/// <reference types="@types/googlemaps" />

import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {IonSearchbar, ModalController} from '@ionic/angular';
import {Place, PlaceType} from '../../models/place';
import PlacesService = google.maps.places.PlacesService;

@Component({
    templateUrl: 'place.autocomplete.component.html'
})

export class PlaceAutocompleteComponent implements OnInit {
    @ViewChild('mainSearchbar') searchBar: IonSearchbar;
    country: string;
    searchType: PlaceType = PlaceType.CITY;
    autocompleteItems;
    autocomplete;
    geo: Place;
    service = new google.maps.places.AutocompleteService();
    placeDetailService: PlacesService;

    constructor(public viewCtrl: ModalController, private zone: NgZone) {
        this.autocompleteItems = [];
        this.autocomplete = {
            query: ''
        };
    }

    ngOnInit(): void {
        const map = new google.maps.Map(document.getElementById('map-canvas'), {
            center: new google.maps.LatLng(42.444508, 76),
            zoom: 15
        });
        this.placeDetailService = new google.maps.places.PlacesService(map);
    }

    dismiss() {
        this.viewCtrl.dismiss();
    }

    chooseItem(item: Place) {
        if (this.searchType === PlaceType.ESTABLISHMENT) {
            this.placeDetailService.getDetails({
                placeId: item.id,
                fields: ['url', 'address_components', 'international_phone_number', 'geometry']
            }, (data, status) => {
                item.phoneNumber = data?.international_phone_number;
                item.location = {lat: data?.geometry?.location?.lat(), lon: data?.geometry?.location?.lng()};
                item.url = data?.url;
                item.city = data?.address_components?.find(addr => {
                    return addr.types.indexOf('locality') >= 0;
                })?.long_name;

                this.viewCtrl.dismiss(item).then();
                this.geo = item;
            });
        } else {
            item.city = item.name;
            this.placeDetailService.getDetails({
                placeId: item.id,
                fields: ['url', 'address_components', 'international_phone_number', 'geometry']
            }, (data, status) => {
                item.location = {lat: data?.geometry?.location?.lat(), lon: data?.geometry?.location?.lng()};
                this.viewCtrl.dismiss(item).then();
                this.geo = item;
            });
        }

    }

    updateSearch() {
        if (this.autocomplete.query === '') {
            this.autocompleteItems = [];
            return;
        }
        const me = this;
        this.service.getPlacePredictions({
            input: me.autocomplete.query,
            types: [me.searchType],
            componentRestrictions: {
                country: me.country
            }
        }, (predictions, status) => {
            me.autocompleteItems = [];
            me.zone.run(() => {
                if (predictions != null) {
                    predictions.forEach((prediction) => {
                        me.autocompleteItems.push({
                            id: prediction.place_id,
                            name: prediction.structured_formatting.main_text,
                            description: prediction.description
                        });
                    });
                }
            });
        });
    }

    ionViewDidEnter() {
        setTimeout(() => {
            this.searchBar.setFocus().then(() => {
            });
        }, 150);
    }

    get isCitySearch() {
        return this.searchType === PlaceType.CITY;
    }

}
