export interface Place {
    id?: string;
    name: string;
    url?: string;
    description?: string;
    city: string;
    phoneNumber?: string;
    location?: Location;
}

export interface Location {
    lat: number;
    lon: number;
}

export enum PlaceType {
    CITY = '(cities)',
    ESTABLISHMENT = 'establishment'
}
