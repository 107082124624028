import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import {ModalController, ToastController} from '@ionic/angular';
import {AuthService} from './auth.service';
import {doCheckAuthenticated} from '../utils/login-utils';
import {DealDataService} from './deal-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router,
              private authService: AuthService,
              private toastCtrl: ToastController,
              private modalCtrl: ModalController,
              private dataService: DealDataService) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    return doCheckAuthenticated(this.authService.isAuthenticated(),
        this.toastCtrl,
        'Authentification requise pour accéder à cette page!',
        this.modalCtrl,
        this.dataService);
  }
}
