<form class="form" [formGroup]="termsForm" >
<div class="ion-justify-content-center ion-align-items-center ion-text-justify">
    <div class="image-container ion-text-center">
        <ion-button class="dismiss-btn" fill="clear" (click)="dismiss()">
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        <br/>
        <h1 class="text-bissap">Bienvenue sur Bissap !</h1>
        <img class="bissap-img" src="assets/img/friends.png"/>
    </div>
    <div class="text-term">
        Nous savons que vous êtes déjà informés de nos Conditions générales d’Utilisation et de nos règles
        de publication car vous avez sûrement visité notre super site.
        Cependant, une petite vérification est toujours la bienvenue.
        <p>
            Veuillez accepter les conditions ci-dessous.
        </p>
    </div>

    <div>
        <ion-item>
            <ion-label><span class="text-bissap">Je certifie</span>  avoir pris connaissance des CGU disponible <a href="https://bissap.deals">ici</a></ion-label>
            <ion-checkbox
                          name="pickup" formControlName="cgu"></ion-checkbox>
        </ion-item>
        <ion-item>
            <ion-label><span class="text-bissap">Je certifie</span> avoir pris connaissance des règles de publications disponible <a href="https://bissap.deals">ici</a> </ion-label>
            <ion-checkbox
                          name="pickup" formControlName="terms"></ion-checkbox>
        </ion-item>
    </div>

</div>
    <br>
    <div>
        <ion-button fill="outline" (click)="accept()"  expand="block" class="bissap-action">
            <span class="span-via-google"> Continuer</span>
        </ion-button>
        <ion-button    (click)="dismiss()"  expand="block" class="bissap-dismiss">
            <span class="span-via-google"> Quitter</span>
        </ion-button>
    </div>
</form>
