<ion-content fullscreen class="ion-padding" scroll-y="false" >
  <ion-slides #slides pager="true">

    <ion-slide class="slide1">
      <div class="slide">
        <img src="assets/img/unboarding-1.png"/>
        <h2>Découvrez Bissap</h2>
        <p>
          Les meilleurs deals d’Afrique sont ici, partagés par une communauté active et surmotivée !
        </p>

        <ion-button (click)="skip()" class="previous" fill="clear">Passer
        </ion-button>

        <ion-button (click)="continue()"  class="continue" fill="clear">Suivant
          <ion-icon slot="end" name="arrow-forward"></ion-icon>
        </ion-button>

      </div>
    </ion-slide>

    <ion-slide>
      <img src="assets/img/unboarding-2.png"/>
      <h2>Voter pour le meilleur</h2>
      <p>
        Un deal vous intéresse ? Faites-le savoir en augmentant sa température avec  <b>+</b>. Dans le cas contraire, votez  <b>-</b> !
      </p>
      <ion-button (click)="skip()" class="previous" fill="clear">Passer
      </ion-button>
      <ion-button (click)="continue()" class="continue" fill="clear">Suivant
        <ion-icon slot="end" name="arrow-forward"></ion-icon>
      </ion-button>
    </ion-slide>

    <ion-slide>
      <img src="assets/img/unboarding-3.png"/>
      <h2>Poster vos deals</h2>
      <p>
        Vous avez vu une offre qui ne peut pas se refuser ? Partagez vos deals avec la communauté !
      </p>
      <ion-button (click)="skip()" class="previous" fill="clear">Passer
      </ion-button>

      <ion-button (click)="continue()" class="continue" fill="clear">Suivant
        <ion-icon slot="end" name="arrow-forward"></ion-icon>
      </ion-button>
    </ion-slide>


    <ion-slide>
      <img src="assets/img/onboarding-4.png"/>
      <h2>Vite fait, bien fait</h2>
      <p>
        Oopsy, les deals expirent très vite ! Pour ne rien louper, activez la petite cloche pour les notifications !
      </p>
      <ion-button (click)="isReview ? end() : skip()"  class="end learn-bissap" expand="block">Découvrir les deals
      </ion-button>
    </ion-slide>

    <ion-slide *ngIf="!isReview">
      <div *ngIf="countries?.length > 0">
        <h2 class="select-country-title">Pour commencer, veuillez sélectionner un pays pour voir les deals</h2>
        <ion-item>
          <ion-icon class="pseudo-icon" name="flag-outline"></ion-icon>
          <ion-label>Pays</ion-label>
          <ion-select placeholder="Sélectionner"
                      [(ngModel)]="parameters.country"
                      interface="popover"
                      class="header-filters">
            <ion-select-option [value]="country.id"
                               *ngFor="let country of countries" >{{ country.name }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-button (click)="end()"  [disabled]="!parameters.country"
                    class="end begin-app" expand="block">Commencer l'aventure Bissap!
          <ion-icon slot="end" name="arrow-forward"></ion-icon>
        </ion-button>
      </div>

      <div *ngIf="hasError || countries?.length === 0">
        <app-technical-problem></app-technical-problem>
      </div>
    </ion-slide>
  </ion-slides>
</ion-content>
