<ion-header [translucent]="true">
    <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="start" (click)="onBackBtn()">
            <ion-button class="show-back-button">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen="true">
    <app-create-edit [form]="form" *ngIf="form" (onFormSubmitted)="onFormSubmitted()">

    </app-create-edit>
</ion-content>