import {DealExpiredComponent} from '../../common/deal-expired/deal-expired.component';
import {ModalController, NavController, Platform, PopoverController, ToastController} from '@ionic/angular';
import {Deal, DiscountType} from '../../../models/deal';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    displayUserDeals,
    getPercent,
    getRouterOutlet,
    isDealExpired,
    shippingFees,
    showAlertManager,
    isDealHot
} from 'src/app/utils/utils';
import {DealDataService} from '../../../services/deal-data.service';
import {AlertManagerComponent} from '../../notifs/alert-manager/alert-manager.component';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {doCheckAuthenticated} from '../../../utils/login-utils';
import {Store} from '@ngxs/store';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {PlaceDisplayComponent} from '../../popups/place-display/place-display.component';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'deal-detail',
    templateUrl: './deal-detail.component.html',
    styleUrls: ['./deal-detail.component.scss'],
})
export class DealDetailComponent implements OnInit, OnDestroy {
    @Input()
    deal: Deal;
    showLocation = false;
    socialShareAvailable = false;

    constructor(public modalController: ModalController,
                private dataService: DealDataService,
                private popoverController: PopoverController,
                private authService: AuthService,
                private toastCtrl: ToastController,
                private router: Router,
                private store: Store,
                private navCtrl: NavController,
                private socialSharing: SocialSharing,
                private platform: Platform,
                private titleService: Title,
                private metaService: Meta) {
    }

    async ngOnInit() {
        this.addSEOTags();
        this.socialShareAvailable = this.platform.is('capacitor') || navigator.share !== undefined;
        if (this.isExpired()) {
            const modal = await this.modalController.create({
                component: DealExpiredComponent,
                swipeToClose: true,
                presentingElement: getRouterOutlet(),
                backdropDismiss: false,
                cssClass: 'bissap-expired-modal-class',
                showBackdrop: true
            });
            modal.onDidDismiss().then((fromBack) => {
                if (fromBack.role) {
                    this.navCtrl.back();
                }
            });
            await modal.present();
        }
        if (this.deal && this.deal.temperature == null) {
            this.deal.temperature = 0;
        }
    }

    private addSEOTags() {
        this.titleService.setTitle(this.deal.title);
        this.metaService.removeTag('property=\'og:title\'');
        this.metaService.removeTag('property=\'og:description\'');
        this.metaService.removeTag('property=\'og:image\'');
        this.metaService.removeTag('property=\'og:site_name\'');
        this.metaService.removeTag('property=\'og:url\'');
        this.metaService.removeTag('property=\'og:type\'');
        this.metaService.removeTag('property=\'og:image:secure_url\'');
        this.metaService.removeTag('property=\'og:article:section\'');
        this.metaService.removeTag('property=\'og:article:published_time\'');
        const dealUrl = this.dataService.getAppBaseUrl() + '/deals/' + this.deal?.id;
        this.metaService.addTags([
            {property: 'og:url', content: dealUrl},
            {property: 'og:article:section', content: this.deal.categoryId},
            {property: 'og:article:published_time', content: this.deal.createdAt},
            {property: 'og:site_name', content: 'Bissap Deals'},
            {property: 'og:title', content: this.deal.title},
            {property: 'og:description', content: this.deal.title},
            {property: 'og:type', content: 'Deal'},
            {property: 'og:image', content: this.deal.image || 'https://app.bissap.deals/assets/img/product-img.png', itemprop: 'image'},
            {
                property: 'og:image:secure_url',
                content: this.deal.image || 'https://app.bissap.deals/assets/img/product-img.png',
                itemprop: 'image'
            },
        ]);
    }

    getPercent() {
        return getPercent(this.deal);
    }

    shippingFees() {
        return shippingFees(this.deal);
    }

    toggleStar() {
        if (this.checkAuthenticated()) {
            this.dataService.toggleDealStar(this.deal);
        }
    }

    socialShare() {
        const dealUrl = this.dataService.getAppBaseUrl() + '/deals/' + this.deal?.id;
        if (!this.platform.is('capacitor')) {
            if (navigator.share !== undefined) {
                navigator.share({
                    title: 'Deal BISSAP',
                    text: this.deal.title,
                    url: dealUrl
                }).then(() => {
                    console.log('Successful share');
                }).catch((error) => {
                    console.log('Error sharing:', error);
                });
            } else {
                console.log('Sharing not available');
            }
        } else {
            this.socialSharing.share(this.deal.title, 'Deal BISSAP', null, dealUrl);
        }
    }

    incTemperature() {
        if (this.checkAuthenticated()) {
            this.dataService.incDealTemperature(this.deal);
        }
    }

    decTemperature() {
        if (this.checkAuthenticated()) {
            this.dataService.decDealTemperature(this.deal);
        }
    }

    getAvatar() {
        return this.deal.user.avatar || '/assets/img/user-profilpic.png';
    }

    showAlertManager() {
        if (this.checkAuthenticated()) {
            showAlertManager(this.popoverController, this.deal, AlertManagerComponent);
        }
    }

    hasAlertTrigger() {
        return this.deal.alertTrigger != null;
    }

    async openWithWhatsapp() {
        window.open('https://api.whatsapp.com/send?phone=' + this.deal.whatsappNo);
    }

    openInstaPage() {
        const url = this.deal.instagramPage.startsWith('http') ?
            this.deal.instagramPage : `http://${this.deal.instagramPage}`;
        window.open(url, '_system');
    }

    async openFacebookPage() {
        const url = this.deal.facebookPage.startsWith('http') ?
            this.deal.facebookPage : `http://${this.deal.facebookPage}`;
        window.open(url, '_system');
    }

    async openWithPhone() {
        window.open('tel://' + this.deal.phone);
    }

    openExternalLink() {
        window.open(this.deal.externalDealLink, '_system');
    }

    openDealLocation() {
        if (this.deal?.locationDetails?.url) {
            window.open(this.deal?.locationDetails?.url, '_system');

        } else {
            this.modalController.create({
                component: PlaceDisplayComponent,
                componentProps: {
                    deal: this.deal,
                },
                swipeToClose: true,
                showBackdrop: true,
                presentingElement: getRouterOutlet(),
                cssClass: 'bissap-term-modal-class'
            }).then(e => e.present());
        }
    }

    isExpired() {
        if (!this.deal) {
            return false;
        }
        return isDealExpired(this.deal);
    }

    checkAuthenticated() {
        return doCheckAuthenticated(this.authService.isAuthenticated(),
            this.toastCtrl,
            'Authentification requise pour effectuer cette action',
            this.modalController,
            this.dataService);
    }

    displayUserDeals(user) {
        displayUserDeals(user, this.authService, this.router, this.store);
    }

    ngOnDestroy(): void {
        this.popoverController.dismiss();
        this.modalController.dismiss();
    }

    toggleLocation() {
        this.showLocation = !this.showLocation;
    }

    isPublished() {
        return this.deal.status === 'PUBLISHED';
    }

    get isPriceAbsolute() {
        return !this.deal.discountType
            || this.deal.discountType === DiscountType.PRICE
            || this.deal.discountType === DiscountType.PRICE_ABSOLUTE;
    }

    get isPricePercent() {
        return this.deal.discountType === DiscountType.PRICE_PERCENT;
    }

    get isOffer() {
        return this.deal.discountType === DiscountType.OFFER;
    }

    get isFree() {
        return this.deal.discountType === DiscountType.FREE;
    }

    get locationIcon() {
        if (this.deal?.location === 'Playstore') {
            return 'logo-google-playstore';
        }
        if (this.deal?.location === 'Apple Store') {
            return 'logo-apple-appstore';
        }
        if (this.deal?.location === 'Internet Store') {
            return 'globe-outline';
        }
        return 'location-outline';
    }

    isDealHot() {
        return isDealHot(this.deal.temperature);
    }

    get productImg() {
        return this.deal?.image || '/assets/img/product-img.png';
    }
}
