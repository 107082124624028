import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CreateDealForm} from '../../../models/form';
import {ActivatedRoute, Router} from '@angular/router';
import {DealDataService} from '../../../services/deal-data.service';
import {AlertController} from '@ionic/angular';
import {confirmExit, CURRENCIES} from '../../../utils/utils';

@Component({
  selector: 'app-editer',
  templateUrl: './editer.page.html',
  styleUrls: ['./editer.page.scss'],
})
export class EditerPage implements OnInit {
  form: CreateDealForm;
  title = 'Editer un deal';
  loading = false;
  error = false;
  submitted = false;
  constructor(private activatedRoute: ActivatedRoute,
              private dataService: DealDataService,
              private router: Router,
              private alertController: AlertController,
              private cd: ChangeDetectorRef) { }
  ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.loading = true;
    this.error = false;
    this.dataService.getMyDealById(id).subscribe(deal => {
      this.loading = false;
      if (deal) {
        this.form =  {
          ...deal,
          startDate: deal.startDate,
          title: deal.title,
          location: deal.location,
          price: deal.price,
          usualPrice: deal.usualPrice,
          description: deal.description,
          externalDealLink: deal.externalDealLink,
          categoryId: deal.categoryId,
          country: deal.country,
          city: deal.city,
          pickup: deal.pickup,
          shipping: deal.shipping,
          shippingFees: deal.shippingFees && deal.shippingFees.value != null ?
              deal.shippingFees : {unit: CURRENCIES[0]},
          phone: deal.phone,
          whatsappNo: deal.whatsappNo,
          facebookPage: deal.facebookPage,
          instagramPage: deal.instagramPage,
          image: deal.image,
          professional: deal.professional,
          isStared: deal.stared,
          type: deal.type,
          id: deal.id,
          user: deal.user,
          event: deal.event,
          message: deal.message,
          dealSourceType: deal.dealSourceType
        };
        this.cd.detectChanges();
      }
    }, error => {
      this.loading = false;
      this.error = true;
    });

  }
  async onBackBtn() {
    await this.router.navigate(['/']);
  }
  async checkBackBtn(): Promise<boolean> {
    return confirmExit(this.alertController);
  }
  onFormSubmitted() {
    this.submitted = true;
  }
}
