import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ActionsService {
    constructor(private router: Router,
                private socialSharing: SocialSharing,
                private platform: Platform) {
    }

    execute(action: string) {
        switch (action) {
            case 'note':
                this.noteApp();
                break;
            case 'facebook':
                this.openFacebook();
                break;
            case 'blog':
                this.openBlog();
                break;
            case 'signal':
                this.signalDeal();
                break;
            case 'twitter':
                this.openTwitter();
                break;
            case 'invite':
                this.invite();
                break;
            case 'install':
                this.installApp();
                break;
            case 'install-android':
                this.installAndroidApp();
                break;
            case 'install-ios':
                this.installIosApp();
                break;
        }
    }

    installApp() {
        window.open(this.getAppUrl(), '_system');
    }

    noteApp() {
        window.open(this.getAppUrl(), '_system');
    }

    private getAppUrl() {
        let appUrl;
        if (this.platform.is('ios')) {
            appUrl = this.iosAppUrl;
        } else {
            appUrl = this.androidAppUrl;
        }
        return appUrl;
    }

    installAndroidApp() {
        window.open(this.androidAppUrl, '_system');
    }

    installIosApp() {
        window.open(this.iosAppUrl, '_system');
    }

    openFacebook() {
        window.open('https://facebook.com/bissap-deals', '_system');
    }

    openTwitter() {
        window.open('https://twitter.com/Bissap_deals', '_system');
    }

    openBlog() {
        window.open('https://www.bissap.deals/leblog', '_system');
    }

    signalDeal() {
        window.open('https://www.bissap.deals/contact/', '_system');
    }

    openHelpPage() {
        window.open('https://www.bissap.deals/category/tutoriels/', '_system');
    }

    invite() {
        const appUrl = this.getAppUrl();
        const message = 'Je viens de découvrir une superbe application qui te permet de connaitre toutes ' +
            'les bonnes promotions de ton pays. Tu peux y accéder ici';
        if (!this.platform.is('capacitor')) {
            if (navigator.share !== undefined) {
                navigator.share({
                    title: 'Invitez vos amis',
                    text: message,
                    url: appUrl
                }).then(() => {
                    console.log('Successful share');
                }).catch((error) => {
                    console.log('Error sharing:', error);
                });
            } else {
                console.log('Sharing not available');
            }
        } else {
            this.socialSharing.share(message, 'Invitez vos amis',
                'https://storage.googleapis.com/bissap-data-bucket/deals/ILLUSTRATION%20SEULE/PNG/illus-promo.png', appUrl);
        }
    }

    get androidAppUrl() {
        return 'https://play.google.com/store/apps/details?id=com.bissap';
    }

    get iosAppUrl() {
        return 'https://apps.apple.com/us/app/bissap/id1554514287';
    }
}
