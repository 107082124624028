import {Injectable, OnInit} from '@angular/core';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import {Store} from '@ngxs/store';
import {StoreAuthData, StoreAuthTokens, StoreParameters, StoreUserProfile} from '../store/app.action';
import {AuthData, Parameter} from '../models/store';
import {AppState} from '../store/app.state';
import {ModalController, Platform, PopoverController, ToastController} from '@ionic/angular';
import '@codetrix-studio/capacitor-google-auth';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {apiAuthTokenExchange, apiAuthUserInfo, config, environment} from '../../config.json';
import {catchError, shareReplay} from 'rxjs/operators';
import {getRouterOutlet, handleError} from '../utils/utils';
import {User} from '../models/deal';
import {BehaviorSubject} from 'rxjs';
import {TermsAndConditionsComponent} from '../components/popups/terms-and-conditions/terms-and-conditions.component';
import {AppleLoginProvider} from '../components/login/apple-login-provider';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse,
    ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnInit {
    loading = false;
    private onLoginObserver$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private parameter: Parameter;

    constructor(private socialAuthService: SocialAuthService,
                private store: Store,
                private modalCtrl: ModalController,
                private popoverController: PopoverController,
                private toastController: ToastController,
                private platform: Platform,
                private facebook: Facebook,
                private http: HttpClient,
                private signInWithApple: SignInWithApple) {
    }

    ngOnInit(): void {
    }

    logout(showInfoMessage) {
        GoogleAuth.signOut().then(() => {
            console.log('Logout');
        });
        this.socialAuthService.signOut()
            .then(() => {
                console.log('Logout');
            })
            .catch((error) => {
                console.log(error);
            });
        this.facebook.logout().then(() => {
            console.log('Logout');
        }).catch((error) => {
            console.log(error);
        });
        this.http.post(`${this.getApiBaseUrl()}${apiAuthUserInfo}/logout`, {headers: this.getAuthorizationHeader()})
            .subscribe(() => {
            });
        this.store.dispatch(new StoreAuthData({
            accessToken: null,
            refreshToken: null,
            user: null
        }));
        if (showInfoMessage) {
            this.toastController.create({
                color: 'success',
                message: `Vous avez été déconnecté. Vous nous manquez déjà!`,
                duration: 5000,
                position: 'bottom'
            }).then(e => e.present());
        }
    }

    signInWithGoogle(completeProfilComponent): void {
        GoogleAuth.signIn().then((result) => {
            const user: SocialUser = {
                provider: GoogleLoginProvider.PROVIDER_ID,
                authToken: result.authentication?.accessToken,
                photoUrl: result.imageUrl,
                id: result.id,
                name: result.name || result.givenName,
                idToken: result.authentication?.idToken,
                email: result.email,
                firstName: result.givenName,
                lastName: result.familyName,
                authorizationCode: result.serverAuthCode,
                response: null,
            };
            this.login(user, completeProfilComponent);
        })
            .catch((err) => {
                this.showError(`Une erreur est survenue lors de la connexion avec Google`, err);
            });

    }

    signInWithFB(completeProfilComponent): void {
        if (this.isNative()) {
            this.loginWithFbNative(completeProfilComponent);
        } else {
            this.loginWithFbWeb(completeProfilComponent);
        }

    }

    loginInWithApple(completeProfilComponent): void {
        if (this.isNative() && this.isIos()) {
            this.signInWithApple.signin({
                requestedScopes: [
                    ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
                    ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
                ]
            })
                .then((res: AppleSignInResponse) => {
                    const user: SocialUser = {
                        provider: AppleLoginProvider.PROVIDER_ID,
                        authToken:  null,
                        photoUrl: null,
                        id: res.user,
                        name: null,
                        idToken: res.identityToken,
                        email: null,
                        firstName: null,
                        lastName: null,
                        authorizationCode: res.authorizationCode,
                        response: null,
                    };
                    this.login(user, completeProfilComponent);
                })
                .catch((error: AppleSignInErrorResponse) => {
                    alert(error.code + ' ' + error.localizedDescription);
                    console.error(error);
                });
        } else {
            this.socialAuthService.signIn(AppleLoginProvider.PROVIDER_ID)
                .then((result: SocialUser) => {
                    this.login(result, completeProfilComponent);
                })
                .catch((err2) => {
                    this.showError(`Une erreur est survenue lors de la connexion avec Apple!`, err2);
                });
        }

    }

    desactivateAccount() {
        const observable = this.http.delete(`${this.getApiBaseUrl()}${apiAuthUserInfo}`, {headers: this.getAuthorizationHeader()})
            .pipe(shareReplay());
        observable.subscribe(() => {
                this.logout(false);
                this.onLogin().next('logout');
            },
            error => {
                this.showError(`Une erreur est survenue lors de la connexion avec le serveur`, error);
            });
        return observable;
    }

    private loginWithFbNative(completeProfilComponent) {
        this.facebook.login(['public_profile', 'email'])
            .then((result) => {
                console.log(result);
                const user: SocialUser = {
                    provider: FacebookLoginProvider.PROVIDER_ID,
                    authToken: result.authResponse.accessToken,
                    photoUrl: null,
                    id: result.authResponse.userID,
                    name: null,
                    idToken: null,
                    email: null,
                    firstName: null,
                    lastName: null,
                    authorizationCode: null,
                    response: null,
                };
                this.login(user, completeProfilComponent);
            }).catch((err) => {
            this.showError(`Une erreur est survenue lors de la connexion avec Facebook!`, err);
        });
    }

    private loginWithFbWeb(completeProfilComponent) {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)
            .then((result: SocialUser) => {
                this.login(result, completeProfilComponent);
            })
            .catch((err2) => {
                this.showError(`Une erreur est survenue lors de la connexion avec Facebook!`, err2);
            });
    }

    private login(user: SocialUser, completeProfilComponent) {
        this.loading = true;
        const loginResponse = this.http
            .post(`${this.getApiBaseUrl()}${apiAuthTokenExchange}`,
                {
                    provider: user.provider,
                    token: user.authToken ? user.authToken : user.idToken,
                    tokenType: user.authToken ? 'access_token' : 'id_token',
                    authorizationCode: user.authorizationCode,
                });
        loginResponse.subscribe((tokens) => {
                this.parameter = this.store.selectSnapshot(AppState.SelectParameters);
                this.parameter.loginProvider = user.provider;
                this.store.dispatch(new StoreParameters(this.parameter));
                this.fetchUserInfo(tokens, completeProfilComponent, user);
            },
            err => {
                this.showError(`Une erreur est survenue lors de la connexion!`, err);
                this.loading = false;
            });

    }

    fetchUserInfo(tokens, completeProfilComponent, socialUser: SocialUser) {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${tokens.access_token}`);
        const avatar = socialUser ? socialUser.photoUrl : null;
        this.http.get(`${this.getApiBaseUrl()}${apiAuthUserInfo}`, {headers})
            .subscribe((userInfo: any) => {
                const user = {...userInfo, avatar: userInfo.avatar || avatar};
                this.store.dispatch(new StoreAuthData({
                    accessToken: tokens.access_token,
                    refreshToken: tokens.refresh_token,
                    user,
                }));
                this.loading = false;
                this.modalCtrl.dismiss();
                if (!user.country || !user.name) {
                    this.parameter.termsAccepted = false;
                }
                if (!this.parameter?.termsAccepted) {
                    this.modalCtrl.create({
                        component: TermsAndConditionsComponent,
                        swipeToClose: true,
                        presentingElement: getRouterOutlet(),
                        cssClass: 'bissap-term-modal-class'
                    }).then((modal) => {
                        modal.present().then();
                        modal.onWillDismiss().then((detail) => {
                            if (detail.data) {
                                this.parameter.termsAccepted = true;
                                this.store.dispatch(new StoreParameters(this.parameter));
                                if (!user.country || !user.name) {
                                    this.showProfileDialog(completeProfilComponent, user);
                                }
                                this.onLoginObserver$.next(user);
                            } else {
                                // disconnect
                                this.logout(false);
                            }
                        });
                    });
                } else if (!user.country || !user.name) {
                    this.showProfileDialog(completeProfilComponent, user);
                }
            }, err => {
                if (err.status !== 401) {
                    this.showError('Une erreur est survenue lors de la connexion', err);
                }
                this.loading = false;
            });
    }

    isAuthenticated() {
        const authData: AuthData = this.store.selectSnapshot(AppState.SelectAuthData);
        return authData && authData.accessToken;
    }

    loggedInUser() {
        const authData: AuthData = this.store.selectSnapshot(AppState.SelectAuthData);
        return authData && authData.accessToken && authData.user ? authData.user : null;
    }

    private showProfileDialog(completeProfilComponent: any, user: any) {
        this.popoverController.create({
            component: completeProfilComponent,
            cssClass: 'profile-container',
            componentProps: {
                user,
                fromLogin: true
            }
        }).then(popover => popover.present());
    }

    private signIn(): void {
    }

    private isNative() {
        return this.platform.is('capacitor');
    }

    private isIos() {
        return this.platform.is('ios');
    }

    private getApiBaseUrl() {
        return `${config[environment].apiBaseUrl}`;
    }

    private showError(message, err) {
        console.log(err);
        this.toastController.create({
            color: 'danger',
            message,
            duration: 5000,
            position: 'bottom'
        }).then(e => e.present());
    }

    refreshToken() {
        const authData = this.store.selectSnapshot(AppState.SelectAuthData);
        if (authData && authData.refreshToken) {
            console.log('Refreshing auth token');
            this.http
                .post(`${this.getApiBaseUrl()}${apiAuthTokenExchange}`,
                    {provider: 'REFRESH_TOKEN', token: authData.refreshToken})
                .pipe(
                    catchError(handleError<any>('refreshToken', {},
                        this, this.store, this.toastController))
                )
                .subscribe((tokens) => {
                    this.store.dispatch(new StoreAuthTokens({
                        accessToken: tokens.access_token,
                        refreshToken: tokens.refresh_token
                    }));
                    this.fetchAndUpdateUserData(tokens.access_token);

                });
        }
    }

    fetchAndUpdateUserData(token) {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${token}`);
        this.http.get(`${this.getApiBaseUrl()}${apiAuthUserInfo}`, {headers})
            .subscribe((userInfo: any) => {
                const user: User = {...userInfo};
                this.store.dispatch(new StoreUserProfile(user));
            });
    }

    onLogin() {
        return this.onLoginObserver$;
    }

    private getAuthorizationHeader() {
        const token = this.store.selectSnapshot(AppState.SelectAuthData).accessToken;
        if (token) {
            let headers = new HttpHeaders();
            headers = headers.set('Authorization', `Bearer ${token}`);
            return headers;
        }
        return null;
    }
}
