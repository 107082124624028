import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'bissap-alert-toggle',
  templateUrl: './alert-toggle.component.html',
  styleUrls: ['./alert-toggle.component.scss'],
})
export class AlertToggleComponent implements OnInit {
  @Input() value: boolean;
  @Input() icon: boolean;
  @Output() valueChange = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {}

    toggleValue() {
        this.value = !this.value;
        this.valueChange.emit(this.value);
    }
}
