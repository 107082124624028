import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';
import {validURL} from './utils';
import {PhoneNumberUtil} from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
const dateFormat = 'Le DD MMMM YYYY à HH:mm';

export class DealFormValidator {
    static validUsualPrice(fc: FormControl) {
        if (fc.value && fc?.parent?.value?.priceValue) {
            const price = parseFloat(fc?.parent?.value?.priceValue);
            const usualPrice = parseFloat(fc.value);
            return usualPrice <= price ? {valid: true} : null;
        } else {
            return null;
        }
    }

    static validEndDate(fc: FormControl) {
        if (fc.value && fc.parent) {
            const today = moment();
            const start = moment(fc.parent.value.startDate);
            const end = moment(fc.value);
            let valid = end.isSameOrAfter(start) && end.isSameOrAfter(today);
            let validMaxEnd = true;
            if (valid) {
                const maxValidityEnd = moment(fc.parent.value.startDate);
                maxValidityEnd.add(4, 'months');
                validMaxEnd = end.isSameOrBefore(maxValidityEnd);
                valid = validMaxEnd;
            }
            return valid ? null : !validMaxEnd ? {maxvalidity: true} : {valid: true};
        } else {
            return null;
        }
    }

    static validStartDate(fc: FormControl, startDate: string) {
        if (fc.value) {
            const today = moment(startDate);
            const date = moment(fc.value);
            const valid = date.isSameOrAfter(today);
            return valid ? null : {valid: true};
        } else {
            return {valid: true};
        }
    }

    static url(fc: AbstractControl) {
        if (!fc.value) {
            return null;
        }
        if (!validURL(fc.value)) {
            return {valid: true};
        }
        return null;
    }

    static validUnit(price: any): ValidatorFn {
        return (fc: FormControl) => {
            if (price) {
                return !fc.value ? {required: true} : null;
            } else {
                return null;
            }
        };
    }

    static validPhone(fc: AbstractControl) {
        if (!fc.value || !fc?.value?.nationalNumber) {
            return null;
        }
        let phoneNumber = null;
        const error = {valid: true};
        try {
            phoneNumber = PhoneNumberUtil.getInstance().parse(fc.value.nationalNumber, fc.value.isoCode);
        } catch (e) {
            return error;
        }
        if (!phoneNumber) {
            return error;
        } else {
            if (!PhoneNumberUtil.getInstance().isValidNumberForRegion(phoneNumber, fc.value.isoCode)) {
                return error;
            }
        }
        return null;
    }

    static numberOnlyValidation(event: any) {
        const pattern = /[0-9,.]/;
        const inputChar = event.data;

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    static integersOnlyValidation(event: any) {
        const pattern = /[0-9]/;
        const inputChar = event.data;

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }
}
