import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { DealListComponent } from './list/list.component';
import { DealItemComponent } from './item/item.component';
import { MomentModule } from 'ngx-moment';
import {GaugesModule} from 'ng-canvas-gauges';
import {SharedModule} from '../../shared.module';
import {QuillModule} from 'ngx-quill';
import {NgxIonicImageViewerModule} from 'ngx-ionic-image-viewer';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule,
        RouterModule, MomentModule, GaugesModule, SharedModule,
        QuillModule, NgxIonicImageViewerModule],
    declarations: [DealItemComponent, DealListComponent],
    exports: [DealItemComponent, DealListComponent]
})
export class DealsComponentModule {}
