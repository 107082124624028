import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ModalController, Platform} from '@ionic/angular';
import {ProfilComponent} from '../profil/profil.component';
import {AppState} from '../../store/app.state';
import {Store} from '@ngxs/store';
import {GoogleLoginProvider} from 'angularx-social-login';
import * as firebase from 'firebase';
import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
import {AppleLoginProvider} from './apple-login-provider';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    form: any = {};

    constructor(public authService: AuthService,
                private modalController: ModalController,
                private platform: Platform,
                private store: Store) {
    }

    ngOnInit() {
        const parameter = this.store.selectSnapshot(AppState.SelectParameters);
        if (parameter?.loginProvider) {
            if (parameter?.loginProvider === GoogleLoginProvider.PROVIDER_ID) {
                this.signInWithGoogle();
            } else if (parameter?.loginProvider === FacebookAuthProvider.PROVIDER_ID) {
                this.signInWithFB();
            } else if (parameter?.loginProvider === AppleLoginProvider.PROVIDER_ID) {
                this.signInWithApple();
            }
        }
    }

    isIos() {
        return this.platform.is('ios');
    }

    signInWithGoogle(): void {
        this.authService.signInWithGoogle(ProfilComponent);
    }

    signInWithFB(): void {
        this.authService.signInWithFB(ProfilComponent);
    }

    signInWithApple() {
        // sign in with apple
        this.authService.loginInWithApple(ProfilComponent);
    }

    signUp() {
    }

    signIn() {
    }

    dismiss() {
        this.modalController.dismiss();
    }

}
