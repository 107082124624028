import { Injectable } from '@angular/core';

@Injectable()
/**
 * Configuration values for ManUp
 */
export class ManUpConfig {
    url: string;
    externalTranslations?: boolean;
}
