/**
 * Created by ouedraog on 21/04/2020.
 */
import {AlertTrigger} from './notification';
import {Country} from './country';
import {Place} from './place';

export interface User {
    createdDate?: string;
    name?: string;
    avatar?: string;
    id?: string;
    country?: string;
    email?: string;
    provider?: string;
    phoneNumber?: string;
    trusted?: boolean;
    username?: string;
    usernameUpdateDisabled?: boolean;
    avatarVersion?: number;
}

export interface Price {
    value?: number;
    unit?: string;
}

export enum DealType {
    DEAL = 'DEAL',
    ADS = 'ADS'
}

export enum DealStatus {
    NEW = 'NEW',
    UPDATED = 'UPDATED',
    PUBLISHED = 'PUBLISHED',
    EXPIRED = 'EXPIRED',
    REJECTED = 'REJECTED',
    FIXED = 'FIXED'
}


export interface Deal {
    boosted?: boolean;
    event?: string;
    id?: string;
    startDate?: string;
    temperature?: number;
    title?: string;
    user?: User;
    location?: string;
    locationDetails?: Place;
    professional?: string;
    price?: Price;
    usualPrice?: Price;
    description?: string;
    stared?: boolean;
    externalDealLink?: string;
    type: string;
    image?: string;
    endDate?: string;
    country?: string;
    city?: string;
    pickup?: boolean;
    shipping?: boolean;
    shippingFees?: Price;
    phone?: string;
    whatsappNo?: string;
    hidden?: boolean;
    categoryId?: string;
    facebookPage?: string;
    instagramPage?: string;
    alertTrigger?: AlertTrigger;
    viewCount?: number;
    voted?: boolean;
    createdAt?: string;
    // Ads
    action?: Action;
    status?: DealStatus;
    message?: string;
    discountType?: DiscountType;
    discount?: number;
    discountOffer?: number;
    discountBuy?: number;
    dealSourceType: DealSourceType;

}

export interface Action {
    title: string;
    link: string;
}

export interface Category {
    id: string;
    name: string;
    dealCount?: number;
}

export interface SearchCriteria {
    type: string; // ALL, DEAL, CODE_PROMO
    field: string; // ALL, PROFESSIONAL, TITLE, DESCRIPTION
    category: string; // category id
    onlyHotDeals?: boolean;
    includeExpiredDeals?: boolean;
    country: string;
    sortByField: string;
    noSave?: boolean;
    searchAfter?: any[];
    onlyRecentDeals?: boolean;
    discountType?: string;
    onlyFeaturedDeals?: boolean;
}

export interface UserDeals {
    user: User;
    deals: Deal[];
    totalElements: number;
}

export interface DealVote {
    id: string;
    userId: string;
    dealId: string;
    weight?: number;
    lastVotedAt: string;
    temperature?: number;
}

export interface DealSearchResponse {
    deals: Deal[];
    searchAfter?: any[];
    totalHits?: null;
    user?: User;
}

export enum DiscountType {
    PRICE_ABSOLUTE = 'PRICE_ABSOLUTE',
    PRICE_PERCENT = 'PRICE_PERCENT',
    OFFER = 'OFFER',
    FREE = 'FREE',
    PRICE = 'PRICE',
}

export enum DealSourceType {
    PHYSICAL = 'PHYSICAL',
    ONLINE = 'ONLINE'
}
