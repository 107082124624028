<ion-app *ngIf="!isLargeScreen() && !displayInstallAppMessage()">
  <app-welcome [isReview]="isReview" *ngIf="!parameters.tutoViewed || !parameters.country"></app-welcome>
  <ion-split-pane contentId="main-content"
                  *ngIf="parameters.tutoViewed && parameters.country">
    <ion-menu menuId="main-content" contentId="main-content" type="overlay" side="end">
      <ion-content>
        <div class="menu-header">
          <ion-button class="dismiss-btn" fill="clear" (click)="dismiss()">
            <ion-icon  name="close-outline" class="float-right"></ion-icon>
          </ion-button>
          <div class="menu-logo" (click)="showEditProfile()">
            <ion-avatar>
              <ion-img src="{{ getProfilePic() }}" class="img-circle"></ion-img>
            </ion-avatar>
            <span *ngIf="isLoggedIn && user && user.name">{{ user.name }}</span>
          </div>
        </div>


        <ion-list class="bissap-menu-list">
          <ion-menu-toggle auto-hide="false" >
            <ion-item (click)="showLoginModal()" *ngIf="!isLoggedIn">
              <ion-icon name="log-in-outline"></ion-icon>
              <ion-label>Se connecter</ion-label>
            </ion-item>
            <ion-item (click)="showPage('/profile')">
              <ion-icon name="person-outline"></ion-icon>
              <ion-label>{{isLoggedIn ? 'Mon profil' : 'Paramètres'}}</ion-label>
            </ion-item>
            <ion-item *ngIf="isLoggedIn" (click)="showPage('/mes-deals')">
              <ion-icon name="pricetag-outline"></ion-icon>
              <ion-label>Mes deals</ion-label>
            </ion-item>
<!--            <ion-item *ngIf="isLoggedIn">
              <ion-icon name="bookmark-outline"></ion-icon>
              <ion-label>Mes code promo</ion-label>
            </ion-item>-->
            <ion-item *ngIf="isLoggedIn" (click)="showPage('/favoris')">
              <ion-icon name="star-outline"></ion-icon>
              <ion-label>Mes favoris</ion-label>
            </ion-item>
            <ion-item *ngIf="isLoggedIn" (click)="showPage('/notifications')">
              <ion-icon name="notifications-outline"></ion-icon>
              <ion-label>Mes notifications</ion-label>
            </ion-item>
            <ion-item *ngIf="isLoggedIn" (click)="showPage('/mes-alertes')">
              <ion-icon name="megaphone-outline"></ion-icon>
              <ion-label>Mes alertes</ion-label>
            </ion-item>
            <ion-item  (click)="reviewBissapTuto()">
              <ion-icon name="arrow-redo-outline"></ion-icon>
              <ion-label>Revoir la présentation de Bissap</ion-label>
            </ion-item>
            <ion-item  (click)="showHelp()">
              <ion-icon name="help-buoy-outline"></ion-icon>
              <ion-label>Tutoriels</ion-label>
            </ion-item>
            <ion-item  (click)="showBlog()">
              <ion-icon name="newspaper-outline"></ion-icon>
              <ion-label>Le Blog Bissap</ion-label>
            </ion-item>
            <ion-item *ngIf="isLoggedIn" (click)="signOut()">
              <ion-icon name="log-out-outline"></ion-icon>
              <ion-label>Se déconnecter</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <div class="bissap-menu-logo-end">
          <img src="assets/img/bissap-logo-full.svg"/>
          <div class="bissap-version">Version {{ version }}</div>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>

<ion-app *ngIf="isLargeScreen() && !displayInstallAppMessage()">
  <bissap-design-warning></bissap-design-warning>
</ion-app>

<ion-app *ngIf="displayInstallAppMessage()">
  <bissap-app-install></bissap-app-install>
</ion-app>
