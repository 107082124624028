import {LoadingController, ModalController} from '@ionic/angular';
import {DealDataService} from '../../../services/deal-data.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Deal} from '../../../models/deal';

@Component({
    selector: 'app-deal-detail',
    templateUrl: './deal-detail.page.html',
    styleUrls: ['./deal-detail.page.scss'],
})
export class DealDetailPage implements OnInit {
    deal: Deal;
    loading = true;
    hasError = false;

    constructor(private activatedRoute: ActivatedRoute,
                public dataService: DealDataService,
                public modalController: ModalController,
                private loadingController: LoadingController) {
    }

    async ngOnInit() {
        const spinner = await this.loadingController.create({
            cssClass: 'loading-spinner',
            message: `<ion-img src="assets/img/loading.gif"></ion-img>`,
            spinner: null,
        });
        await spinner.present();
        this.loading = true;
        this.hasError = false;
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        if (id) {
            this.dataService.setCurrentDealDetailPage(id);
            this.dataService.getDealById(id).subscribe(deal => {
                this.deal = deal;
                spinner.dismiss();
                this.loading = false;
            }, error => {
                spinner.dismiss();
                this.loading = false;
                this.hasError = true;
            });
        } else {
            this.loading = false;
            spinner.dismiss();
        }
    }

    closeModal() {
        this.modalController.dismiss();
    }
}
