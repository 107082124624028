import {Component, OnInit} from '@angular/core';
import {ModalController, ToastController} from '@ionic/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'bissap-design-terms',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
    termsForm: FormGroup;
    constructor(private modalController: ModalController,
                private toastController: ToastController) {
    }

    ngOnInit() {
        this.termsForm = new FormGroup({
            terms: new FormControl( false,
                [Validators.requiredTrue]),
            cgu:  new FormControl( false,
                [Validators.requiredTrue])
        });
    }

    dismiss() {
        this.modalController.dismiss();
    }

    accept() {
        if (this.termsForm.valid) {
            this.modalController.dismiss({accepted: true});
        } else {
            this.toastController.create({
                color: 'warning',
                message : 'Veuillez accepter toutes les conditions pour continuer',
                duration: 5000,
                position: 'bottom'
            }).then(e => e.present());
        }
    }
}
