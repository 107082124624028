import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AppState} from '../../../store/app.state';
import {StoreParameters} from '../../../store/app.action';
import {Store} from '@ngxs/store';
import {Parameter} from '../../../models/store';

@Component({
  selector: 'app-voted-popup',
  templateUrl: './voted-popup.component.html',
  styleUrls: ['./voted-popup.component.scss'],
})
export class VotedPopupComponent implements OnInit {
  dismissText: string;
  votedMessage: string;
  votedTitle: string;
  votedImage: string;
  @Input() parameters: Parameter;
  @Input() votedUp: boolean;

  constructor(private modalController: ModalController,
              private store: Store) { }

  ngOnInit() {
    this.dismissText = `J'ai compris`;
    this.votedMessage = this.votedUp ?
        'Vous venez de voter en faveur de ce deal, c’est qu’il doit être vraiment cool. Votre vote permettra de le valoriser!'
        : 'Vous venez de voter contre ce deal, l’offre n\'était sûrement pas assez intéressante. Merci pour votre honnêteté!';
    this.votedTitle = this.votedUp ? 'Merci pour votre vote !' : 'Aie, aie !';
    this.votedImage = this.votedUp ? '/assets/img/vote-happy.png'
        : '/assets/img/vote-sad.png';
  }

  hideForever() {
    this.parameters.doNotDiplayVotedPopup = true;
    this.store.dispatch(new StoreParameters(this.parameters))
        .subscribe(() => {
        });
    }
  dismiss() {
    this.modalController.dismiss();
  }

}
