import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {EditerPage} from '../pages/deals/editer/editer.page';

@Injectable({
  providedIn: 'root'
})
export class HandleUnsavedEditChangeGuard implements CanDeactivate<EditerPage> {
  canDeactivate(component: EditerPage,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.submitted) {
      return true;
    }
    return component.checkBackBtn();
  }

}
