import {Injectable} from '@angular/core';
import {AsyncStorageEngine} from '@ngxs-labs/async-storage-plugin';
import {from, Observable} from 'rxjs';
import {Storage} from '@ionic/storage';
import {replacer, reviver} from './utils';

@Injectable({
    providedIn: 'root'
})
export class StorageService implements AsyncStorageEngine {
    constructor(private storage: Storage) { }

    length(): Observable<number> {
        return from(this.storage.length());
    }

    getItem(key: any): Observable<any> {
        console.log('Start get');
        return from(this.storage.get(this.buildStorageKey(key)));
    }

    setItem(key: any, val: any): void {
        this.storage.set(this.buildStorageKey(key), val).then(() => {
            console.log(`Store ${key}`);
        });
    }

    removeItem(key: any): void {
        this.storage.remove(this.buildStorageKey(key));
    }

    clear(): void {
        this.storage.clear();
    }

    key(val: number): Observable<string> {
        return from(this.storage.keys().then(keys => keys[val]));
    }
    private buildStorageKey(key: any) {
        return key;
    }

}

export function serialize(obj) {
    return JSON.stringify(obj, replacer);
}

export function deserialize(obj: any): any {
    return JSON.parse(obj, reviver);
}
