import {ModalController, ToastController} from '@ionic/angular';
import {LoginComponent} from '../components/login/login.component';
import {getRouterOutlet} from './utils';

export function doCheckAuthenticated(isAuthenticated,
                                     toastCtrl: ToastController, message,
                                     modalController: ModalController,
                                     dataService) {
    if (!isAuthenticated) {
        toastCtrl.create({
            message,
            duration: 3000,
            position: 'bottom',
            color: 'warning'
        }).then(toast => toast.present());
        doShowLoginModal(modalController, dataService);
        return false;
    }
    return true;
}
export function doShowLoginModal(modalController: ModalController, dataService) {
    const loginModal =  modalController.create({
        component: LoginComponent,
        swipeToClose: true,
        presentingElement: getRouterOutlet(),
        cssClass: 'login-modal-class'
    });
    loginModal.then((modal) => {
        modal.present().then();
        modal.onWillDismiss().then(() => {
            dataService.init();
        });
    });
}
