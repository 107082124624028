import {CreateDealForm} from '../../../models/form';
import {AfterContentInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Plugins} from '@capacitor/core';
import {Store} from '@ngxs/store';
import {AppState} from '../../../store/app.state';
import {Router} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {User} from '../../../models/deal';
import {AuthService} from '../../../services/auth.service';
import {confirmExit, CURRENCIES} from '../../../utils/utils';


const INITIAL_FORM: CreateDealForm = {
  startDate: moment().add(1, 'h').toISOString(),
  title: '',
  location: '',
  price: {unit: CURRENCIES[0]},
  usualPrice: {unit: CURRENCIES[0]},
  description: '',
  externalDealLink: '',
  categoryId: '',
  country: '',
  city: '',
  pickup: false,
  shipping: false,
  shippingFees: { unit: CURRENCIES[0] },
  phone: '',
  whatsappNo: '',
  facebookPage: '',
  instagramPage: '',
  image: '',
  professional: '',
  isStared: false,
  type: 'DEAL',
  event: 'Aucun',
};

@Component({
  selector: 'app-create',
  templateUrl: './create.page.html',
  styleUrls: ['./create.page.scss'],
})
export class CreatePage implements OnInit, AfterContentInit {

  constructor( private store: Store,
               private router: Router,
               private alertController: AlertController,
               private authService: AuthService) {
  }

  form: CreateDealForm;
  currencies = CURRENCIES;
  title = 'Poster un deal';
  submitted = false;

  async ionViewWillLeave() {
    this.form = {...INITIAL_FORM};
  }
  ionViewWillEnter() {
    this.ngOnInit();
  }
  ngOnInit() {
    const user: User = this.authService.loggedInUser();
    const countries = this.store.selectSnapshot(AppState.SelectCountries);
    let country = countries && countries.length > 0 ? countries[0].id : null;
    if (user && user.country) {
      country = user.country;
    }
    this.form = {...INITIAL_FORM, country};
    this.title = 'Poster un deal';
  }
  ngAfterContentInit(): void {
    this.ngOnInit();
  }
  async onBackBtn() {
    await this.router.navigate(['/']);
  }
  async checkBackBtn(): Promise<boolean> {
    return confirmExit(this.alertController);
  }
  onFormSubmitted() {
    this.submitted = true;
  }
}
