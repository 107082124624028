import {Component, OnInit} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {ActionsService} from '../../../services/actions.service';

@Component({
    selector: 'bissap-app-install',
    templateUrl: './app-install.component.html',
    styleUrls: ['./app-install.component.scss'],
})
export class AppInstallComponent implements OnInit {

    constructor(private modalController: ModalController,
                private actionsService: ActionsService,
                private platform: Platform) {
    }

    ngOnInit() {
    }

    dismiss() {
        this.modalController.dismiss();
    }

    install() {
        if (this.isIos()) {
            this.installIos();
        } else {
            this.installAndroid();
        }
    }

    installIos() {
        return this.actionsService.installIosApp();
    }

    installAndroid() {
        return this.actionsService.installAndroidApp();
    }

    private isIos() {
        return this.platform.is('ios');
    }
}
