import {Component, AfterViewInit, OnDestroy, ViewChildren, QueryList, OnInit, NgZone} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {
    ModalController,
    Platform,
    IonRouterOutlet,
    ToastController,
    AlertController,
    PopoverController, MenuController
} from '@ionic/angular';
import {SplashScreen} from '@capacitor/splash-screen';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Router} from '@angular/router';
import 'moment/locale/fr';
import {AppState} from './store/app.state';
import {Store} from '@ngxs/store';
import {User} from './models/deal';
import {AuthService} from './services/auth.service';
import {NotificationsService} from './services/notifications.service';
import {ProfilComponent} from './components/profil/profil.component';
import {doShowLoginModal} from './utils/login-utils';
import {Parameter} from './models/store';
import {DealDataService} from './services/deal-data.service';
import {version, environment} from '../config.json';
import {ManUpService} from './manup/manup.service';
import {ActionsService} from './services/actions.service';
import {socialAuth} from '../config.json';
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';
import {App, URLOpenListenerEvent} from '@capacitor/app';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
    backButtonSubscription;
    // set up hardware back button event.
    lastTimeBackPress = 0;
    timePeriodToExit = 2000;
    isReview = false;
    isLoggedIn = false;
    user: User;
    version: string;
    private loginModal: Promise<HTMLIonModalElement>;
    @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
    parameters: Parameter = {tutoViewed: false, country: ''};

    constructor(
        private platform: Platform,
        private statusBar: StatusBar,
        public modalController: ModalController,
        private router: Router,
        private toastController: ToastController,
        private alertController: AlertController,
        private popoverController: PopoverController,
        private store: Store,
        private authService: AuthService,
        private notificationService: NotificationsService,
        private dealDataService: DealDataService,
        private readonly updates: SwUpdate,
        private manup: ManUpService,
        private menu: MenuController,
        private actionsService: ActionsService,
        private zone: NgZone
    ) {
        this.initializeApp();
        this.version = version;
        if (!this.isProd()) {
            this.version = this.version + ` (${environment})`;
        }
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                console.log('app launch on url : ' + event.url);
                let slug = event.url.split('.deals').pop();
                if (slug) {
                    if (slug.indexOf('Facebook') >= 0) {
                        slug = slug.replace('%5C%2Fdeals%5C%2F', '/deals/');
                        slug = slug.split('%22')[0];
                    } else if (slug.indexOf('%2Fdeals%2F') >= 0) {
                        slug = slug.replace('%2Fdeals%2F', '/deals/');
                    }
                    console.log('Open app link : ' + slug);
                    this.router.navigateByUrl(slug);
                }
            });
        });
    }

    async ngOnInit() {
        this.updatePwa();
    }

    showLoginModal() {
        doShowLoginModal(this.modalController, this.dealDataService);
    }

    initializeApp() {
        this.platform.ready().then(() => {
            SplashScreen.hide().then();
            this.statusBar.overlaysWebView(false);
            this.statusBar.backgroundColorByHexString('#f65169');
            this.notificationService.init();
            this.manup.validate();

            this.store.select(AppState.SelectAuthData).subscribe(authData => {
                if (authData && authData.user) {
                    this.user = authData.user;
                    this.isLoggedIn = true;
                    if (this.loginModal) {
                        this.loginModal.then(modal => {
                            modal.dismiss({});
                        });
                    }
                    this.authService.refreshToken();
                } else {
                    this.user = null;
                    this.isLoggedIn = false;
                }
            });
        });
        this.store.select(AppState.SelectParameters).subscribe(params => {
            if (params) {
                this.parameters = params;
            }
        });
        GoogleAuth.initialize({
            clientId: socialAuth.googleProvideId,
            scopes: ['profile', 'email'],
            grantOfflineAccess: true,
        });
        this.dealDataService.init();
    }

    async ngAfterViewInit() {
        this.backButtonSubscription = this.platform.backButton.subscribe(() => {
            this.routerOutlets.forEach((routerOutlet) => this.handleBackButton(routerOutlet));
        });
    }

    private async handleBackButton(outlet: IonRouterOutlet) {
        this.popoverController.dismiss();
        if (this.router.url.includes('/acceuil')) {
            if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
                navigator['app'].exitApp();
            } else {
                this.toastController.create({
                    message: `Veuillez appuyer à nouveau pour quitter l'application`,
                    duration: 2000,
                    position: 'bottom'
                }).then(ts => {
                    ts.present();
                    this.lastTimeBackPress = new Date().getTime();
                });
            }
        } else if (outlet && outlet.canGoBack()) {
            outlet.pop();
        }
    }

    ngOnDestroy() {
        this.backButtonSubscription.unsubscribe();
    }

    getProfilePic() {
        if (this.user && this.user.avatar) {
            const versionNo = this.user.avatarVersion || 0;
            const versionSuffix = this.user.avatar.indexOf('?') >= 0 ? '&version=' + versionNo : '?version=' + versionNo;
            return this.user.avatar.indexOf('data:image') >= 0 ? this.user.avatar : this.user.avatar + versionSuffix;
        }
        return '/assets/img/user-profilpic.png';
        return this.user && this.user.avatar ? this.user.avatar + 'version=' + this.user.avatarVersion : '/assets/img/user-profilpic.png';
    }

    signOut() {
        this.authService.logout(true);
        this.dealDataService.init();
    }

    showPage(link: string) {
        this.router.navigate([link]);
    }

    showEditProfile() {
        if (this.isLoggedIn) {
            this.popoverController.create({
                component: ProfilComponent,
                cssClass: 'profile-container',
                componentProps: {
                    user: this.user,
                    fromLogin: false
                }
            }).then(popover => popover.present());
        }

    }

    private updatePwa() {
        if (this.updates.isEnabled) {
            this.updates.available.subscribe(event => {
                this.updates.activateUpdate().then(
                    () => {
                        document.location.reload();
                    }
                );
            });
            this.updates.checkForUpdate();
        }
    }

    isLargeScreen() {
        return this.platform.width() > 1250;
    }

    dismiss() {
        this.menu.close('main-content').then();
    }

    showBlog() {
        this.actionsService.openBlog();
    }

    showHelp() {
        this.actionsService.openHelpPage();
    }

    reviewBissapTuto() {
        this.parameters.tutoViewed = false;
        this.isReview = true;
    }

    displayInstallAppMessage() {
        return this.isProd() && !this.isNative() && !this.isLargeScreen();
    }

    isProd() {
        return environment === 'prod';
    }

    private isNative() {
        return this.platform.is('capacitor');
    }

    private isIos() {
        return this.platform.is('ios');
    }
}
