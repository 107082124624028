<div class="ion-justify-content-center ion-align-items-center ion-text-center">
    <div class="image-container">
        <h1>Bienvenue sur Bissap !</h1>
        <img class="bissap-img" src="assets/img/illus-promo.png"/>
    </div>
    <p>La version web BISSAP n'est pas encore disponible.</p>
    <p>Nous travaillons ardemment pour bientôt vous déployer une version web ergonomique.</p>
    <p>Vous pouvez télécharger dès à présent la version mobile <a class="bissap-warning" (click)="installAndroid()">Android </a> ou <a class="bissap-warning" (click)="installIos()">IOS</a>.</p>
    <p> Merci pour votre compréhension</p>
</div>
