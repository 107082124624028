import {BaseLoginProvider, SocialUser} from 'angularx-social-login';


declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID: string = 'APPLE';

    protected auth2: any;

    constructor(private clientId: string, private scope: string, private redirectURI: string) {
        super();
    }

    initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.loadScript(AppleLoginProvider.PROVIDER_ID,
                'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
                () => {
                    this.auth2 = AppleID.auth.init({
                        clientId: this.clientId,
                        scope: this.scope,
                        redirectURI: this.redirectURI,
                        usePopup: true,
                    });
                    resolve();
                });
        });

    }

    signIn(): Promise<SocialUser> {
        console.log('AppleCall Signin');
        return new Promise((resolve, reject) => {
            const promise = AppleID.auth.signIn();
            promise.then(data => {
                const user: SocialUser = new SocialUser();
                user.authorizationCode = data?.authorization?.code;
                user.idToken = data?.authorization?.id_token;
                resolve(user);
            });
            promise.catch((err: any) => {
                reject(err);
            });
        });
    }

    getLoginStatus(): Promise<SocialUser> {
        return null;
    }

    signOut(revoke?: boolean): Promise<any> {
        return null;
    }
}
