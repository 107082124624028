import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from './services/auth-guard.service';
import {HandleUnsavedChangeGuardGuard} from './services/handle-unsaved-change-guard.guard';
import {CreatePage} from './pages/deals/create/create.page';
import {EditerPage} from './pages/deals/editer/editer.page';
import {HandleUnsavedEditChangeGuard} from './services/handle-unsaved-change-edit-guard.guard';

const routes: Routes = [
  { path: 'acceuil', loadChildren: './pages/home/home.module#HomePageModule' },
  {
    path: 'deals/:id',
    loadChildren:
      './pages/deals/deal-detail/deal-detail.module#DealDetailPageModule',
  },
  {
    path: '',
    redirectTo: 'acceuil',
    pathMatch: 'full'
  },
  {
    path: 'creer',
    canActivate: [AuthGuardService],
    canDeactivate: [HandleUnsavedChangeGuardGuard],
    component: CreatePage,
    loadChildren: () => import('./pages/deals/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/notification-manager/notification-manager.module').then( m => m.NotificationManagerPageModule)
  },
  {
    path: 'favoris',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/deals/favorites/favorites.module').then( m => m.FavoritesPageModule)
  },
  {
    path: 'mes-alertes',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/my-alerts/my-alerts.module').then( m => m.MyAlertsPageModule)
  },
  {
    path: 'mes-deals',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/deals/my-deals/my-deals.module').then( m => m.MyDealsPageModule)
  },
  {
    path: 'editer/:id',
    canActivate: [AuthGuardService],
    component: EditerPage,
    canDeactivate: [HandleUnsavedEditChangeGuard],
    loadChildren: () => import('./pages/deals/editer/editer.module').then( m => m.EditerPageModule)
  },
  {
    path: 'utilisateurs/:id',
    loadChildren: () => import('./pages/deals/user-deals/user-deals.module').then( m => m.UserDealsPageModule)
  },
  {
    path: 'rechercher',
    loadChildren: () => import('./pages/deals/rechercher/rechercher.module').then( m => m.RechercherPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.page.module').then(m => m.ProfilePageModule)
  },
  {
    path: '**',
    redirectTo: 'acceuil',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
        { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
