import {Category, Deal, DealSearchResponse, DealVote, SearchCriteria, User, UserDeals} from '../models/deal';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AppState} from '../store/app.state';
import {getRouterOutlet, handleError} from '../utils/utils';
import {
    DeleteDeal,
    DeleteGlobalAlertTrigger,
    StoreAlerts,
    StoreCategories,
    StoreCountries,
    StoreCurrentDealId,
    StoreDeal,
    StoreDeals,
    StoreFavoriteDeals,
    StoreGlobalAlertTrigger,
    StoreMySubscribedDeals,
    StoreNotification, StoreNotifications,
    StorePushSubscription,
    StoreSearchCriteria,
    StoreSelectedCategory,
    StoreUserDeals,
    StoreUserProfile,
    UpdateDeals,
} from '../store/app.action';
import {Country} from '../models/country';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
    apiAuthUserInfo,
    apiDealNotifSubscribe,
    apiDealSubscribed,
    apiDealVote,
    apiDeleteDealsPath,
    apiFavoriteDeals,
    apiGetDealsPath,
    apiGetFavoriteDealsPath,
    apiGetMyDealsPath,
    apiGetStaticDataPath,
    apiGetUserAlerts,
    apiGetUserNotifs,
    apiGlobalAlert,
    apiIncDealViewCount,
    apiNotifSubscribe,
    apiSaveDealsPath,
    apiUpdateNotifStatus,
    config,
    environment,
} from '../../config.json';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
import * as moment from 'moment';
import {StaticData} from '../models/static-data';
import {Page} from '../models/Page';
import {AlertTrigger, DealNotifSubscription, GlobalAlertTrigger, Notification} from '../models/notification';
import {Parameter} from '../models/store';
import {VotedPopupComponent} from '../components/popups/voted-popup/voted-popup.component';

@Injectable({
    providedIn: 'root'
})
export class DealDataService implements OnDestroy {
    private intervalId = null;
    private searchCriteriaObserver$: BehaviorSubject<SearchCriteria>;
    private dealUpdateObserver$: BehaviorSubject<any>;
    private dealChangeObserver$: BehaviorSubject<any>;
    private parameters: Parameter;

    constructor(
        private store: Store,
        private http: HttpClient,
        private auth: AuthService,
        private toast: ToastController,
        private alertController: AlertController,
        private modalCtrl: ModalController) {
        this.searchCriteriaObserver$ = new BehaviorSubject<SearchCriteria>(this.store.selectSnapshot(AppState.SelectSearchCriteria));
        this.dealUpdateObserver$ = new BehaviorSubject<any>(null);
        this.dealChangeObserver$ = new BehaviorSubject<any>(null);

        this.intervalId = setInterval(() => {
            this.init();
        }, 5 * 60 * 1000);

        this.auth.onLogin().subscribe((u) => {
            if (u) {
                this.dealChangeObserver$.next('update');
            }
        });
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    public init() {
        //   this.loadDeals();
        this.loadStaticData();
        this.loadUsersAlerts();
        this.loadUsersNotifs();
        this.store.select(AppState.SelectParameters).subscribe(params => {
            this.parameters = params;
        });
    }

    public loadDeals(): Observable<any> {
        return this.searchDeals(null, null);
    }

    public updateDeals(): Observable<any> {
        return this.fetchDealsFromServer(null, null, true);
    }

    public searchDeals(searchText: string, searchCriteria: SearchCriteria): Observable<any> {
        return this.fetchDealsFromServer(searchText, searchCriteria, false);
    }

    public fetchDealsFromServer(searchText: string, searchCriteria: SearchCriteria, doUpdate: boolean): Observable<any> {
        // private Boolean onlyHotDeals;
        //  private String searchField;
        if (!searchCriteria) {
            searchCriteria = {
                category: '',
                country: '',
                field: '',
                includeExpiredDeals: true,
                onlyHotDeals: false,
                type: 'DEAL',
                sortByField: null,
                discountType: null,
            };
        }
        const observable = this.http.get(`${this.getApiBaseUrl()}${apiGetDealsPath}`, {
            params:
                {
                    searchTerm: searchText ? searchText : '',
                    categoryId: searchCriteria.category || '',
                    country: searchCriteria.country || this.dealCountry,
                    // dealType: searchCriteria.type,
                    includeExpiredDeals: '' + searchCriteria.includeExpiredDeals,
                    onlyRecentDeals: searchCriteria.onlyRecentDeals ? '' + searchCriteria.onlyRecentDeals : 'false',
                    searchAfter: searchCriteria.searchAfter || [],
                    searchField: searchCriteria.field,
                    sortByField: searchCriteria.sortByField ? searchCriteria.sortByField : 'temperature',
                    discountType: searchCriteria.discountType || '',
                    onlyFeaturedDeals: searchCriteria.onlyFeaturedDeals ? '' + searchCriteria.onlyFeaturedDeals : 'false',
                },
            headers: this.getAuthorizationHeader(),
        }).pipe(shareReplay());

        observable.subscribe(
            (data: DealSearchResponse) => {
                if (searchCriteria.noSave) {
                    console.log('No save search');
                } else if (searchCriteria.searchAfter || doUpdate) {
                    this.store.dispatch(new UpdateDeals(data.deals));
                } else {
                    this.store.dispatch(new StoreDeals(data.deals));
                }
            },
            (error => {
                handleError<Deal[]>('loadDeals',
                    [], this.auth, this.store, this.toast);
                return error;
            }));
        return observable;
    }

    public getDealsOrderByTemperature(): Observable<Deal[]> {
        return this.store.select(AppState.SelectDeals).pipe(map(deals => {
            return deals?.sort((a, b) =>
                (b.temperature || 0) - (a.temperature || 0))
                .slice(0, 20);
        }));
    }

    public getDealsOrderByStartDate(): Observable<Deal[]> {
        return this.store.select(AppState.SelectDeals).pipe(map(deals => {
            return deals?.sort((a, b) =>
                moment(b.startDate).diff(moment(a.startDate)))
                .slice(0, 20);
        }));
    }

    public getFeaturedDeals(): Observable<Deal[]> {
        return this.store.select(AppState.SelectDeals).pipe(map(deals => {
            return deals?.filter(d => d.boosted)
                .slice(0, 20);
        }));
    }

    public loadMyDeals(criteria: any = {}) {
        const observable = this.http
            .get(`${this.getApiBaseUrl()}${apiGetMyDealsPath}`,
                {
                    params: {searchAfter: criteria.searchAfter || []},
                    headers: this.getAuthorizationHeader()
                })
            .pipe(
                catchError(handleError<Deal[]>('loadUserDeals',
                    [], this.auth, this.store, this.toast))
            )
            .pipe(shareReplay());
        observable.subscribe((data: DealSearchResponse) => {
            this.store.dispatch(new StoreUserDeals({
                user: data.user,
                deals: data.deals,
                totalElements: data.totalHits,
            }));
        });
        return observable;
    }

    public loadUserDeals(userId, criteria: any = {}) {
        const url = `${this.getApiBaseUrl()}${apiGetMyDealsPath}/${userId}`;
        const observable = this.http
            .get(url,
                {
                    params: {searchAfter: criteria.searchAfter || []},
                    headers: this.getAuthorizationHeader()
                })
            .pipe(
                catchError(handleError<Deal[]>('loadUserDeals',
                    [], this.auth, this.store, this.toast))
            )
            .pipe(shareReplay());
        return observable;
    }

    public loadFavoriteDeals() {
        const userId = this.auth.loggedInUser().id;
        const observable = this.http
            .get(`${this.getApiBaseUrl()}${apiGetFavoriteDealsPath}`,
                {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<Deal[]>('loadFavoriteDeals',
                    [], this.auth, this.store, this.toast))
            ).pipe(shareReplay());
        ;
        observable.subscribe((data: Page<Deal[]>) => {
            this.store.dispatch(new StoreFavoriteDeals(data.content));
        });
        return observable;
    }

    public loadStaticData() {
        const country = this.dealCountry ? this.dealCountry : 'xx';
        this.http.get(`${this.getApiBaseUrl()}${apiGetStaticDataPath}/${country}`)
            .pipe(
                catchError(handleError<Country[]>('loadStaticData', [],
                    this.auth, this.store, this.toast))
            )
            .subscribe((data: StaticData) => {
                this.store.dispatch(new StoreCountries(data.countries));
                this.store.dispatch(new StoreCategories([
                    {id: null, name: 'Tout'},
                    ...data.categories]));
            });

    }

    public getDeals(): Observable<Deal[]> {
        return this.store.select(AppState.SelectDeals);
    }

    public getCategories(): Observable<Category[]> {
        return this.store.select(AppState.SelectCategories);
    }

    public getCountries(): Observable<Country[]> {
        return this.store.select(AppState.SelectCountries);
    }

    public saveDeal(dealForm: Deal) {
        const authData = this.store.selectSnapshot(AppState.SelectAuthData);
        const fullDeal: Deal = {
            ...dealForm,
            id: dealForm.id,
            user: dealForm.user || authData.user
        };
        if (fullDeal.startDate) {
            fullDeal.startDate = moment(fullDeal.startDate).toISOString();
        }
        if (fullDeal.endDate) {
            fullDeal.endDate = moment(fullDeal.endDate).toISOString();
        }
        return this.doSaveDeals(fullDeal);
    }

    private doSaveDeals(fullDeal: Deal) {
        const observable = this.http.post(`${this.getApiBaseUrl()}${apiSaveDealsPath}`,
            fullDeal, {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<Deal[]>('saveMyDeals',
                    [], this.auth, this.store, this.toast))
            ).pipe(shareReplay());
        observable.subscribe((data: Deal) => {
            this.dealUpdateObserver$.next(data);
        });
        return observable;
    }

    private expireDeals(fullDeal: Deal) {
        return this.http.post(`${this.getApiBaseUrl()}${apiSaveDealsPath}/expire/do/${fullDeal.id}`,
            {}, {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<Deal[]>('expireDeals',
                    [], this.auth, this.store, this.toast))
            ).pipe(shareReplay());
    }

    private undoExpireDeals(fullDeal: Deal) {
        return this.http.post(`${this.getApiBaseUrl()}${apiSaveDealsPath}/expire/undo/${fullDeal.id}`,
            {}, {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<Deal[]>('unexpireDeals',
                    [], this.auth, this.store, this.toast))
            ).pipe(shareReplay());
    }

    public filterDeals(category) {
        this.store.dispatch(new StoreSelectedCategory(category));
    }

    public setCurrentDealDetailPage(id: string) {
        this.store.dispatch(new StoreCurrentDealId(id));
        const observable = this.http.post(
            `${this.getApiBaseUrl()}${apiIncDealViewCount}/${id}`,
            {}, {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<number>('incViewCount',
                    0, this.auth, this.store, this.toast))
            );
        observable.subscribe((data) => {
            console.log('Stored deal view count: ' + data);
        });
    }

    public toggleDealStar(deal: Deal) {
        const authData = this.store.selectSnapshot(AppState.SelectAuthData);
        if (deal.stared) {
            this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Deals favoris',
                message: 'Voulez vous supprimer ce deal de vos favoris?',
                buttons: [
                    {
                        text: 'Non',
                        role: 'cancel'
                    }, {
                        text: 'Oui',
                        handler: () => {
                            const observable = this.http.delete(
                                `${this.getApiBaseUrl()}${apiFavoriteDeals}/${deal.id}`,
                                {headers: this.getAuthorizationHeader()})
                                .pipe(
                                    catchError(handleError<Deal[]>('deleteFavorite',
                                        [], this.auth, this.store, this.toast))
                                );
                            observable.subscribe(() => {
                                deal.stared = !deal.stared;
                                this.dealUpdateObserver$.next('stared');
                                this.store.dispatch(new StoreDeal(deal));
                                const message = 'Deal supprimé de vos favoris';
                                this.toast.create({
                                    color: 'success',
                                    message,
                                    duration: 2000,
                                    position: 'bottom'
                                }).then(e => e.present());
                                // this.updateDeals();
                            });

                        }
                    }
                ]
            }).then(alert => alert.present());
        } else {
            const observable = this.http.post(
                `${this.getApiBaseUrl()}${apiFavoriteDeals}/${deal.id}/${authData.user.id}`,
                {}, {headers: this.getAuthorizationHeader()})
                .pipe(
                    catchError(handleError<Deal[]>('addFavorite',
                        [], this.auth, this.store, this.toast))
                );
            observable.subscribe(() => {
                deal.stared = !deal.stared;
                this.store.dispatch(new StoreDeal(deal));
                const message = 'Deal ajouté dans vos favoris';
                this.toast.create({
                    color: 'success',
                    message,
                    duration: 2000,
                    position: 'bottom'
                }).then(e => e.present());
                // this.updateDeals();
            });
        }

    }

    incDealTemperature(deal) {
        this.http.post(`${this.getApiBaseUrl()}${apiDealVote}`,
            {dealId: deal.id, increment: true},
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<any>('incDealTemperature', {},
                    this.auth, this.store, this.toast))
            )
            .subscribe((data: DealVote) => {
                this.handleVoteResponse(data, true);
                deal.temperature = data.temperature;
            });
    }

    decDealTemperature(deal) {
        this.http.post(`${this.getApiBaseUrl()}${apiDealVote}`, {dealId: deal.id, increment: false},
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<any>('decDealTemperature', {},
                    this.auth, this.store, this.toast))
            )
            .subscribe((data: DealVote) => {
                // this.updateDeals();
                this.handleVoteResponse(data, false);
                deal.temperature = data.temperature;
            });
    }

    private handleVoteResponse(voteResponse: DealVote, votedUp: boolean) {
        let color = 'warning';
        let message = `Vous avez déjà voté pour ce deal!`;
        const votedOk = voteResponse.weight !== undefined && voteResponse.weight !== null;
        const showPopup = votedOk && this.parameters && !this.parameters.doNotDiplayVotedPopup;
        if (votedOk) {
            color = 'success';
            message = `Merci d'avoir voté pour ce deal!`;
        }
        if (showPopup) {
            this.modalCtrl.create({
                component: VotedPopupComponent,
                componentProps: {
                    votedUp,
                    parameters: this.parameters
                },
                swipeToClose: true,
                showBackdrop: true,
                presentingElement: getRouterOutlet(),
                cssClass: 'bissap-vote-modal-class'
            }).then(e => e.present());
        } else {
            this.toast.create({
                color,
                message,
                duration: 2000,
                position: 'bottom'
            }).then(e => e.present());
        }
    }

    storeNotificationToken(token) {
        this.store.dispatch(new StorePushSubscription(token));
        this.http.post(`${this.getApiBaseUrl()}${apiNotifSubscribe}`, {token},
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<any>('storeNotifToken', {},
                    this.auth, this.store, this.toast))
            )
            .subscribe((data: any) => {
                console.log('Notif token saved');
            });
    }

    private getApiBaseUrl() {
        return `${config[environment].apiBaseUrl}`;
    }

    getAppBaseUrl() {
        return `${config[environment].appBaseUrl}`;
    }

    getDealById(dealId: string): Observable<any> {
        return this.http.get(`${this.getApiBaseUrl()}${apiSaveDealsPath}/${dealId}`, {headers: this.getAuthorizationHeader()});
    }

    getMyDealById(id: string) {
        return this.getDealById(id);
    }

    deleteDeal(id: string) {
        const observable = this.http.delete(`${this.getApiBaseUrl()}${apiDeleteDealsPath}/${id}`,
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<Deal[]>('deleteFavorite',
                    [], this.auth, this.store, this.toast))
            ).pipe(shareReplay());
        observable.subscribe((data: Deal) => {
            this.store.dispatch(new DeleteDeal(id));
            const message = 'Deal supprimé!';
            this.toast.create({
                color: 'success',
                message,
                duration: 2000,
                position: 'bottom'
            }).then(e => e.present());
        });
        return observable;
    }

    expireDeal(deal: Deal) {
        deal.endDate = moment().format();
        this.expireDeals(deal).subscribe((data: Deal) => {
            deal.status = data.status;
            this.store.dispatch(new StoreDeal(deal));
        });
    }

    undoExpireDeal(deal: Deal) {
        deal.endDate = null;
        this.undoExpireDeals(deal).subscribe((data: Deal) => {
            deal.status = data.status;
            this.store.dispatch(new StoreDeal(deal));
        });
    }

    getUserDeals(userId): Observable<UserDeals> {
        return this.store.select(AppState.SelectUserDeals)
            .pipe(map(userDeals => {
                if (userDeals && userDeals.user && userDeals.user.id === userId) {
                    return userDeals;
                }
                return {user: {}, deals: [], totalElements: 0};
            }));
    }

    updateProfile(user: User) {
        this.store.dispatch(new StoreUserProfile(user));
        const obs = this.http.post(`${this.getApiBaseUrl()}${apiAuthUserInfo}`, user,
            {headers: this.getAuthorizationHeader()}).pipe(shareReplay());
        obs.subscribe((data: User) => {
            this.store.dispatch(new StoreUserProfile(data));
            this.onDealsChange().next('updated');
        });
        const token = this.store.selectSnapshot(AppState.SelectNotifToken);
        if (token) {
            this.storeNotificationToken(token);
        }
        return obs;
    }

    saveSearchCriteria(searchCriteria: SearchCriteria) {
        this.store.dispatch(new StoreSearchCriteria(searchCriteria));
        this.searchCriteriaObserver$.next(searchCriteria);
    }

    saveDealNotifSubscription(alertTrigger: AlertTrigger) {
        const observable = this.http.post(`${this.getApiBaseUrl()}${apiDealNotifSubscribe}`, alertTrigger,
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<any>('saveDealNotifSubscription', {},
                    this.auth, this.store, this.toast))
            ).pipe(shareReplay());

        observable.subscribe((data: DealNotifSubscription) => {
            console.log('Deal Notif subcription saved');
            this.toast.create({
                color: 'success',
                message: 'Votre notification a été bien sauvegardée!',
                duration: 2000,
                position: 'bottom'
            }).then(e => e.present());
            if (!data) {
                this.dealUpdateObserver$.next('stared');
            }
        });
        return observable;
    }

    deleteDealNotifSubscription(notifId: string) {
        const observable = this.http.delete(`${this.getApiBaseUrl()}${apiDealNotifSubscribe}/${notifId}`,
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<any>('deleteDealNotifSubscription', {},
                    this.auth, this.store, this.toast))
            ).pipe(shareReplay());

        observable.subscribe((data: any) => {
            console.log('Deal Notif subcription deleted');
            this.dealUpdateObserver$.next('stared');
        });
        return observable;
    }

    public loadSubscribedDeals() {
        if (!this.auth.isAuthenticated()) {
            return;
        }
        const userId = this.auth.loggedInUser().id;
        const observable = this.http
            .get(`${this.getApiBaseUrl()}${apiDealSubscribed}`,
                {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<Deal[]>('loadSubscribedDeals',
                    [], this.auth, this.store, this.toast))
            ).pipe(shareReplay());
        observable.subscribe((data: Page<Deal[]>) => {
            this.store.dispatch(new StoreMySubscribedDeals(data.content.map(d => {
                return {...d, alertTrigger: {dealId: d.id}};
            })));
        });
        return observable;
    }

    public loadUsersNotifs() {
        if (!this.auth.isAuthenticated()) {
            return;
        }
        const observable = this.http.get(`${this.getApiBaseUrl()}${apiGetUserNotifs}`,
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<Deal[]>('loadUsersNotifs',
                    [], this.auth, this.store, this.toast))
            ).pipe(shareReplay());
        observable.subscribe((data: Notification[]) => {
            this.store.dispatch(new StoreNotifications(data));
        });
        return observable;
    }

    public loadUsersAlerts() {
        const observable = this.http.get(`${this.getApiBaseUrl()}${apiGetUserAlerts}`,
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<Deal[]>('apiGetUserAlerts',
                    [], this.auth, this.store, this.toast))
            ).pipe(shareReplay());
        observable.subscribe((data: Notification[]) => {
            this.store.dispatch(new StoreAlerts(data));
        });
        return observable;
    }

    public updateNotifStatus(isAlert: boolean) {
        const user = this.auth.loggedInUser();
        if (user) {
            const suffix = isAlert ? 'alert' : 'notifs';
            const observable = this.http.post(`${this.getApiBaseUrl()}${apiUpdateNotifStatus}/${suffix}`, {},
                {headers: this.getAuthorizationHeader()})
                .pipe(
                    catchError(handleError<Deal[]>('updateNotifStatus',
                        [], this.auth, this.store, this.toast))
                ).pipe(shareReplay());
            observable.subscribe(() => {
            });
        }

    }

    loadGlobalAlertSubscription() {
        const observable = this.http.get(`${this.getApiBaseUrl()}${apiGlobalAlert}`,
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<any>('getGlobalAlertSubscription', {},
                    this.auth, this.store, this.toast))
            ).pipe(shareReplay());

        observable.subscribe(data => {
            console.log('Global Alert subscription loaded');
            if (data) {
                this.store.dispatch(new StoreGlobalAlertTrigger(data));
            } else {
                this.store.dispatch(new DeleteGlobalAlertTrigger(''));
            }
        });
        return observable;
    }

    saveGlobalAlertSubscription(alertTrigger: GlobalAlertTrigger): Observable<GlobalAlertTrigger> {
        return this.http.post(`${this.getApiBaseUrl()}${apiGlobalAlert}`, alertTrigger,
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<any>('saveGlobalAlertSubscription', {},
                    this.auth, this.store, this.toast))
            )
            .pipe(map(data => {
                console.log('Global Alert subscription saved');
                this.store.dispatch(new StoreGlobalAlertTrigger(alertTrigger));
                return data;
            }));
    }

    deleteGlobalAlertSubscription(notifId: string): Observable<void> {
        return this.http.delete(`${this.getApiBaseUrl()}${apiGlobalAlert}/${notifId}`,
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<any>('deleteGlobalAlertSubscription', {},
                    this.auth, this.store, this.toast))
            )
            .pipe(map(() => {
                console.log('Global Alert subscription deleted');
                this.store.dispatch(new DeleteGlobalAlertTrigger(notifId));
                return;
            }));
    }

    private getAuthorizationHeader() {
        const token = this.store.selectSnapshot(AppState.SelectAuthData).accessToken;
        if (token) {
            let headers = new HttpHeaders();
            headers = headers.set('Authorization', `Bearer ${token}`);
            return headers;
        }
        return null;
    }

    fetchNotificationSubscription(dealId: string) {
        return this.http.get(`${this.getApiBaseUrl()}${apiDealNotifSubscribe}/${dealId}`,
            {headers: this.getAuthorizationHeader()})
            .pipe(
                catchError(handleError<any>('fetchNotificationSubscription', {},
                    this.auth, this.store, this.toast))
            );
    }

    onSearchCriteriaChange(): BehaviorSubject<SearchCriteria> {
        return this.searchCriteriaObserver$;
    }

    onDealUpdated(): BehaviorSubject<any> {
        return this.dealUpdateObserver$;
    }

    onDealsChange(): BehaviorSubject<any> {
        return this.dealChangeObserver$;
    }

    get dealCountry(): string {
        const countryFromParams = this.store.selectSnapshot(AppState.SelectParameters).country;
        return this.auth.loggedInUser() ? this.auth.loggedInUser().country : countryFromParams;
    }

    findBissapCountries() {
        return ['bj', 'bf', 'cm', 'ci', 'ml', 'ne', 'sn', 'tg', 'fr'];
    }
}
