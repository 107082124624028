/**
 * Created by ouedraog on 25/04/2020.
 */
import {AuthService} from '../services/auth.service';
import {Store} from '@ngxs/store';
import {ToastController} from '@ionic/angular';
import {Observable, throwError} from 'rxjs';
import {Category, Deal, DealStatus, User} from '../models/deal';
import * as moment from 'moment';
import {StoreCurrentUser} from '../store/app.action';
import {FormGroup} from '@angular/forms';
import {PhoneNumber, PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';

export const APP_STATE_KEY = 'BissapAppStore';
export const CURRENCIES = [
    'FCFA', 'EUR', 'USD'
];
export const calendarStrings = {
    lastDay : '[Hier à] LT',
    sameDay : '[Aujourd\'hui à] LT',
    nextDay : '[Demain à] LT',
    lastWeek : 'lll',
    nextWeek : '[Ce] dddd [à] LT',
    sameElse : 'lll'
};

export const onlineStoreStepsFormFields =             [
    ['dealSourceType'],
    ['location', 'externalDealLink', 'professional'],
    ['title', 'description', 'categoryId', 'startDate', 'endDate'],
    ['discountType', 'priceValue', 'priceUnit',
        'usualPriceValue', 'usualPriceUnit', 'discount', 'discountBuy',
        'discountOffer', 'pickup', 'shipping',
        'shippingFeesValue', 'shippingFeesUnit'],
    [],
];

export const physicalStoreStepsFormFields =             [
    ['dealSourceType'],
    [],
    ['location', 'country', 'city', 'professional', 'phone', 'whatsappNo'],
    ['title', 'description', 'categoryId', 'startDate', 'endDate'],
    ['discountType', 'priceValue', 'priceUnit',
        'usualPriceValue', 'usualPriceUnit', 'discount', 'discountBuy',
        'discountOffer', 'pickup', 'shipping',
        'shippingFeesValue', 'shippingFeesUnit'],
    ['externalDealLink'],
];
export function getRouterOutlet() {
    return document.getElementById('bissap-deal-detail');
}


export async function showModal(component, modalController) {
    const modal = await modalController.create({
      component,
      swipeToClose: true,
      presentingElement: getRouterOutlet(),
      backdropDismiss: true,
      cssClass: 'bissap-common-modal-class'
    });
    return await modal.present();
  }
export async function showAlertManager(popoperCtrl, deal, component) {
    const popover = await popoperCtrl.create({
        component,
        componentProps: {
            deal
        },
        cssClass: 'alert-manager-container'
    });
    popover.present();
}
export function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export function guid() {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function getPercent(deal) {
  if (!deal.usualPrice || !deal.price ) {
    return '';
  }
  const percent = (deal.price.value - deal.usualPrice.value) / deal.usualPrice.value ;
  return Math.round(percent * 100) + '%';
}

export function shippingFees(deal) {
  if (!deal.shippingFees || deal.shippingFees.value === 0 || !deal.shippingFees.value ) {
    return 'Gratuit';
  }
  return deal.shippingFees.value + ' ' + deal.shippingFees.unit;
}

export function replacer(key, value) {
    const originalObject = this[key];
    if(originalObject instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(originalObject.entries()), // or with spread: value: [...originalObject]
        };
    } else {
        if(originalObject instanceof Set){
            return {
                dataType: 'Set',
                value: Array.from(originalObject), // or with spread: value: [...originalObject]
            };
        }
        return value;
    }
}
export function reviver(key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
        if (value.dataType === 'Set') {
            return new Set(value.value);
        }
    }
    return value;
}
export function handleError<T>(
    operation = 'operation',
    result?: T,
    authService?: AuthService,
    store?: Store,
    toastCtl?: ToastController) {
    return (error: any): Observable<T> => {
        if (error.status === 401 && authService) {
            authService.logout(false);
        } else {
            if (toastCtl && operation !== 'loadDeals') {
                toastCtl.create({
                    message: `Une erreur est survenue lors de la communication avec le serveur!`,
                    position: 'bottom',
                    color: 'warning',
                    duration: 5000
                }).then(tst => tst.present());
            }
        }
        console.error(error); // log to console instead
        console.log(`${operation} failed: ${error.message}`);
        // Let the app keep running by returning an empty result.
        return throwError(error);
    };
}
export function isDealExpired(deal: Deal) {
    return (deal.endDate && moment(deal.endDate).isBefore(moment())) || deal.status === DealStatus.EXPIRED;
}

export function displayUserDeals(user: User, authService, router, store: Store) {
    store.dispatch(new StoreCurrentUser(user)).subscribe(() => {
        const myUser = authService.loggedInUser();
        /*const url = ( myUser && myUser.id === user.id ) ?
            'mes-deals' : `utilisateurs/${user.id}/deals`;*/
        const url = `utilisateurs/${user.id}`;
        router.navigate([url]);
    });
}

const validationMessages = {
    phone: [
        {type: 'required', message: 'Veuillez renseigner votre numéro de téléphone'},
        {type: 'valid', message: 'Veuillez renseigner un numéro valide'}
    ],
    whatsappNo: [
        {type: 'valid', message: 'Veuillez renseigner un numéro valide'}
    ],
    country: [
        {type: 'required', message: 'Veuillez renseigner votre pays'}
    ],
    city: [
        {type: 'required', message: 'Veuillez renseigner votre ville'},
        {type: 'maxlength', message: 'La ville ne doit pas contenir plus de 20 caractères'},
    ],
    title: [
        {type: 'minlength', message: 'Le titre doit contenir au moins 10 caractères'},
        {type: 'maxlength', message: 'Le titre ne doit pas contenir plus de 100 caractères'},
        {type: 'required', message: 'Veuillez renseigner le titre'}
    ],
    description: [
        {type: 'minlength', message: 'La description doit contenir au moins 100 caractères'},
        {type: 'maxlength', message: 'La description ne doit pas contenir plus de 1000 caractères'},
        {type: 'required', message: 'Veuillez renseigner la description'}
    ],
    professional: [
        {type: 'required', message: 'Veuillez renseigner le commerçant'},
        {type: 'maxlength', message: 'Veuillez renseigner moins de 20 caractères'},
    ],
    startDate: [
        {type: 'valid', message: 'Veuillez renseigner une date valide'},
        {type: 'required', message: 'Veuillez renseigner une date valide'},
    ],
    endDate: [
        {type: 'valid', message: 'La date de fin du deal doit être après la date de début et la date du jour'},
        {type: 'maxvalidity', message: 'La durée de votre promotion ne peut pas excéder 4 mois'},
    ],
    priceValue: [
        {type: 'required', message: 'Veuillez renseigner un prix valide'},
        {type: 'max', message: `Le prix doit être inférieur à 9999999999`},
        {type: 'min', message: `Le prix doit être supérieur à 0`},
        {type: 'pattern', message: 'Veuillez renseigner un nombre avec 2 décimales maximum'}
    ],
    usualPriceValue: [
        {type: 'max', message: `Le prix doit être inférieur à 9999999999`},
        {type: 'pattern', message: 'Veuillez renseigner un nombre avec 2 décimales maximum'},
        {type: 'valid', message: 'Le prix de promotion ne peut pas être supérieur ou égal au prix habituel'}
    ],
    priceUnit: [
        {type: 'required', message: 'Veuillez renseigner une devise'}
    ],
    usualPriceUnit: [
        {type: 'required', message: 'Veuillez renseigner une devise'}
    ],
    shippingFeesValue: [
        {type: 'max', message: `Veuillez renseigner un nombre inférieur à 9999999999`},
        {type: 'pattern', message: 'Veuillez renseigner un nombre avec 2 décimales maximum'},
        {type: 'max', message: `Le prix doit être inférieur à 9999999999`},
        {type: 'required', message: 'Veuillez renseigner les frais de port'}
    ],
    shippingFeesUnit: [
        {type: 'required', message: 'Veuillez renseigner une devise'}
    ],
    categoryId: [
        {type: 'required', message: 'Veuillez renseigner une catégorie'}
    ],
    externalDealLink: [
        {type: 'valid', message: 'Veuillez renseigner une url valide'},
        {type: 'required', message: 'Veuillez renseigner le lien du deal'}
    ],
    facebookPage: [
        {type: 'valid', message: 'Veuillez renseigner une url valide'},
        {type: 'pattern', message: 'Veuillez renseigner une page Facebook valide'},
    ],
    instagramPage: [
        {type: 'valid', message: 'Veuillez renseigner une url valide'},
        {type: 'pattern', message: 'Veuillez renseigner une page Instagram valide'},
    ],
    location: [
        {type: 'maxlength', message: 'Veuillez renseigner moins de 50 caractères'},
        {type: 'required', message: 'Veuillez renseigner où se trouve le deal'}
    ],
    discountType: [
        {type: 'required', message: 'Veuillez renseigner le type de promotion'},
    ],
    discount: [
        {type: 'required', message: 'Veuillez renseigner la réduction'},
        {type: 'max', message: `La réduction doit être inférieure à 100`},
        {type: 'min', message: `La réduction doit être supérieure à 1`},
        {type: 'pattern', message: 'Veuillez renseigner un nombre avec 2 décimales maximum'}
    ],
    discountBuy: [
        {type: 'required', message: 'Veuillez renseigner le nombre de produits à acheter'},
        {type: 'max', message: `Le nombre de produits à acheter doit être inférieur à 100`},
        {type: 'min', message: `Le nombre de produits à acheter doit être supérieur à 1`},
        {type: 'pattern', message: 'Veuillez renseigner un nombre entier entre 1 et 100'}
    ],
    discountOffer: [
        {type: 'required', message: 'Veuillez renseigner le nombre de produits à offrir'},
        {type: 'max', message: `Le nombre de produits à offrir doit être inférieur à 100`},
        {type: 'min', message: `Le nombre de produits à offrir doit être supérieur à 1`},
        {type: 'pattern', message: 'Veuillez renseigner un nombre entier entre 1 et 100'}
    ]
};

export function getErrorMessages(name: string, createDealGroup: FormGroup) {
    const messages = [];
    if (validationMessages[name]) {
        for (const validation of validationMessages[name]) {
            if (createDealGroup.get(name)) {
                const control = createDealGroup.get(name);
                if (control.hasError(validation.type) && control.touched) {
                    messages.push(validation.message);
                    break;
                }
            }
        }
    }
    return messages;
}

export function confirmExit(alertController): Promise<boolean> {
    return new Promise(async (resolve) => {
        const alert = await alertController.create({
            cssClass: 'my-custom-class',
            header: 'Poster un deal',
            subHeader: 'Voulez-vous quitter?',
            message: 'Tous vos changements seront perdus.',
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    handler: () => {
                        resolve(false);
                    }
                }, {
                    text: 'Supprimer',
                    handler: () => {
                        resolve(true);
                    }
                }
            ]
        });
        await alert.present();
    });
}

export function parseTelephoneNumberToIonIntlTelValue(rawNumber, defaultCountryCode= 'ZZ') {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const probePhoneNumber = rawNumber;
    let googleNumber: PhoneNumber;
    try {
        googleNumber = phoneUtil.parse(
            probePhoneNumber,
            defaultCountryCode.toUpperCase()
        );
    } catch (e) {
    }
    const internationalNo = googleNumber
        ? phoneUtil.format(googleNumber, PhoneNumberFormat.INTERNATIONAL)
        : null;
    const nationalNo = googleNumber
        ? phoneUtil.format(googleNumber, PhoneNumberFormat.NATIONAL)
        : '';
    const regionCode = googleNumber
        ? phoneUtil.getRegionCodeForNumber(googleNumber).toLowerCase()
        : defaultCountryCode;

    return internationalNo ? {
        internationalNumber: internationalNo,
        nationalNumber: nationalNo,
        isoCode: regionCode
    } : null;
}

export function isDealHot(temperature: number) {
    return temperature > 40;
}
