import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngxs/store';
import {StoreParameters} from '../../store/app.action';
import {IonSlides} from '@ionic/angular';
import {Parameter} from '../../models/store';
import {Country} from '../../models/country';
import {DealDataService} from '../../services/deal-data.service';
import {AppState} from '../../store/app.state';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  @ViewChild('slides', { read: IonSlides }) slides: IonSlides;
  countries: Country[] = [];
  parameters: Parameter;
  hasError = false;
  @Input() isReview = false;
  constructor(private store: Store, private dataService: DealDataService) { }

  ngOnInit() {
    this.dataService.getCountries().subscribe(countries => {
      this.countries = countries || [];
    }, error => this.hasError = true);
    this.parameters = this.store.selectSnapshot(AppState.SelectParameters) || { tutoViewed: true, country: ''};
  }

  ngOnDestroy(): void {
    this.store.dispatch(new StoreParameters(this.parameters));
  }
  async continue() {
    await this.slides.slideNext();
  }
  async skip() {
    const lastSlide = await this.slides.length() - 1;
    await this.slides.slideTo(lastSlide);
  }
  end() {
    this.parameters.tutoViewed = true;
    this.store.dispatch(new StoreParameters(this.parameters));
  }
}
