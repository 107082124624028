<ion-header>
  <ion-toolbar>
    <ion-title>Saisir la description</ion-title>
    <ion-button class="dismiss-btn"
                slot="start"
                fill="clear"
                (click)="cancel()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-button>
    <ion-button class="dismiss-btn"
                fill="clear"
                [disabled]="!formGroup.get('description').valid"
                slot="end"
                (click)="validate()">
      <ion-icon name="checkmark-outline"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div [formGroup]="formGroup" *ngIf="formGroup" class="container">
    <quill-editor customToolbarPosition="bottom"
                  [styles]="{height: '250px'}"
                  theme="snow"
                  placeholder="Vous ne savez pas quoi écrire? Présentez votre produit en quelques mots. Dites à la communauté pourquoi votre promotion est intéressante, pourquoi vous êtes le meilleur. Toute information permettant d’augmenter votre visibilité est importante !"
                  formControlName="description"
                  maxlength="1000"
                  minlength="100">
      <div quill-editor-toolbar>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>
        <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <select class="ql-align">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </span>
        <span class="ql-formats">
            <select class="ql-color"></select>
          </span>
        <span class="ql-formats">
            <button class="ql-emoji"></button>
          </span>

      </div>
    </quill-editor>
    <div class="ion-text-right remaining">
      Nombre de caractères : <span [class.text-bissap]="!isDescriptionOk" [class.text-green]="isDescriptionOk">{{ descriptionLength }}</span>/1000
    </div>
    <ion-label *ngFor="let msg of getErrorMessages('description')" color="danger">
      <ion-icon color="danger" name="alert-circle"></ion-icon>
      <span>{{ msg }} </span>
    </ion-label>

    <div class="validate-container">
      <ion-button
              [disabled]="!formGroup.get('description').valid"
              class="validate" expand="block" (click)="validate()" >
        Valider
      </ion-button>
    </div>

  </div>
</ion-content>
