<p class="error-message" *ngIf="deal?.message" >
    <ion-icon name="warning-outline"></ion-icon> {{deal?.message}}
</p>
<div id="bissap-deal-detail" class="bissap-deal-detail" *ngIf="deal" >

    <div class="bissap-deal-detail-p1">
    <div class="bissap-image-container" slot="start">
    <span class="ion-float-left" *ngIf="deal.temperature != null">
      <ion-icon class="bissap-frozen" name="snow-outline" *ngIf="deal.temperature < 0"></ion-icon>
        <ion-icon class="bissap-hot" name="flame" *ngIf="deal.temperature >= 100"></ion-icon>
    </span>

        <ion-img ionImgViewer scheme="light"  [src]="productImg" ></ion-img>
    </div>
    <ion-row *ngIf="deal.temperature != null && isPublished()"
             class="ion-justify-content-between bissap-temp-wrapper">
        <ion-col size="4">
            <div
                    class="bissap-temp-container {{ isDealHot()
              ? 'bissap-temp-positive': 'bissap-temp-negative' }}">

                <a class="temp-action bissap-temp-dec {{deal.voted ? 'hidex': ''}}"
                   (click)="decTemperature()">
                    <img src="/assets/img/picto-moins.png" class="thermo-picto"/>
                </a>
                <span class="bissap-temp-value ion-text-center">
                    {{ deal.temperature | number }}°
                </span>
                <a class="temp-action bissap-temp-dec {{deal.voted ? 'hidex': ''}}"
                   (click)="incTemperature()">
                    <img src="/assets/img/picto-plus.png" class="thermo-picto"/>
                </a>
            </div>
        </ion-col>
        <ion-col size="8">
        <span class="bissap-data-actions ion-float-right">
             <ion-button color="light" size="small" (click)="showAlertManager()" [disabled]="isExpired()">
                    <ion-icon slot="icon-only" name="notifications-outline" *ngIf="!hasAlertTrigger()"></ion-icon>
                    <ion-icon color="danger" slot="icon-only" name="notifications-sharp"
                              *ngIf="hasAlertTrigger()"></ion-icon>
             </ion-button>
            <ion-button color="light" size="small" (click)="toggleStar()" [disabled]="isExpired()">
              <ion-icon slot="icon-only" name="star-outline" *ngIf="!deal.stared"></ion-icon>
              <ion-icon color="danger" slot="icon-only" name="star-sharp" *ngIf="deal.stared"></ion-icon>
            </ion-button>
            <ion-button color="light" size="small" (click)="socialShare()" [disabled]="isExpired()">
              <ion-icon slot="icon-only" name="share-social-outline" ></ion-icon>
            </ion-button>
        </span>
        </ion-col>
    </ion-row>


    <div class="bissap-other-container">
        <ion-row class="pro-line">
            <ion-col *ngIf="deal.professional" size="6" class="bissap-pro-mention">
              <span >
                  <ion-icon name="pricetag-outline"></ion-icon>
                  Deals
              </span>
                <span class="icon-bissap">{{deal.professional}}</span>
            </ion-col>

            <ion-col *ngIf="deal.city" class="location-col" size="6">
                <div class="bissap-location-wrapper icon-bissap">
                    <img src="/assets/img/city-picto.png" class="city-picto"/>
                    {{ deal.city }}
                </div>

            </ion-col>
        </ion-row>
        <hr>
        <h2 class="ion-text-wrap bissap-data-title">{{ deal.title}}</h2>
        <div class="bissap-data-pro" *ngIf="isPriceAbsolute">
                <span class="bissap-price"
                      *ngIf="deal.price">{{ deal.price.value | currency:'XOF':deal.price.unit:'2.0-0':'fr'}}</span>
                <span class="bissap-price-usual"
                      *ngIf="deal.usualPrice && deal.usualPrice.value ">{{ deal.usualPrice.value | currency:'XOF':deal.price.unit:'2.0-0':'fr'}}</span>
                <span class="bissap-price-percent"
                      *ngIf="deal.usualPrice && deal.price && deal.usualPrice.value"> ({{ getPercent() }})</span>
                <span *ngIf="deal.shipping" class="bissap-price-shipping">
                 <span> <img src="/assets/img/truck.svg"/> {{ shippingFees()  }}</span>
            </span>
        </div>
        <div class="bissap-data-pro" *ngIf="isFree">
            <span class="bissap-price">Gratuit</span>
        </div>
        <div class="bissap-data-pro" *ngIf="isPricePercent">
            <span class="bissap-price">{{deal.discount}}% de réduction</span>
        </div>
        <div class="bissap-data-pro" *ngIf="isOffer">
            <span class="bissap-price">{{deal.discountBuy}} acheté(s), {{deal.discountOffer}} offert(s)</span>
        </div>
        <div class="social-line" >
            <div *ngIf="deal.phone" (click)="openWithPhone()">
                <ion-button class="btn-social" [disabled]="isExpired()">
                    <ion-icon name="call-outline"></ion-icon>
                </ion-button>
            </div>
            <div *ngIf="deal.phone && deal.whatsappNo">
                <ion-button class="btn-social" (click)="openWithWhatsapp()" [disabled]="isExpired()">
                    <ion-icon name="logo-whatsapp"></ion-icon>
                </ion-button>
            </div>
            <div *ngIf="deal.facebookPage" (click)="openFacebookPage()">
                <ion-button class="btn-social" [disabled]="isExpired()">
                    <ion-icon class="social-icon" name="logo-facebook"></ion-icon>
                </ion-button>
            </div>
            <div *ngIf="deal.instagramPage" (click)="openInstaPage()">
                <ion-button class="btn-social" [disabled]="isExpired()">
                    <ion-icon  name="logo-instagram"></ion-icon>
                </ion-button>
            </div>
            <div *ngIf="deal.externalDealLink" (click)="openExternalLink()">
                <ion-button class="btn-social" [disabled]="isExpired()">
                    <ion-icon  name="link-outline"></ion-icon>
                </ion-button>
            </div>
            <div *ngIf="deal.location" (click)="openDealLocation()">
                <ion-button class="btn-social" [disabled]="isExpired()">
                    <ion-icon  [name]="locationIcon"></ion-icon>
                </ion-button>
            </div>
        </div>

        <ion-row>
            <ion-col>
                <div class="bissap-avatar-container">
                    <ion-chip *ngIf="deal.user" color="light" class="ion-float-left" (click)="displayUserDeals(deal.user)">
                        <ion-avatar>
                            <ion-img ionImgViewer scheme="light" [src]="getAvatar()"></ion-img>
                        </ion-avatar>
                        <span class="bissap-avatar-username">{{ deal.user.name }}</span>
                        <ion-icon *ngIf="deal.user.trusted" class="trusted" name="checkmark-circle" title="Compte vérifié par l'équipe Bissap"></ion-icon>
                    </ion-chip>

                </div>
            </ion-col>
            <ion-col>
                <div class="publish-info">
                    <div class="published" *ngIf="deal.startDate">
                        <ion-icon name="time-outline"></ion-icon>
                        Publié le {{ deal.startDate |  amLocale:'fr' | amDateFormat:"DD MMM YYYY à HH:mm" }}
                    </div>
                    <div class="published" *ngIf="deal.endDate">
        <span>
            <ion-icon name="hourglass-outline"></ion-icon>
            Expire le {{ deal.endDate |  amLocale:'fr' | amDateFormat:'DD MMM YYYY à HH:mm' }}
        </span>
                    </div>
                </div>

            </ion-col>
        </ion-row>

    </div>
    </div>
    <div class="bissap-deal-detail-p2">
    </div>
    <div class="bissap-deal-detail-p3">
        <div class="bissap-description">
            <quill-view-html class="ion-text-wrap" [content]="deal.description" theme="snow" *ngIf="deal.description"> {{ deal.description }}></quill-view-html>
        </div>
    </div>
</div>

