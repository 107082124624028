import {Component, Input, OnInit} from '@angular/core';
import {Deal} from '../../../models/deal';
import {AlertController, PopoverController, ToastController} from '@ionic/angular';
import {AlertTrigger} from '../../../models/notification';
import {Store} from '@ngxs/store';
import {AppState} from '../../../store/app.state';
import {DealDataService} from '../../../services/deal-data.service';
import {FormControl, Validators} from '@angular/forms';
import {DealFormValidator} from '../../../utils/validators';

@Component({
  selector: 'app-alert-manager',
  templateUrl: './alert-manager.component.html',
  styleUrls: ['./alert-manager.component.scss'],
})
export class AlertManagerComponent implements OnInit {
  @Input() deal: Deal;
  alertTrigger: AlertTrigger = {
    dealId: '',
    createdAt: '',
    createdBy: '',
    authorPosted: {
      enabled: false,
      pushEnabled: false
    },
    isExpired: {
      enabled: false,
      pushEnabled: false
    },
    isExpiring: {
      enabled: false,
      pushEnabled: false
    },
    temperatureMax: {
      value: 0,
      enabled: false,
      pushEnabled: false
    },
    temperatureMin: {
      value: 0,
      enabled: false,
      pushEnabled: false
    },
    voted: {
      enabled: false,
      pushEnabled: false
    }
  };
  loading = false;
  error = false;
  temperatureForm = new FormControl(0, [
    Validators.max(99999), Validators.min(-99999), Validators.pattern('^-?[0-9]*$'),
  Validators.maxLength(6)]);
  constructor(private popoverCtrl: PopoverController,
              private store: Store,
              private alertController: AlertController,
              private dealDataService: DealDataService,
              private toastCtrl: ToastController) { }
  getTitleFromDeal() {
    if (this.deal.title && this.deal.title.length > 26) {
      return this.deal.title.substring(0, 26) + '...'  ;
    }
    return this.deal.title;
  }
  ngOnInit() {
    this.temperatureForm.valueChanges.subscribe((value) => {
      if (value) {
        this.alertTrigger.temperatureMin.enabled = true;
      }
    });
    if (this.deal && this.deal.alertTrigger) {
      this.loading = true;
      this.dealDataService.fetchNotificationSubscription(this.deal.id)
          .subscribe(data => {
            const alertData = data;
            if (!data.authorPosted) {
              alertData.authorPosted = {
                enabled: false,
                pushEnabled: false
              };
            }
            if (!data.isExpired) {
              alertData.isExpired = {
                enabled: false,
                pushEnabled: false
              };
            }
            if (!data.isExpiring) {
              alertData.isExpiring = {
                enabled: false,
                pushEnabled: false
              };
            }
            if (!data.temperatureMax) {
              alertData.temperatureMax = {
                enabled: false,
                pushEnabled: false,
                value: 0,
              };
            }
            if (!data.temperatureMin) {
              alertData.temperatureMin = {
                enabled: false,
                pushEnabled: false,
                value: 0,
              };
            }
            if (!data.voted) {
              alertData.voted = {
                enabled: false,
                pushEnabled: false
              };
            }
            this.temperatureForm.setValue(alertData.temperatureMin.value);
            this.alertTrigger = alertData;
            this.loading = false;
            this.error = false;
          }, error => {
            this.loading = false;
            this.error = true;
          });
    }
  }

  dismiss() {
    this.popoverCtrl.dismiss();
  }

  async saveAlert() {
    this.temperatureForm.setValue(parseInt(this.temperatureForm.value));
    if (!this.alertTrigger.authorPosted.enabled
        && !this.alertTrigger.authorPosted.pushEnabled
        && !this.alertTrigger.voted.enabled
        && !this.alertTrigger.voted.pushEnabled
        && !this.alertTrigger.isExpired.enabled
        && !this.alertTrigger.isExpired.pushEnabled
        && !this.alertTrigger.isExpiring.enabled
        && !this.alertTrigger.isExpiring.pushEnabled
        && !this.alertTrigger.temperatureMax.enabled
        && !this.alertTrigger.temperatureMax.pushEnabled
        && !this.alertTrigger.temperatureMin.enabled
        && !this.alertTrigger.temperatureMin.pushEnabled) {
      await this.deleteAlertWithText('Créer une alerte',
          `Voulez vous abandonner la création de l'alerte`,
          `Aucune alerte n'a été définie!`);
    } else {
      this.alertTrigger.temperatureMin.value = this.temperatureForm.value;
      this.alertTrigger.dealId = this.deal.id;
      this.alertTrigger.authorPosted.pushEnabled = this.alertTrigger.authorPosted.enabled;
      this.alertTrigger.voted.pushEnabled = this.alertTrigger.voted.enabled;
      this.alertTrigger.isExpired.pushEnabled = this.alertTrigger.isExpired.enabled;
      this.alertTrigger.isExpiring.pushEnabled = this.alertTrigger.isExpiring.enabled;
      this.alertTrigger.temperatureMax.pushEnabled = this.alertTrigger.temperatureMax.enabled;
      this.alertTrigger.temperatureMin.pushEnabled = this.alertTrigger.temperatureMin.enabled;
      this.dealDataService.saveDealNotifSubscription(this.alertTrigger).subscribe(
          (data) => {
                 this.deal.alertTrigger = data;
          }
      );
      await this.popoverCtrl.dismiss();
    }
  }

  public async deleteAlert() {
    this.deleteAlertWithText(`Suppression de l'alerte`,
        'Voulez-vous supprimer cette alerte?',
        `Vous ne recevrez plus d'alertes sur ce deal`);
  }

  private async deleteAlertWithText(header, subHeader, message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header,
      subHeader,
      message,
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel'
        }, {
          text: 'Supprimer',
          handler: () => {
            this.dealDataService.deleteDealNotifSubscription(this.alertTrigger.id).subscribe(
                () => {
                  this.deal.alertTrigger = null;
                }
            );
            this.popoverCtrl.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  dealBelongsToMe() {
    const authData = this.store.selectSnapshot(AppState.SelectAuthData);
    return this.deal?.user?.id === authData?.user?.id;
  }

    numberOnlyValidation(event: any) {
        DealFormValidator.integersOnlyValidation(event);
    }

}
