import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ActionsService} from '../../../services/actions.service';

@Component({
    selector: 'bissap-design-warning',
    templateUrl: './design-warning.component.html',
    styleUrls: ['./design-warning.component.scss'],
})
export class DesignWarningComponent implements OnInit {

    constructor(private modalController: ModalController,
                private actionsService: ActionsService) {
    }

    ngOnInit() {
    }

    dismiss() {
        this.modalController.dismiss();
    }

    installIos() {
        return this.actionsService.installIosApp();
    }

    installAndroid() {
        return this.actionsService.installAndroidApp();
    }
}
