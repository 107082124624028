import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AppStateModel, AuthData, Parameter} from '../models/store';
import {Category, Deal, SearchCriteria, User, UserDeals} from '../models/deal';
import {Country} from '../models/country';
import { Badge } from '@ionic-native/badge/ngx';
import {
    ClearSearchHistory,
    DeleteDeal,
    DeleteGlobalAlertTrigger,
    ReadAlert,
    ReadNotification,
    StoreAlert, StoreAlerts,
    StoreAuthData,
    StoreAuthTokens,
    StoreCategories,
    StoreCountries,
    StoreCurrentDealId, StoreCurrentUser,
    StoreDeal,
    StoreDeals,
    StoreFavoriteDeals,
    StoreGlobalAlertTrigger,
    StoreMySubscribedDeals,
    StoreNotification, StoreNotifications,
    StoreParameters,
    StorePushSubscription, StoreSearchCriteria,
    StoreSelectedCategory,
    StoreUserDeals,
    StoreUserProfile, UpdateDeals,
    UpdateSearchText
} from './app.action';
import {GlobalAlertTrigger, Notification} from '../models/notification';
import {Injectable} from '@angular/core';
import {APP_STATE_KEY} from '../utils/utils';

@State<AppStateModel>({
    name: APP_STATE_KEY,
    defaults: {
        authData: {
            accessToken: '',
            refreshToken: '',
            user: null
        },
        categories: [],
        deals: new Map<string, Deal>(),
        countries: [],
        selectedCategory: { name: 'Tout', id: '0' },
        currentDealId: '',
        searchText: '',
        notifications: new Map<string, Notification>(),
        alerts: new Map<string, Notification>(),
        globalAlertTriggers: new Map<string, GlobalAlertTrigger>(),
        pushSubscriptionToken: null,
        recentSearches: [],
        searchCriteria: null,
        userDeals: {user: null, deals: [], totalElements: 0},
        favoriteDeals: [],
        parameters: {
            tutoViewed: false,
            country: '',
        },
        subscribedDeals: [],
        currentUser: null,
    }
})
@Injectable()
export class AppState {
    constructor(private badge: Badge) {
        this.badge.clear().then((cleared) => {
            console.log('Cleared badge: ' + cleared);
        });
    }

    @Selector() static SelectAuthData(state: AppStateModel): AuthData {
        return state.authData;
    }
    @Selector() static SelectCategories(state: AppStateModel): Category[] {
        return state.categories;
    }
    @Selector() static SelectDealsWithAlerts(state: AppStateModel): Deal[] {
       return [...state.deals.values()].filter(deal => {
           return deal.alertTrigger != null;
       });
    }
    @Selector() static SelectFavoriteDeals(state: AppStateModel): Deal[] {
        return state.favoriteDeals;
    }
    @Selector()  static SelectUserDeals(state: AppStateModel): UserDeals {
        return state.userDeals;
    }
    @Selector()  static SelectDealsAsMap(state: AppStateModel): Map<string, Deal> {
        return state.deals;
    }
    @Selector() static SelectGlobalAlertTriggers(state: AppStateModel): GlobalAlertTrigger[] {
        return [...state.globalAlertTriggers.values()];
    }
    @Selector() static SelectSearchResults(state: AppStateModel): Deal[] {
        return [...state.deals.values()].filter(deal => {
            const categoryFilter = !state.selectedCategory || state.selectedCategory.name === 'Tout'
                || state.selectedCategory.id === deal.categoryId;
            const searchFilter = state.searchText && deal.title.toLowerCase()
                .includes(state.searchText.toLowerCase());
            return categoryFilter && searchFilter;
        }).sort( (a: Deal, b: Deal) => {
            if (!b.startDate || !a.startDate) {
                return 0;
            }
            return b.startDate.localeCompare(a.startDate);
        });
    }
    @Selector() static SelectDeals(state: AppStateModel): Deal[] {
        return [...state.deals.values()].sort( (a: Deal, b: Deal) => {
            if (!b.startDate || !a.startDate) {
                return 0;
            }
            return b.startDate.localeCompare(a.startDate);
        });
    }
    @Selector() static SelectSelectedCategory(state: AppStateModel): Category {
        return state.selectedCategory;
    }
    @Selector() static SelectNotifToken(state: AppStateModel): string {
        return state.pushSubscriptionToken;
    }
    @Selector() static SelectCountries(state: AppStateModel): Country[] {
        return state.countries;
    }
    @Selector() static SelectCurrentUser(state: AppStateModel): User {
        return state.currentUser;
    }
    @Selector() static SelectSearchText(state: AppStateModel): string {
        return state.searchText;
    }
    @Selector() static SelectAllNotifications(state: AppStateModel): Notification[] {
        return [...state.notifications.values()].sort(this.getCompareFn());
    }
    @Selector() static SelectAllAlerts(state: AppStateModel): Notification[] {
        return [...state.alerts.values()].sort(this.getCompareFn());
    }
    @Selector() static SelectRecentSearch(state: AppStateModel): string[] {
        return state.recentSearches;
    }
    @Selector() static SelectParameters(state: AppStateModel): Parameter {
        return state.parameters;
    }
    private static getCompareFn() {
        return (a, b) => {
            if (!a.read && !b.read) {
                return (b.createdAt || '').localeCompare((a.createdAt || ''));
            }
            if (!a.read) {
                return -1;
            }
            if (!b.read) {
                return 1;
            }
            return (b.createdAt || '').localeCompare((a.createdAt || ''));
        };
    }

    @Selector() static SelectUnreadAlertCount(state: AppStateModel): number {
        return [...state.alerts.values()].filter(n => !n.read).length;
    }
    @Selector() static SelectUnreadNotificationCount(state: AppStateModel): number {
        return [...state.notifications.values()].filter(n => !n.read).length;
    }
    @Selector() static SelectSearchCriteria(state: AppStateModel): SearchCriteria {
        return state.searchCriteria;
    }
    @Selector() static SelectSubscribedDeals(state: AppStateModel): Deal[] {
        return state.subscribedDeals;
    }
    @Action(StoreAuthData)
    storeAuthData(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreAuthData
    ) {
        const state = getState();
        setState({
            ...state,
            authData: payload
        });
    }

    @Action(StoreCategories)
    storeCategories(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreCategories
    ) {
        const state = getState();
        const categories = payload;
        setState({
            ...state,
            categories
        });
    }
    @Action(StoreUserDeals)
    storeUserDeals(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreUserDeals
    ) {
        const state = getState();
        const userDeals = payload;
        setState({
            ...state,
            userDeals
        });
    }
    @Action(StoreSearchCriteria)
    storeSearchCriteria(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreSearchCriteria
    ) {
        const state = getState();
        const searchCriteria = payload;
        setState({
            ...state,
            searchCriteria
        });
    }
    @Action(StoreDeals)
    storeDeals(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreDeals
    ) {
        const state = getState();
        const deals = new Map<string, Deal>();
        payload.forEach(deal => {
            if (!deal.temperature && deal.type !== 'ADS') {
                deal.temperature = 0;
            }
            deals.set(deal.id, deal);
        });
        setState({
            ...state,
            deals
        });
    }

    @Action(StoreFavoriteDeals)
    storeFavoriteDeals(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreFavoriteDeals
    ) {
        const state = getState();
        setState({
            ...state,
            favoriteDeals: payload,
        });
    }

    @Action(StoreDeal)
    storeDeal(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreDeal
    ) {
        const state = getState();
        const deals = new Map<string, Deal>(state.deals);
        deals.set(payload.id, payload);
        setState({
            ...state,
            deals
        });
    }
    @Action(DeleteDeal)
    deleteDeal(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: DeleteDeal
    ) {
        const state = getState();
        const deals = new Map<string, Deal>(state.deals);
        deals.delete(payload);
        setState({
            ...state,
            deals
        });
    }
    @Action(StoreCountries)
    storeCountries(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreCountries
    ) {
        const state = getState();
        setState({
            ...state,
            countries: payload
        });
    }

    @Action(StoreSelectedCategory)
    storeSelectedCategory(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreSelectedCategory
    ) {
        const state = getState();
        setState({
            ...state,
            selectedCategory: payload
        });
    }

    @Action(StoreCurrentDealId)
    storeCurrentDealId(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreCurrentDealId
    ) {
        const state = getState();
        const deals = new Map<string, Deal>(state.deals);
        const deal = deals.get(payload);
        if (deal) {
            deal.viewCount = (deal.viewCount || 0) + 1;
        }
        setState({
            ...state,
            currentDealId: payload,
            deals
        });
    }

    @Action(UpdateSearchText)
    updateSearchText(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: UpdateSearchText
    ) {
        const state = getState();
        const recentSearches = state.recentSearches || [];
        if (payload) {
            // delete previous elements and add this one to the top
            const index = recentSearches.indexOf(payload);
            if (index > -1) {
                recentSearches.splice(index, 1);
            }
            recentSearches.unshift(payload);
            recentSearches.slice(0, 10);
        }
        setState({
            ...state,
            searchText: payload,
            recentSearches
        });
    }

    @Action(StoreNotification)
    storeNotification(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreNotification
    ) {
        const state = getState();
        const notifications = new Map<string, Notification>(state.notifications);
        notifications.set(payload.id, payload);
        setState({
            ...state,
            notifications
        });
    }

    @Action(StoreAlert)
    storeAlert(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreAlert
    ) {
        const state = getState();
        const alerts = new Map<string, Notification>(state.alerts);
        alerts.set(payload.id, payload);
        setState({
            ...state,
            alerts
        });
    }

    @Action(StoreNotifications)
    storeNotifications(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreNotifications
    ) {
        const state = getState();
        const notifications = new Map<string, Notification>();
        payload.forEach(notif => {
            notifications.set(notif.id, notif);
        });
        setState({
            ...state,
            notifications
        });
    }
    @Action(StoreAlerts)
    storeAlerts(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreAlerts
    ) {
        const state = getState();
        const alerts = new Map<string, Notification>();
        payload.forEach(notif => {
            alerts.set(notif.id, notif);
        });
        setState({
            ...state,
            alerts
        });
       // this.badge.increase(1).then((value) => {
          //  console.log('Increased badge: ' + value);
       // });
    }


    @Action(ReadAlert)
    readAlert(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: ReadAlert
    ) {
        const state = getState();
        const alerts = new Map<string, Notification>(state.alerts);
        if (alerts.has(payload)) {
            const alert = alerts.get(payload);
            alert.read = true;
            alerts.set(payload, alert);
        }
        setState({
            ...state,
            alerts
        });
        this.badge.clear();
    }

    @Action(ReadNotification)
    readNotif(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: ReadNotification
    ) {
        const state = getState();
        const notifs = new Map<string, Notification>(state.notifications);
        if (notifs.has(payload)) {
            const notif = notifs.get(payload);
            notif.read = true;
            notifs.set(payload, notif);
        }
        setState({
            ...state,
            notifications: notifs
        });
        this.badge.clear();
    }

    @Action(StoreGlobalAlertTrigger)
    storeGlobalAlertRigger(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreGlobalAlertTrigger
    ) {
        const state = getState();
        const globalAlertTriggers = new Map<string, GlobalAlertTrigger>();
        globalAlertTriggers.set(payload.id, payload);
        setState({
            ...state,
            globalAlertTriggers
        });
    }

    @Action(DeleteGlobalAlertTrigger)
    deleteGlobalAlertRigger(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: DeleteGlobalAlertTrigger
    ) {
        const state = getState();
        const globalAlertTriggers = new Map<string, GlobalAlertTrigger>();
        setState({
            ...state,
            globalAlertTriggers
        });
    }
    @Action(StoreUserProfile)
    storeUserProfile(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreUserProfile
    ) {
        const state = getState();
        const authData = state.authData;
        authData.user = payload;
        setState({
            ...state,
            authData
        });
    }
    @Action(StorePushSubscription)
    storePushNotification(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StorePushSubscription
    ) {
        const state = getState();
        state.pushSubscriptionToken = payload;
        setState({
            ...state,
        });
    }
    @Action(ClearSearchHistory)
    clearSearchHistory(
        { getState, setState }: StateContext<AppStateModel>,
        {  }: ClearSearchHistory
    ) {
        const state = getState();
        state.recentSearches = [];
        setState({
            ...state
        });
    }
    @Action(StoreParameters)
    storeParameters(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreParameters
    ) {
        const state = getState();
        state.parameters = {...state.parameters, ...payload};
        setState({
            ...state
        });
    }
    @Action(StoreAuthTokens)
    storeAuthTokens(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: any
    ) {
        const state = getState();
        const authData = state.authData;
        authData.accessToken = payload.accessToken;
        authData.refreshToken = payload.refreshToken;
        setState({
            ...state,
            authData
        });
    }
    @Action(StoreMySubscribedDeals)
    storeMySubscribedDeals(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreMySubscribedDeals
    ) {
        const state = getState();
        setState({
            ...state,
            subscribedDeals: payload || [],
        });
    }
    @Action(StoreCurrentUser)
    storeCurrentUser(
        { getState, setState }: StateContext<AppStateModel>,
        { payload }: StoreCurrentUser
    ) {
        const state = getState();
        setState({
            ...state,
            currentUser: payload
        });
    }

    @Action(UpdateDeals)
    updateDeals(
        {getState, setState}: StateContext<AppStateModel>,
        {payload}: UpdateDeals
    ) {
        const state = getState();
        const deals = new Map<string, Deal>(state.deals);
        payload.forEach(deal => {
            if (!deal.temperature && deal.type !== 'ADS') {
                deal.temperature = 0;
            }
            deals.set(deal.id, deal);
            setState({
                ...state,
                deals
            });
        });

    }
}
