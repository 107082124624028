import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from './components/login/login.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {AppState} from './store/app.state';
import {deserialize, serialize} from './utils/storage';
import {NgxsModule} from '@ngxs/store';
import {IonicStorageModule} from '@ionic/storage';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {HttpClientModule} from '@angular/common/http';
import {NotifModule} from './components/notifs/notif.module';
import {socialAuth} from '../config.json';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {SearchModule} from './components/search/search.module';
import {SharedModule} from './shared.module';
import {WelcomeComponent} from './components/welcome/welcome.component';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {APP_STATE_KEY} from './utils/utils';
import {Badge} from '@ionic-native/badge/ngx';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {PlaceAutocompleteComponent} from './components/place-autocomplete/place.autocomplete.component';
import {PopupsModule} from './components/popups/popups.module';
import {DealDetailPageModule} from './pages/deals/deal-detail/deal-detail.module';
import { ManUpService } from './manup/manup.service';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {ManUpConfig} from './manup/manup.config';
import {DescriptionModalComponent} from './components/description-modal/description-modal.component';
import {QuillModule} from 'ngx-quill';
import {HandleUnsavedChangeGuardGuard} from './services/handle-unsaved-change-guard.guard';
import {HandleUnsavedEditChangeGuard} from './services/handle-unsaved-change-edit-guard.guard';
import {SignInWithApple} from '@ionic-native/sign-in-with-apple/ngx';
import {AppleLoginProvider} from './components/login/apple-login-provider';
import {IonIntlTelInputModule} from 'ion-intl-tel-input';
import {config, environment as env} from '../config.json';

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        WelcomeComponent,
        PlaceAutocompleteComponent,
        DescriptionModalComponent],
    entryComponents: [LoginComponent],
    imports: [
        NgxsModule.forRoot([AppState]),
        NgxsStoragePluginModule.forRoot({serialize, deserialize, key: APP_STATE_KEY}),
        BrowserModule,
        IonicModule.forRoot({backButtonText: '', mode: 'ios'}),
        AppRoutingModule,
        IonicStorageModule.forRoot({
            name: '__bissap',
            driverOrder: ['sqlite', 'indexeddb', 'websql', 'localstorage'],
        }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        NoopAnimationsModule,
        HttpClientModule,
        NotifModule,
        SocialLoginModule,
        SearchModule,
        SharedModule,
        IonIntlTelInputModule,
        FormsModule,
        PopupsModule,
        DealDetailPageModule,
        ReactiveFormsModule,
        QuillModule,
    ],
    providers: [
        AppVersion,
        Facebook,
        SignInWithApple,
        StatusBar,
        Badge,
        ManUpService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LOCALE_ID, useValue: 'fr-FR'},
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(socialAuth.facebookProviderId),
                    },
                    {
                        id: AppleLoginProvider.PROVIDER_ID,
                        provider: new AppleLoginProvider(socialAuth.appleProviderId, 'name email', 'https://app.bissap.deals'),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        {
            provide: ManUpConfig,
            useValue: {
                url: `${config[env].apiBaseUrl}/api/manup.json`,
                externalTranslations: false
            }
            },
        SocialSharing,
        HandleUnsavedChangeGuardGuard,
        HandleUnsavedEditChangeGuard,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
