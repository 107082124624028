<div class="ion-justify-content-center ion-align-items-center ion-text-center bissap-install">
    <h1>Bienvenue sur Bissap !</h1>
    <div class="image-container">
        <img class="bissap-img" src="assets/img/unboarding-1.png"/>
    </div>
    <p>Profitez de toutes les fonctionnalités de BISSAP en téléchargeant notre application dès maintenant !</p>
    <ion-button (click)="install()" expand="block" class="bissap">
        Télécharger l'appli
        <ion-icon slot="end" name="download-outline"></ion-icon>
    </ion-button>
</div>
