import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MomentModule} from 'ngx-moment';
import {DesignWarningComponent} from './design-warning/design-warning.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {VotedPopupComponent} from './voted-popup/voted-popup.component';
import {PlaceDisplayComponent} from './place-display/place-display.component';
import {AppInstallComponent} from './app-install/app-install.component';

@NgModule({
    declarations: [DesignWarningComponent, TermsAndConditionsComponent, VotedPopupComponent, PlaceDisplayComponent, AppInstallComponent],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        MomentModule,
        ReactiveFormsModule,
    ],
    exports: [DesignWarningComponent, TermsAndConditionsComponent, VotedPopupComponent, PlaceDisplayComponent, AppInstallComponent],
    entryComponents: [DesignWarningComponent, TermsAndConditionsComponent, VotedPopupComponent, PlaceDisplayComponent, AppInstallComponent]
})
export class PopupsModule {
}
