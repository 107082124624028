import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateEditComponent} from './components/deals/create-edit/create-edit.component';
import {ProfilComponent} from './components/profil/profil.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {QuillModule} from 'ngx-quill';
import 'quill-emoji/dist/quill-emoji.js';
import {NgxIonicImageViewerModule} from 'ngx-ionic-image-viewer';
import {IonIntlTelInputModule} from 'ion-intl-tel-input';
import {EditDealHeaderComponent} from './components/deals/edit-deal-header/edit-deal-header.component';
import {DealDetailPageModule} from './pages/deals/deal-detail/deal-detail.module';
import {DealDetailComponent} from './components/deals/deal-detail/deal-detail.component';
import {TechnicalProblemComponent} from './components/common/technical-problem/technical-problem.component';
import {DealExpiredComponent} from './components/common/deal-expired/deal-expired.component';
import {NotFoundComponent} from './components/common/not-found/not-found.component';
import {MomentModule} from 'ngx-moment';
import {ShortNumberPipe} from './components/common/deal-expired/pipes/short-number.pipe';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonIntlTelInputModule,
        NgxIonicImageViewerModule,
        BsDropdownModule.forRoot(),
        QuillModule.forRoot({
            modules: {
                syntax: false,
                toolbar: {
                    container: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        [{list: 'ordered'}, {list: 'bullet'}],
                        [{size: ['small', false, 'large', 'huge']}],  // custom dropdown
                        [{color: []}],          // dropdown with defaults from theme
                        [{align: []}],
                        ['clean'],                                         // remove formatting button
                        ['link', 'video'], // link and image, video
                        ['emoji']
                    ],
                    handlers: {
                        emoji: function() {
                        }
                    }
                },
                'emoji-toolbar': true,
                'emoji-shortname': true,
            },
            theme: 'snow'
        }),
        MomentModule,
    ],
    declarations: [
        CreateEditComponent,
        ProfilComponent,
        EditDealHeaderComponent,
        DealDetailComponent,
        TechnicalProblemComponent,
        DealExpiredComponent,
        NotFoundComponent,
        ShortNumberPipe
    ],
    exports: [
        CreateEditComponent,
        ProfilComponent,
        EditDealHeaderComponent,
        DealDetailComponent,
        TechnicalProblemComponent,
        DealExpiredComponent,
        NotFoundComponent,
        ShortNumberPipe
    ],
    entryComponents: [TechnicalProblemComponent,
        DealExpiredComponent,
        NotFoundComponent]
})

export class SharedModule {
}
