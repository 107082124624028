import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'edit-deal-header',
    templateUrl: './edit-deal-header.component.html',
    styleUrls: ['./edit-deal-header.component.scss'],
})
export class EditDealHeaderComponent {
    @Input() title: string;
    @Input() subtitle: string;
}
