import { MomentModule } from 'ngx-moment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DealDetailPageRoutingModule } from './deal-detail-routing.module';

import { DealDetailPage } from './deal-detail.page';
import {QuillModule} from 'ngx-quill';
import {NgxIonicImageViewerModule} from 'ngx-ionic-image-viewer';
import {SharedModule} from '../../../shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DealDetailPageRoutingModule,
        MomentModule,
        QuillModule,
        NgxIonicImageViewerModule,
        SharedModule
    ],
    declarations: [DealDetailPage],
    exports: [
    ],
    entryComponents: []
})
export class DealDetailPageModule {}
