<ion-list>
  <ion-list-header class="notif-title">
    Sur le deal <span class="bissap-on-deal">{{ getTitleFromDeal() }}</span>
    <ion-button class="dismiss-btn" fill="clear" (click)="dismiss()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
  </ion-list-header>

  <div class="notif-center" *ngIf="loading || error">
    <ion-spinner *ngIf="loading" class="spinner" color="danger"></ion-spinner>
    <p *ngIf="error" class="error-message">
      Une erreur est survenue lors de la récupération des données
    </p>
  </div>
  <div *ngIf="!loading && !error" class="notif-container-main">
    <div class="sub-title">Être notifié quand: </div>
    <ion-item>
      <ion-label>Quand la température du deal est supérieure à</ion-label>
      <ion-input inputmode="numeric" step="1"
                 [formControl]="temperatureForm"
                 name="temperatureMinValue"
                 pattern="^-?[0-9]*$"
      ></ion-input>
      <span class="bissap-toggle">
        <bissap-alert-toggle [(value)]="alertTrigger.temperatureMin.enabled"
                             icon="notifications"></bissap-alert-toggle>
        <!--
        <bissap-alert-toggle [(value)]="alertTrigger.temperatureMin.pushEnabled"
                             icon="call"></bissap-alert-toggle>
         -->
      </span>
    </ion-item>
    <ion-label *ngIf="!temperatureForm.valid" class="error-message">
      <ion-icon color="danger" name="alert-circle"></ion-icon>
      <span>Veuillez entrer une température valide (entre -99999 et 99999)</span>
    </ion-label>
    <!--
      <ion-item>
        <ion-label>Quand la température du deal est inférieure à </ion-label>
        <ion-input type="number" inputmode="number" max="999" step="1"
                   [(ngModel)]="alertTrigger.temperatureMax.value"></ion-input>
          <span class="bissap-toggle">
            <bissap-alert-toggle [(value)]="alertTrigger.temperatureMax.enabled" icon="notifications"></bissap-alert-toggle>
            <bissap-alert-toggle [(value)]="alertTrigger.temperatureMax.pushEnabled" icon="call"></bissap-alert-toggle>
          </span>
      </ion-item>
    -->
    <ion-item>
      <ion-label>Ce deal arrive à expiration</ion-label>
      <span class="bissap-toggle">
        <bissap-alert-toggle [(value)]="alertTrigger.isExpiring.enabled" icon="notifications"></bissap-alert-toggle>
        <!--
        <bissap-alert-toggle [(value)]="alertTrigger.isExpiring.pushEnabled" icon="call"></bissap-alert-toggle>
        -->
      </span>
    </ion-item>
  <ion-item [disabled]="dealBelongsToMe()">
    <ion-label>Quand l'auteur de ce deal poste un autre deal</ion-label>
    <span class="bissap-toggle">
      <bissap-alert-toggle [(value)]="alertTrigger.authorPosted.enabled" icon="notifications"></bissap-alert-toggle>
      <!--
     <bissap-alert-toggle [(value)]="alertTrigger.authorPosted.pushEnabled" icon="call"></bissap-alert-toggle>
      -->
 </span>
 </ion-item>
</div>

</ion-list>

<div *ngIf="!loading && !error">
<ion-button expand="block" class="save" [disabled]="!temperatureForm.valid"
            (click)="saveAlert()">Sauvegarder</ion-button>
<ion-button *ngIf="deal.alertTrigger" expand="block" fill="outline" class="delete" (click)="deleteAlert()">
 Supprimer
</ion-button>

  <!--
<div class="tips">
 <div class="tips-item">
   <ion-icon color="danger" slot="icon-only" name="notifications-sharp"></ion-icon>
   <span>Si vous activez cette icône alors vous recevrez la
notification dans la liste des notifications. Cette liste est accessible
tout en haut de l’écran d’accueil via l’icône cloche présente à
gauche de l’icône recherche</span>
 </div>
 <div class="tips-item">
   <ion-icon color="danger" slot="icon-only" name="call-sharp"></ion-icon>
   <span>Si vous activez cette icône alors vous
recevrez une notification push (visible dans la barre de notification de votre téléphone)</span>
 </div>
</div>
-->
</div>
