import {Injectable} from '@angular/core';
import {ReadAlert, ReadNotification, StoreAlert, StoreNotification} from '../store/app.action';

import {
    Capacitor,
} from '@capacitor/core';
import {
    PushNotifications
} from '@capacitor/push-notifications';

import {Store} from '@ngxs/store';
import {firebaseConfig} from '../../config.json';
import {firebase} from '@firebase/app';
import '@firebase/messaging';
import {SwPush} from '@angular/service-worker';
import {Notification} from '../models/notification';
import {DealDataService} from './deal-data.service';
import {Router} from '@angular/router';
import {Badge} from '@ionic-native/badge/ngx';
import {ActionPerformed, PushNotificationSchema, Token} from '@capacitor/push-notifications/dist/esm/definitions';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private store: Store,
                private push: SwPush,
                private dealDataService: DealDataService,
                private router: Router,
                private badge: Badge) {
    }
    public init() {
        this.badge.clear();
        const isPushNotificationsAvailable = Capacitor.isPluginAvailable(
            'PushNotifications');
        if (isPushNotificationsAvailable) {
            this.configureNotificationsNative();
        } else if (firebase.messaging.isSupported()) {
            this.subscribeToPushPwa();
        } else {
            console.log('Push notification is not available');
        }
        this.push.messages.subscribe((msg: any) => {
            console.log('push message received', msg);
            if (msg.hasOwnProperty('data')) {
                this.badge.set(1).then(() => {
                    console.log('Set badge');
                });
                const notif: Notification = {
                    createdAt: msg.data.createdAt,
                    id: msg.data.id,
                    message: msg.data.message,
                    read: false,
                    url: msg.data.url
                };
                if (msg.data.type === 'alert') {
                    this.store.dispatch(new StoreAlert(notif));
                } else if (msg.data.type === 'notification') {
                    this.store.dispatch(new StoreNotification(notif));
                } else {
                    console.log('sync data message');
                }
            }
        });
        this.push.notificationClicks.subscribe(click => {
            console.log('notification click', click);
            if (click.notification && click.notification.data && click.notification.data.url) {
                this.readNotification(click.notification.data);
            }
        });
    }
    private storeNotificationToken(token) {
        this.dealDataService.storeNotificationToken(token);
    }
    private configureNotificationsNative() {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result => {
            if (result.receive) {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                console.error('Permission for push notification is not granted');
            }
        });

        PushNotifications.addListener('registration',
            (token: Token) => {
                console.log('Push registration success, token: ' + token.value);
                this.storeNotificationToken(token.value);
            }
        );

        PushNotifications.addListener('registrationError',
            (error: any) => {
                console.error('Error on registration: ' + JSON.stringify(error));
            }
        );

        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                this.badge.set(1).then(() => {
                    console.log('Set badge');
                });
                console.log('Push received: ' + JSON.stringify(notification));
            }
        );

        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                console.log('Push action performed: ' + JSON.stringify(notification));
                if (notification.notification && notification.notification.data && notification.notification.data.url) {
                    this.readNotification(notification.notification.data);
                }
            }
        );
    }

    private readNotification(data) {
        if (data.type === 'alert') {
            this.dealDataService.updateNotifStatus(true);
            this.store.dispatch(new ReadAlert(data.id));
        } else if (data.type === 'notification') {
            this.dealDataService.updateNotifStatus(false);
            this.store.dispatch(new ReadNotification(data.id));
        }
        this.badge.clear().then(() => {
            console.log('Badge cleaned');
        });
        console.log('Navigation to notif deal page : ' + data.url);
        this.router.navigate([data.url]);
    }

    private subscribeToPushPwa() {
        firebase.initializeApp(firebaseConfig);
        navigator.serviceWorker.ready.then((registration) => {
            const messaging = firebase.messaging();
            // Register the Service Worker
            messaging.useServiceWorker(registration);

            // Initialize your VAPI key
            messaging.usePublicVapidKey(
                firebaseConfig.vapidKey
            );
            messaging.getToken().then(token => {
                console.log('Push registration success, token: ' + token);
                this.storeNotificationToken(token);
            });
            // Optional and not covered in the article
            // Handle token refresh
            messaging.onTokenRefresh(() => {
                messaging.getToken().then(
                    (refreshedToken: string) => {
                        console.log(refreshedToken);
                        this.storeNotificationToken(refreshedToken);
                    }).catch((err) => {
                    console.error(err);
                });
            });
        });
    }
}

