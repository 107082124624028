<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons>
      <ion-back-button [text]="" defaultHref="/"></ion-back-button>
      <ion-title>Détails du deal</ion-title>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" >
    <deal-detail [deal]="deal" *ngIf="deal"></deal-detail>
    <div *ngIf="!loading && !deal && !hasError">
        <app-not-found message = "Désolé, aucun résultat n'a été trouvé!"></app-not-found>
    </div>
    <div *ngIf="hasError">
        <app-technical-problem></app-technical-problem>
    </div>
</ion-content>
