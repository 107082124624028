import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {getErrorMessages} from '../../utils/utils';

@Component({
    selector: 'app-description-modal',
    templateUrl: './description-modal.component.html',
    styleUrls: ['./description-modal.component.scss'],
})
export class DescriptionModalComponent implements OnInit {
    @Input() formGroup: FormGroup;
    @Input() createDealGroup: FormGroup;

    constructor(private viewCtrl: ModalController) {
    }

    ngOnInit() {
        this.formGroup = new FormGroup({
            description: new FormControl(this.createDealGroup.controls.description.value,
                this.createDealGroup.controls.description.validator)
        });
        this.formGroup.controls.description.markAsTouched();
    }

    async cancel() {
        await this.viewCtrl.dismiss();
    }

    async validate() {
        await this.viewCtrl.dismiss(this.formGroup.controls.description.value);
    }

    getErrorMessages(name) {
        return getErrorMessages(name, this.formGroup);
    }

    get descriptionLength() {
        return this.formGroup.get('description').value?.length || 0;
    }

    get isDescriptionOk() {
        return this.descriptionLength >= 100 && this.descriptionLength <= 1000;
    }
}
