import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ActionsService} from '../../../services/actions.service';
import {Deal} from '../../../models/deal';

@Component({
    selector: 'bissap-place-display',
    templateUrl: './place-display.component.html',
    styleUrls: ['./place-display.component.scss'],
})
export class PlaceDisplayComponent implements OnInit {
    @Input() deal: Deal;

    constructor(private modalController: ModalController,
                private actionsService: ActionsService) {
    }

    ngOnInit() {
    }

    dismiss() {
        this.modalController.dismiss();
    }
}
