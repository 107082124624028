<div class="ion-justify-content-center ion-align-items-center ion-text-center">
    <div class="image-container">
        <ion-button class="dismiss-btn" fill="clear" (click)="dismiss()">
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        <br>
        <h3>Localisation du deal</h3>
    </div>
    <p>{{deal?.location}}</p>
</div>
