<div class="voted-popup" >
  <div class="ion-justify-content-center ion-align-items-center ion-text-justify">
    <div class="image-container ion-text-center">
      <ion-img class="bissap-img" [src]="votedImage"></ion-img>
    </div>
    <div class="text-term ion-text-center">
      <h1 class="voted-title">{{ votedTitle }}</h1>
      <p class="voted-message"> {{ votedMessage }}</p>
    </div>

  </div>
  <br>
  <div class="footer">
    <ion-item class="ion-text-center">
      <ion-label><span class="text-bissap">Ne plus me le rappeler</span> </ion-label>
      <ion-checkbox
              name="pickup" (ionChange)="hideForever()"></ion-checkbox>
    </ion-item>
    <ion-button (click)="dismiss()"  class="bissap-action">
      <span class="span-via-google"> {{ dismissText }}</span>
    </ion-button>
  </div>
</div>
