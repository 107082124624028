<div class="ion-justify-content-center ion-align-items-center ion-text-center login-bissap">
    <div class="image-container">

        <div class="menu-header">
            <ion-button class="dismiss-btn" fill="clear" (click)="dismiss()">
                <ion-icon name="close-outline"></ion-icon>
            </ion-button>
            <img src="assets/img/bissap-logo-full.svg"/>
        </div>

        <h1>Rejoignez la communauté Bissap !</h1>
        <h2>Faites des économies en profitant des meilleurs deals d'Afrique</h2>
    </div>
    <div class="space"></div>
    <ion-spinner *ngIf="authService.loading" color="danger"></ion-spinner>
    <ion-button class="connect-with-facebook" fill="outline" expand="block" (click)="signInWithFB()">
        <ion-img src="assets/img/facebook.png"></ion-img>
        <span>Connexion avec Facebook</span>
    </ion-button>
    <ion-button class="connect-with-google" fill="outline" expand="block" (click)="signInWithGoogle()">
        <ion-img src="assets/img/google.png"></ion-img>
        <span class="span-via-google">Connexion avec Google</span>
    </ion-button>
<ion-button class="connect-with-apple" fill="outline"
               expand="block" (click)="signInWithApple()">
       <ion-img src="assets/img/apple.png"></ion-img>
        <span class="span-via-apple">Connexion avec Apple</span>
    </ion-button>

</div>
