<ion-header>
    <ion-toolbar>
        <ion-title>Saisir {{isCitySearch ? 'le nom de la ville' : "le nom de l'etablissement"}}</ion-title></ion-toolbar>
</ion-header>

<ion-content>
    <div  id="map-canvas"></div>
    <ion-searchbar id="mainSearchbar" #mainSearchbar [placeholder]=" isCitySearch ? 'Rechercher une ville' : 'Rechercher un etablissement'"
                   [(ngModel)]="autocomplete.query" [showCancelButton]="true"
                   cancelButtonText="Annuler"
                   (ionInput)="updateSearch()" (ionCancel)="dismiss()"></ion-searchbar>
    <ion-list>
        <ion-item *ngFor="let item of autocompleteItems" tappable   (click)="chooseItem(item)">
            {{ item.description }}
        </ion-item>
    </ion-list>
</ion-content>
