import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deal-expired',
  templateUrl: './deal-expired.component.html',
  styleUrls: ['./deal-expired.component.scss'],
})
export class DealExpiredComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
