import {
    AfterContentInit,
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {DealDataService} from '../../../services/deal-data.service';
import {Router} from '@angular/router';
import {
    ActionSheetController,
    AlertController,
    IonSlides,
    ModalController,
    NavController,
    PickerController,
    Platform,
    PopoverController,
    ToastController
} from '@ionic/angular';
import {Store} from '@ngxs/store';
import {CreateDealForm} from '../../../models/form';
import * as moment from 'moment';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DealFormValidator} from '../../../utils/validators';
import {Deal, DealSourceType, DiscountType} from '../../../models/deal';
import {DomSanitizer} from '@angular/platform-browser';
import {
    CURRENCIES,
    getErrorMessages,
    onlineStoreStepsFormFields,
    parseTelephoneNumberToIonIntlTelValue,
    physicalStoreStepsFormFields
} from '../../../utils/utils';
import {DescriptionModalComponent} from '../../description-modal/description-modal.component';
import {PlaceAutocompleteComponent} from '../../place-autocomplete/place.autocomplete.component';
import {Place, PlaceType} from '../../../models/place';


const PRICE_PATTERN = '(\d)+';

@Component({
    selector: 'app-create-edit',
    templateUrl: './create-edit.component.html',
    styleUrls: ['./create-edit.component.scss'],
})
export class CreateEditComponent implements OnInit, AfterContentInit, OnDestroy, AfterViewInit {
    decimalRegexPattern = '^\\d{1,10}([\\.\\,]\\d{1,2})?$';
    percentRegexPattern = '^\\d{1,3}([\\.\\,]\\d{1,2})?$';
    discountOfferBuyPattern = '^\\d{1,3}';
    preferredCountries;
    selectedCountry = '';
    categories = [];
    yearValues = '';
    monthShortNames = ['Jan.', 'Fev.', 'Mar.', 'Avr.', 'Mai.', 'Jun.',
        'Jui.', 'Aou.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
    customPickerOptions: any = {};
    @ViewChild('slides', {read: IonSlides}) slides: IonSlides;
    discountTypes: any[] = [
        {label: 'Réduction de prix', value: DiscountType.PRICE_ABSOLUTE},
        {label: 'Réduction en %', value: DiscountType.PRICE_PERCENT},
        {label: '1 acheté, 1 offert', value: DiscountType.OFFER},
        {label: 'Prix', value: DiscountType.PRICE},
        {label: 'Gratuit', value: DiscountType.FREE}
    ];
    dealSourceTypes = [{label: 'En ligne', value: DealSourceType.ONLINE, icon: '/assets/img/picto/enligne-picto.png'},
        {label: 'Dans une boutique', value: DealSourceType.PHYSICAL, icon: '/assets/img/picto/shop-picto-2.png'}];
    onlineStoreTypes = [{label: 'Playstore', value: 'Playstore', icon: 'logo-google-playstore'},
        {label: 'Apple Store', value: 'Apple Store', icon: 'logo-apple-appstore'},
        {label: 'Internet Store', value: 'Internet Store', icon: 'globe-outline'}];
    dealLocationDetails: Place;

    constructor(public dataService: DealDataService,
                private router: Router,
                private toast: ToastController,
                private alertController: AlertController,
                private store: Store,
                private navCtrl: NavController,
                private platform: Platform,
                private sanitizer: DomSanitizer,
                private actionSheetController: ActionSheetController,
                private popoverCtrl: PopoverController,
                private modalCtrl: ModalController,
                private pickerCtrl: PickerController,
                private cd: ChangeDetectorRef) {
    }

    @ViewChild('filePicker', {static: false}) filePickerRef: ElementRef<HTMLInputElement>;
    isDesktop: boolean;
    @Input() form: CreateDealForm;
    @Output() onFormSubmitted = new EventEmitter<boolean>();
    formErrors: any;
    currencies = CURRENCIES;
    pricePattern = PRICE_PATTERN;
    createDealGroup: FormGroup = null;
    loading = false;
    serverError = false;
    businessMessage = null;
    errorMessage = null;
    didInit = false;
    displayFormat = `Le DD MMMM YYYY à HH:mm`;
    today = moment().add(1, 'h').toISOString();
    allEvents = [
        'Aucun',
        'Noël',
        'Saint-Valentin',
        'Pâques',
        'Fête des pères',
        'Fête des mères',
        `Vacances d'été`
    ];

    slidesOpts = {
        initialSlide: 0,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            type: 'custom',
            clickable: true,
            renderCustom: (swiper, current, total) => {
                return `<div class="add-deal-steps-index" >
                        <span class="slide-current-index" style="font-size: xx-large ;color: var(--ion-color-bissap-red)">
                               ${current}</span>
                         <span class="slide-total-items" style="font-size: small;color: var(--ion-color-bissap-red) ">
                         /${total}</span>
                    </div>`;
            },
        }
    };

    ionViewWillLeave() {
        this.today = new Date().toISOString();
    }

    ngAfterViewInit() {
        this.didInit = true;
    }

    ngAfterContentInit(): void {
        this.today = moment().add(1, 'h').toISOString();
        const year = new Date().getFullYear();
        this.yearValues = `${year},${year + 1}`;
        this.createDealGroup = new FormGroup({
            phone: new FormControl(parseTelephoneNumberToIonIntlTelValue(this.form.phone),
                [DealFormValidator.validPhone]),
            country: new FormControl(this.form.country,
                [Validators.required]),
            city: new FormControl(this.form.city, [Validators.maxLength(100)]),
            title: new FormControl(this.form.title,
                [Validators.required,
                    Validators.maxLength(100), Validators.minLength(10)]),
            description: new FormControl(this.form.description,
                [Validators.required, Validators.minLength(100), Validators.maxLength(1000)]),
            professional: new FormControl(this.form.professional,
                [Validators.required, Validators.maxLength(100)]),
            startDate: new FormControl(this.form.startDate,
                [(fc: FormControl) => DealFormValidator.validStartDate(fc, this.minStartDate())]),
            endDate: new FormControl(this.form.endDate,
                [(fc: FormControl) => DealFormValidator.validEndDate(fc)]),
            priceValue: new FormControl(this.form.price.value),
            priceUnit: new FormControl(this.form.price.unit, [Validators.required]),
            usualPriceValue: new FormControl(this.form.usualPrice?.value, [
                Validators.max(9999999999), Validators.pattern(this.decimalRegexPattern), Validators.maxLength(10),
                (fc: FormControl) => DealFormValidator.validUsualPrice(fc)]),
            usualPriceUnit: new FormControl(this.form.usualPrice?.unit,
                [(fc) => DealFormValidator.validUnit(this.form?.usualPrice.value)]),
            pickup: new FormControl(this.form.pickup, []),
            location: new FormControl(this.form.location, [
                Validators.maxLength(100)
            ]),
            shipping: new FormControl(this.form.shipping, []),
            shippingFeesValue: new FormControl(this.form.shippingFees?.value, [
                Validators.max(9999999999), Validators.pattern(this.decimalRegexPattern), Validators.maxLength(10)]),
            shippingFeesUnit: new FormControl(this.form.shippingFees?.unit,
                [(fc) => DealFormValidator.validUnit(this.form?.shippingFees.value)]),
            whatsappNo: new FormControl(parseTelephoneNumberToIonIntlTelValue(this.form.whatsappNo), [DealFormValidator.validPhone]),
            categoryId: new FormControl(this.form.categoryId, [Validators.required]),
            externalDealLink: new FormControl(this.form.externalDealLink, [DealFormValidator.url]),
            facebookPage: new FormControl(this.form.facebookPage, [
                DealFormValidator.url, Validators.pattern('.*facebook.*')]),
            instagramPage: new FormControl(this.form.instagramPage, [DealFormValidator.url,
                Validators.pattern('.*instagram.*')]),
            image: new FormControl(this.form.image),
            event: new FormControl(this.form.event),
            discountType: new FormControl(this.form.discountType, [Validators.required]),
            discount: new FormControl(this.form.discount),
            discountOffer: new FormControl(this.form.discountOffer),
            discountBuy: new FormControl(this.form.discountBuy),
            dealSourceType: new FormControl(this.form.dealSourceType, [Validators.required])
        });
        if (this.isEdit()) {
            this.createDealGroup.controls.country.disable();
        }
        this.initCountryCode(this.form.country);
        this.updatePromo();
        this.updateDealSourceType(this.form.dealSourceType, false);
    }

    ngOnInit() {

        this.preferredCountries = this.dataService.findBissapCountries();
        this.customPickerOptions = {
            buttons: [{
                text: 'Annuler',
                role: 'cancel',
                handler: () => console.log('Clicked Save!')
            }, {
                text: 'Effacer',
                handler: () => {
                    this.createDealGroup.controls.endDate.setValue(null);
                }
            },
                {
                    text: 'Valider',
                    handler: (data) => {
                        const year: string = data.year.text;
                        const month: string = data.month.value < 10 ? '0' + data.month.value.toString() : data.month.value.toString();
                        const day: string = data.day.text;
                        const hour: string = data.hour.text;
                        const minute: string = data.minute.text;
                        const value = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':00Z';
                        this.createDealGroup.controls.endDate.setValue(value);
                    }
                }
            ]
        };

        this.dataService.getCategories().subscribe(categories => {
            this.categories = categories.filter(cat => cat.name !== 'Tout');
        });
    }

    clearImage() {
        this.createDealGroup.controls.image.setValue('');
    }

    async submitForm() {
        this.loading = true;
        this.serverError = false;
        this.businessMessage = null;
        if (this.createDealGroup.valid) {
            const deal = this.buildDeal();
            this.onFormSubmitted.emit(true);
            this.dataService.saveDeal(deal).subscribe((createdDeal: Deal) => {
                this.loading = false;
                this.serverError = false;
                if (deal.id) {
                    this.businessMessage = `Bravo! Le deal a été bien modifié. Une fois validé par l’équipe, il sera mis en ligne.`;
                } else {
                    this.businessMessage = `Bravo! Le deal a été bien créé. Une fois validé par l’équipe, il sera mis en ligne.`;
                }
                deal.status = createdDeal.status;
                deal.endDate = createdDeal.endDate;
            }, error => {
                this.loading = false;
                this.serverError = true;
            });
        } else {
            this.createDealGroup.markAllAsTouched();
            const ts = await this.toast.create({
                message: `Oups! Le formulaire contient des erreurs, veuillez corriger les erreurs afin de pouvoir créer le deal`,
                duration: 5000,
                position: 'bottom',
                color: 'warning'
            });
            await ts.present();
        }

    }

    buildDeal() {
        const values = this.createDealGroup.getRawValue();
        const deal: Deal = {
            categoryId: values.categoryId,
            city: values.city,
            country: values.country,
            createdAt: moment().toISOString(),
            description: values.description,
            endDate: values.endDate,
            externalDealLink: values.externalDealLink,
            facebookPage: values.facebookPage,
            id: this.form.id,
            image: values.image,
            instagramPage: values.instagramPage,
            pickup: values.pickup,
            shipping: values.shipping,
            stared: values.isStared,
            whatsappNo: values.whatsappNo ? values.whatsappNo.internationalNumber : '',
            location: values.location,
            phone: values.phone ? values.phone.internationalNumber : '',
            price: {
                value: values.priceValue ? parseFloat(values.priceValue) : values.priceValue,
                unit: values.priceUnit
            },
            professional: values.professional,
            shippingFees: {
                value: values.shippingFeesValue ? parseFloat(values.shippingFeesValue) : values.shippingFeesValue,
                unit: values.shippingFeesUnit
            },
            startDate: values.startDate,
            title: values.title,
            type: this.form.type,
            usualPrice: {
                value: values.usualPriceValue ? parseFloat(values.usualPriceValue) : values.usualPriceValue,
                unit: values.usualPriceUnit
            },
            user: this.form.user,
            event: values.event,
            discountType: values.discountType ? values.discountType : '',
            discount: values.discount ? parseFloat(values.discount) : values.discount,
            discountBuy: values.discountBuy ? parseFloat(values.discountBuy) : values.discountBuy,
            discountOffer: values.discountOffer ? parseFloat(values.discountOffer) : values.discountOffer,
            dealSourceType: values.dealSourceType,
            locationDetails: this.dealLocationDetails
        };
        return deal;
    }

    private async pickImage(source) {
        const capturedPhoto = await Camera.getPhoto({
            resultType: CameraResultType.DataUrl,
            source,
            quality: 100,
            allowEditing: false,
            promptLabelCancel: 'Annuler',
            promptLabelPhoto: 'Prendre une photo',
            promptLabelPicture: 'Charger une photo depuis mon appareil',
        });
        this.createDealGroup.controls.image.setValue(capturedPhoto.dataUrl);
    }

    async showPickImagePrompt() {
        if (!this.platform.is('capacitor')) {
            await this.pickImage(CameraSource.Prompt);
            return;
        }
        const actionSheet = await this.actionSheetController.create({
            header: 'Image du deal',
            cssClass: 'create-deal-options',
            buttons: [{
                text: 'Prendre une photo',
                icon: 'camera',
                handler: () => {
                    this.pickImage(CameraSource.Camera);
                }
            }, {
                text: 'Charger une photo depuis mon appareil',
                icon: 'images',
                handler: () => {
                    this.pickImage(CameraSource.Photos);
                }
            }, {
                text: 'Annuler',
                icon: 'close',
                role: 'cancel',
                handler: () => {
                    // annulation
                }
            }]
        });
        await actionSheet.present();
    }

    getErrorMessages(name) {
        return getErrorMessages(name, this.createDealGroup);
    }

    defaultCountryCode(country: any) {
        if (!this.createDealGroup.controls.phone.value) {
            this.selectedCountry = country.detail.value?.toLowerCase() || '';
        }
    }

    initCountryCode(country: string) {
        if (!this.createDealGroup.controls.phone.value) {
            this.selectedCountry = country?.toLowerCase() || '';
        }
    }

    back() {
        this.navCtrl.back();
    }

    updateUsualPriceUnit(event) {
        this.createDealGroup.controls.usualPriceUnit.setValue(this.createDealGroup.controls.priceUnit.value);
    }

    get startDate() {
        return this.createDealGroup ? this.createDealGroup.value.startDate : this.form.endDate;
    }

    async showAddressModal(onlyCity: boolean) {
        const modal = await this.modalCtrl.create({
            component: PlaceAutocompleteComponent,
            componentProps: {
                country: this.createDealGroup.controls.country.value,
                searchType: onlyCity ? PlaceType.CITY : PlaceType.ESTABLISHMENT,
            }
        });
        await modal.present();
        modal.onDidDismiss().then(
            (place) => {
                if (place && onlyCity) {
                    this.createDealGroup.controls.city.setValue(place.data?.name);
                    if (!this.dealLocationDetails) {
                        this.dealLocationDetails = place.data;
                    }
                } else if (place) {
                    this.createDealGroup.controls.city.setValue(place.data?.city);
                    this.createDealGroup.controls.professional.setValue(place.data?.name);
                    this.createDealGroup.controls.phone.setValue(parseTelephoneNumberToIonIntlTelValue(place.data?.phoneNumber));
                    this.createDealGroup.controls.location.setValue(place.data?.description);
                    this.dealLocationDetails = place.data;
                }
                this.cd.detectChanges();
            }
        );
    }

    get descriptionOrPlaceholder() {
        return this.createDealGroup.controls.description.value ?
            this.createDealGroup.controls.description.value :
            'Saisir une description ...';
    }

    async showDescriptionModal() {
        const modal = await this.modalCtrl.create({
            component: DescriptionModalComponent,
            componentProps: {createDealGroup: this.createDealGroup}
        });
        await modal.present();
        modal.onDidDismiss().then(
            (description) => {
                if (description && description.data) {
                    this.createDealGroup.controls.description.setValue(description.data);
                }
                this.createDealGroup.controls.description.markAsTouched();
            }
        );
    }

    ngOnDestroy(): void {
        this.popoverCtrl.dismiss();
        this.modalCtrl.dismiss();
        this.pickerCtrl.dismiss();
    }

    isEdit() {
        return !!this.form.id;
    }

    updateValidation(formControlName: string, isRequired: boolean) {
        if (isRequired) {
            this.createDealGroup.get(formControlName).setValidators(Validators.required);
        } else {
            this.createDealGroup.get(formControlName).setValidators(null);
        }
        this.createDealGroup.get(formControlName).updateValueAndValidity();
    }

    updateValidations(formControlName: string, validators: ValidatorFn[]) {
        if (validators) {
            this.createDealGroup.get(formControlName).setValidators(validators);
        } else {
            this.createDealGroup.get(formControlName).setValidators(null);
        }
        this.createDealGroup.get(formControlName).updateValueAndValidity();
    }

    minStartDate() {
        return this.form.startDate ? moment(this.form.startDate).toISOString() : this.today;
    }

    checkEndate($event) {
        this.createDealGroup.controls.endDate.updateValueAndValidity();
    }

    checkUsualPrice($event) {
        this.createDealGroup.controls.usualPriceValue.updateValueAndValidity();
    }

    get isPriceAbsolute() {
        return this.createDealGroup.value.discountType === DiscountType.PRICE_ABSOLUTE;
    }

    get isPricePercent() {
        return this.createDealGroup.value.discountType === DiscountType.PRICE_PERCENT;
    }

    get isOffer() {
        return this.createDealGroup.value.discountType === DiscountType.OFFER;
    }

    get isPrice() {
        return this.createDealGroup.value.discountType === DiscountType.PRICE;
    }

    get isPhysicalStore() {
        return this.createDealGroup.value.dealSourceType === DealSourceType.PHYSICAL;
    }

    get isOnlineStore() {
        return this.createDealGroup.value.dealSourceType === DealSourceType.ONLINE;
    }

    get location() {
        return this.createDealGroup.value.location;
    }

    updatePromo() {
        if (this.isPriceAbsolute || this.isPrice) {
            this.updateValidations('priceValue', [Validators.required,
                Validators.min(0.01),
                Validators.max(9999999999), Validators.pattern(this.decimalRegexPattern)]);
            this.updateValidations('discount', null);
            this.updateValidations('discountBuy', null);
            this.updateValidations('discountOffer', null);
        } else if (this.isPricePercent) {
            this.updateValidations('priceValue', null);
            this.updateValidations('discount', [Validators.required,
                Validators.min(1),
                Validators.max(100), Validators.pattern(this.percentRegexPattern)]);
            this.updateValidations('discountBuy', null);
            this.updateValidations('discountOffer', null);
        } else if (this.isOffer) {
            this.updateValidations('priceValue', null);
            this.updateValidations('discount', null);
            this.updateValidations('discountBuy', [Validators.required,
                Validators.min(1),
                Validators.max(100), Validators.pattern(this.discountOfferBuyPattern)]);
            this.updateValidations('discountOffer', [Validators.required,
                Validators.min(1),
                Validators.max(100), Validators.pattern(this.discountOfferBuyPattern)]);
        } else {
            this.updateValidations('priceValue', null);
            this.updateValidations('discount', null);
            this.updateValidations('discountBuy', null);
            this.updateValidations('discountOffer', null);
        }
    }

    updateDealLocation(value = '') {
        this.createDealGroup.controls.location.setValue(value);
        if (value === '') {
            this.dealLocationDetails = null;
        }

    }

    async continue() {
        const activeStep = await this.slides.getActiveIndex();
        this.stepsFormFields[activeStep].forEach(field => {
            this.createDealGroup.get(field).markAsTouched();
        });
        const isValid = !this.stepsFormFields[activeStep]
            .some(field => this.createDealGroup.get(field).invalid);
        if (isValid) {
            await this.slides.slideNext();
        }
        if (activeStep === 0) {
            this.updateDealSourceType(this.createDealGroup.value.dealSourceType, false);
        }
    }

    async showPreview() {
        await this.slides.slideNext();
    }

    async previous() {
        await this.slides.slidePrev();
    }

    get isStepOneValid() {
        return !this.stepsFormFields[0]
            .some(field => this.createDealGroup.get(field).invalid);
    }

    get isStepTwoValid() {
        const index = this.isOnlineStore ? 1 : 2;
        return this.isStepOneValid &&
            !this.stepsFormFields[index]
                .some(field => this.createDealGroup.get(field).invalid);
    }

    get isStepThreeValid() {
        const index = this.isOnlineStore ? 2 : 3;
        return this.isStepTwoValid &&
            !this.stepsFormFields[index]
                .some(field => this.createDealGroup.get(field).invalid);
    }

    get isStepFourValid() {
        const index = this.isOnlineStore ? 3 : 4;
        return this.isStepThreeValid &&
            !this.stepsFormFields[index]
                .some(field => this.createDealGroup.get(field).invalid);
    }

    updateDealSourceType(value: DealSourceType.ONLINE | DealSourceType.PHYSICAL, updateLocation: boolean) {
        this.createDealGroup.controls.dealSourceType.setValue(value);
        const required: boolean = this.isPhysicalStore;
        if (required) {
            this.updateValidations('city', [Validators.required, Validators.maxLength(100)]);
        } else {
            this.updateValidations('city', []);
            this.updateValidations('phone', []);
            this.updateValidations('whatsappNo', []);
        }
        this.updateValidation('country', required);
        if (this.isOnlineStore) {
            this.updateValidations('externalDealLink', [Validators.required, DealFormValidator.url]);
        } else {
            this.updateValidations('externalDealLink', []);
        }
        if (updateLocation) {
            this.updateDealLocation('');
        }
    }

    get isPickup() {
        return this.createDealGroup?.controls?.pickup?.value;
    }

    get isShipping() {
        return this.createDealGroup?.controls?.shipping?.value;
    }

    togglePickup() {
        this.createDealGroup.controls.pickup.setValue(!this.createDealGroup.controls.pickup.value);
    }

    toggleShipping() {
        this.createDealGroup.controls.shipping.setValue(!this.createDealGroup.controls.shipping.value);
    }

    isCheckedDealSource(value: DealSourceType.ONLINE | DealSourceType.PHYSICAL) {
        return this.createDealGroup.controls.dealSourceType.value === value;
    }

    get stepsFormFields() {
        return this.isOnlineStore ? onlineStoreStepsFormFields : physicalStoreStepsFormFields;
    }

}
