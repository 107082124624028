<div class="slide-header">
    <ion-img src="/assets/img/demi-lune.png"></ion-img>
    <div class="add-deal-steps-indics">
        <ion-img class="add-deal-steps" src="/assets/img/add-deal-steps.png"></ion-img>
    </div>
    <div class="step-title">
       {{title}}
    </div>
    <div class="step-subtitle" *ngIf="subtitle">
        {{subtitle}}
    </div>
</div>
