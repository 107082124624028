import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../models/deal';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {Country} from '../../models/country';
import {DealDataService} from '../../services/deal-data.service';
import {ActionSheetController, AlertController, Platform, PopoverController, ToastController} from '@ionic/angular';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DealFormValidator} from '../../utils/validators';
import {Store} from '@ngxs/store';
import {AppState} from '../../store/app.state';
import {parseTelephoneNumberToIonIntlTelValue} from '../../utils/utils';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss'],
})
export class ProfilComponent implements OnInit {
  @Input()
  user: User;
  @Input()
  fromLogin: boolean;
  @Input()
  hideHeader: boolean;
  countries: Country[] = [];
  title: string;
  selectedCountry: string;

  preferredCountries: string[];
  formGroup = new FormGroup({
    phone: new FormControl(undefined, [
      DealFormValidator.validPhone]),
    country: new FormControl(undefined, [Validators.required]),
    name: new FormControl(undefined, [Validators.required, Validators.maxLength(20)]),
  });
  validationMessages = {
    phone: [
      { type: 'required', message: 'Veuillez renseigner votre numéro de téléphone' },
      { type: 'valid', message: 'Veuillez renseigner un numéro valide' }
    ],
    country: [
      { type: 'required', message: 'Veuillez renseigner votre pays' }
    ],
    name: [
      { type: 'required', message: 'Veuillez renseigner votre pseudo' },
      { type: 'maxlength', message: 'Le pseudo ne doit pas contenir plus de 20 caractères' }
    ],
  };
  constructor(private dataService: DealDataService,
              private alertController: AlertController,
              private authService: AuthService,
              private popoverCtrl: PopoverController,
              private router: Router,
              private toastCtrl: ToastController,
              private platform: Platform,
              private actionSheetController: ActionSheetController,
              private store: Store) {
    this.preferredCountries = this.dataService.findBissapCountries();
  }

  ngOnInit() {
    if (this.user && this.user.country) {
      this.formGroup.controls.country.setValue(this.user.country);
      this.selectedCountry = this.user.country.toLowerCase();
    }
    this.title = this.fromLogin ? 'Compléter votre profil' : 'Modifier votre profil';
    this.formGroup.controls.name.setValue(this.user?.name);
    this.formGroup.controls.phone.setValue(parseTelephoneNumberToIonIntlTelValue(this.user?.phoneNumber));
    this.dataService.getCountries().subscribe(countries => {
      this.countries = countries || [];
      if (this.user && this.countries.length > 0 && !this.user.country) {
        this.selectedCountry = this.store.selectSnapshot(AppState.SelectParameters).country?.toLowerCase();
        this.formGroup.controls.country.setValue(this.selectedCountry);
      }
    });

  }

  getProfilePic() {
    return this.user.avatar || '/assets/img/user-profilpic.png';
  }
  private async pickImage(source) {
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source,
      quality: 100,
      allowEditing: false
    });
    this.user.avatar = capturedPhoto.dataUrl;
  }
  async showPickImagePrompt() {
    if (!this.platform.is('capacitor')) {
      await this.pickImage(CameraSource.Prompt);
      return;
    }
    const actionSheet = await this.actionSheetController.create({
      header: 'Image du profil',
      cssClass: 'create-deal-options',
      buttons: [ {
        text: 'Prendre une photo',
        icon: 'camera',
        handler: () => {
          this.pickImage(CameraSource.Camera);
        }
      }, {
        text: 'Charger une photo depuis mon appareil',
        icon: 'images',
        handler: () => {
          this.pickImage(CameraSource.Photos);
        }
      }, {
        text: 'Annuler',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          // annulation
        }
      }]
    });
    await actionSheet.present();
  }

  async onBackBtn() {
    if (this.fromLogin) {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Profil Bissap',
        message: 'Voulez-vous annuler la connexion?',
        buttons: [
          {
            text: 'Non',
            role: 'cancel'
          }, {
            text: 'Oui',
            handler: () => {
              this.authService.logout(false);
              this.popoverCtrl.dismiss();
            }
          }
        ]
      });
      await alert.present();
    } else {
      await this.popoverCtrl.dismiss();
    }
  }
  deleteImage() {
    this.user.avatar = null;
  }

  dismiss() {
    this.onBackBtn();
  }

  save = () => {
    if (!this.validate()) {
      return;
    }
    const values = this.formGroup.getRawValue();
    this.user.phoneNumber = values?.phone?.internationalNumber;
    this.user.country = values.country;
    this.user.name = values.name;
    this.dataService.updateProfile(this.user).subscribe(
        (u) => {
          this.user = u;
          this.popoverCtrl.dismiss();
          this.user.avatarVersion = this.user.avatarVersion ? this.user.avatarVersion + 1 : 1;
          const message = this.fromLogin ? 'Bienvenue dans la communauté Bissap!'
              : 'Votre profil a été mis à jour';
          this.toastCtrl.create({
            message,
            duration: 3000,
            position: 'top',
            color: 'success'
          }).then(toast => toast.present());
        },
        (error => {
          const message = error.status === 409 ? `Le pseudo ${this.user.name} existe déjà. Veuillez choisir un autre.`
              : 'Une erreur s\'est produite lors de la mise à jour de votre profil';
          this.toastCtrl.create({
            message,
            duration: 10000,
            position: 'top',
            color: 'danger'
          }).then(toast => toast.present());
        })
    );
  }

  private validate() {
    if (!this.formGroup.valid) {
      this.toastCtrl.create({
        message: 'Le formulaire contient des erreurs',
        duration: 3000,
        position: 'bottom',
        color: 'warning'
      }).then(toast => toast.present());
      return false;
    }
    return true;
  }

  getErrorMessages(name) {
    if (this.validationMessages[name]) {
      for (const validation of this.validationMessages[name]) {
        if (this.formGroup.get(name) ) {
          const control = this.formGroup.get(name);
          if (control.hasError(validation.type) && (control.dirty || control.touched)) {
            return validation.message;
          }
        }
      }
    }
    return null;
  }

  defaultCountryCode(country: any) {
    if (!this.formGroup.controls.phone.value) {
      this.selectedCountry = country.detail.value;
    }
  }

  async desactivateAccount() {
    const alert = await this.alertController.create({
      cssClass: 'account-delete-class',
      header: 'Désactivation de votre profil Bissap',
      message: `Désactiver votre profil Bissap entrainera :
                     <ul>
                        <li>L’ expiration de tous les deals postés</li>
                        <li>La suppression des notifications créées</li>
                        <li>La suppression des alertes configurées</li>
                     </ul>
                        Voulez-vous continuer ?`,
      buttons: [
        {
          text: 'Non',
          role: 'cancel'
        }, {
          text: 'Oui',
          handler: () => {
            this.authService.desactivateAccount().subscribe(
                () => {
                  this.router.navigateByUrl('/');
                }
            );
          }
        }
      ]
    });
    await alert.present();
  }
}
