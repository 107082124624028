import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared.module';
import {AlertManagerComponent} from './alert-manager/alert-manager.component';
import {NotifComponent} from './notif/notif.component';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertToggleComponent} from './alert-toggle/alert-toggle.component';
import {CreateGlobalAlertComponent} from './create-global-alert/create-global-alert.component';
import {MomentModule} from 'ngx-moment';



@NgModule({
  declarations: [AlertManagerComponent, NotifComponent, AlertToggleComponent, CreateGlobalAlertComponent],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        MomentModule,
        ReactiveFormsModule
    ],
  exports: [AlertManagerComponent, NotifComponent, AlertToggleComponent, CreateGlobalAlertComponent],
  entryComponents: [AlertManagerComponent, NotifComponent, CreateGlobalAlertComponent]
})
export class NotifModule { }
