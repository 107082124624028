<form class="form deal-edit" [formGroup]="createDealGroup" *ngIf="createDealGroup">
    <div class="section info-errors">
        <div class="spinner" *ngIf="loading">
            <ion-spinner name="dots" color="danger">
            </ion-spinner>
            <span> Soumission en cours...</span>
        </div>
        <p class="error-message" *ngIf="serverError">
            Une erreur est survenue lors de la sauvegarde, veuillez réessayer
        </p>
        <p class="error-message" *ngIf="errorMessage">
            <ion-icon name="warning-outline"></ion-icon>
            {{errorMessage}}
        </p>
    </div>
    <ion-slides #slides
                *ngIf="!businessMessage && !loading && didInit" [options]="slidesOpts" pager="true">
        <ion-slide>
            <edit-deal-header class="edit-deal-header-spaced" title=" Où se trouve votre deal ?"
                              subtitle="Votre deal peut se trouver en ligne ou en boutique"></edit-deal-header>
            <div class="slide">
                <div class="section">
                    <ion-segment>
                        <ion-segment-button (click)="updateDealSourceType(dealSourceType.value, true)"
                                            [value]="dealSourceType.value" *ngFor="let dealSourceType of dealSourceTypes"
                                            [class.segment-checked]="isCheckedDealSource(dealSourceType.value)"
                        >

                            <ion-img [src]="dealSourceType.icon"></ion-img>
                            <ion-label>{{dealSourceType.label}}</ion-label>
                        </ion-segment-button>
                    </ion-segment>
                    <ion-button (click)="continue()"
                                class="continue" fill="clear">
                        <ion-icon size="large" slot="end" name="arrow-forward"></ion-icon>
                    </ion-button>
                </div>
            </div>
        </ion-slide>
        <ion-slide *ngIf="isOnlineStore">
            <edit-deal-header class="edit-deal-header-spaced" title="Choix d’un Store Internet"></edit-deal-header>
            <div class="slide">
                <div class="section">
                    <ion-item>
                        <ion-icon name="link-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Lien du deal : *</ion-label>
                        <ion-input type="url"
                                   name="externalDealLink" formControlName="externalDealLink"
                                   inputmode="url"
                                   placeholder="Saisir un lien"></ion-input>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('externalDealLink')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item>
                        <ion-icon name="browsers-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Commerçant : *</ion-label>
                        <ion-input type="text" required="true"
                                   maxlength="20"
                                   minlength="1"
                                   placeholder="Maximum de 20 caractères"
                                   name="professional" formControlName="professional"></ion-input>
                    </ion-item>

                    <ion-label *ngFor="let msg of getErrorMessages('professional')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-button (click)="previous()" class="previous" fill="clear">
                        <ion-icon size="large" slot="start" name="arrow-back"></ion-icon>
                    </ion-button>
                    <ion-button (click)="continue()"
                                class="continue" fill="clear">
                        <ion-icon size="large" slot="end" name="arrow-forward"></ion-icon>
                    </ion-button>
                </div>
            </div>

        </ion-slide>
        <ion-slide *ngIf="isPhysicalStore">
            <edit-deal-header class="edit-deal-header-spaced" title="Localisez le magasin" subtitle="Votre commerce peut se trouver grâce à Google Maps - Utilisez la barre de recherche ..."></edit-deal-header>
            <div class="slide">
                <div class="section">
                    <div (click)="showAddressModal(false)" >
                        <div>
                            <ion-searchbar id="locationSearchbar" #locationSearchbar placeholder="Rechercher"
                                           formControlName="location"
                                           cancelButtonText="Annuler"
                                           readonly
                            ></ion-searchbar>
                        </div>

                    </div>
                    <ion-button (click)="previous()" class="previous" fill="clear">
                        <ion-icon size="large" slot="start" name="arrow-back"></ion-icon>
                    </ion-button>
                    <ion-button (click)="continue()"
                                class="continue" fill="clear">
                        <ion-icon size="large" slot="end" name="arrow-forward"></ion-icon>
                    </ion-button>
                </div>
            </div>

        </ion-slide>

        <!-- Slide 1 --->
        <ion-slide *ngIf="isPhysicalStore">
            <edit-deal-header title="Informations concernant le deal"></edit-deal-header>
            <div class="slide">
                <div class="section">
                    <ion-item>
                        <ion-icon name="flag-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Pays du deal*</ion-label>
                        <ion-select placeholder="Selectionner un pays*"
                                    name="country"
                                    formControlName="country"
                                    required interface="popover"
                                    class="header-filters"
                        >
                            <ion-select-option [value]="country.id"
                                               *ngFor="let country of dataService.getCountries()|async">
                                {{ country.name }}</ion-select-option>
                        </ion-select>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('country')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>
                    <ion-item (click)="showAddressModal(true)">
                        <ion-icon name="reader-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Ville du deal</ion-label>
                        <ion-input type="text" readonly
                                   name="city" formControlName="city"></ion-input>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('city')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item>
                        <ion-icon name="location-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Emplacement du deal</ion-label>
                        <ion-input type="text"
                                   name="location" formControlName="location"></ion-input>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('location')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item>
                        <ion-icon name="browsers-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Commerçant*</ion-label>
                        <ion-input type="text" required="true"
                                   maxlength="20"
                                   minlength="1"
                                   placeholder="Maximum de 20 caractères"
                                   name="professional" formControlName="professional"></ion-input>
                    </ion-item>

                    <ion-label *ngFor="let msg of getErrorMessages('professional')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item class="phone-container" lines="none">
                        <ion-icon name="call-outline" slot="start"></ion-icon>
                        <ion-label position="stacked" class="phone-label">Numéro de téléphone</ion-label>
                        <ion-intl-tel-input
                                formControlName="phone"
                                [preferredCountries]="preferredCountries"
                                [defaultCountryiso]="selectedCountry"
                                modalTitle="Selection du pays"
                                modalSearchPlaceholder="Rechercher un pays"
                                modalCloseText="Fermer"
                                modalSearchFailText="Aucun pays"
                                [enableAutoCountrySelect]="true"
                        >
                        </ion-intl-tel-input>
                    </ion-item>

                    <ion-label *ngFor="let msg of getErrorMessages('phone')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item class="phone-container" lines="none">
                        <ion-icon name="call-outline" slot="start"></ion-icon>
                        <ion-label position="stacked" class="phone-label">Numéro de téléphone Whatsapp</ion-label>
                        <ion-intl-tel-input
                                formControlName="whatsappNo"
                                [preferredCountries]="preferredCountries"
                                [defaultCountryiso]="selectedCountry"
                                modalTitle="Selection du pays"
                                modalSearchPlaceholder="Rechercher un pays"
                                modalCloseText="Fermer"
                                modalSearchFailText="Aucun pays"
                                [enableAutoCountrySelect]="true"
                        >
                        </ion-intl-tel-input>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('whatsappNo')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                </div>
                <ion-button (click)="previous()" class="previous" fill="clear">
                    <ion-icon size="large" slot="start" name="arrow-back"></ion-icon>
                </ion-button>
                <ion-button (click)="continue()"
                            class="continue" fill="clear">
                    <ion-icon size="large" slot="end" name="arrow-forward"></ion-icon>
                </ion-button>
            </div>
        </ion-slide>


        <!-- Slide 2 --->
        <ion-slide>
            <edit-deal-header title="Description du deal"></edit-deal-header>
            <div class="slide">
                <div class="section">
                    <ion-item>
                        <ion-icon name="text-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Titre*</ion-label>
                        <ion-input type="text" required
                                   minlength="10"
                                   maxlength="100"
                                   placeholder="Maximum de 100 caractères"
                                   name="title" formControlName="title"></ion-input>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('title')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item (click)="showDescriptionModal()">
                        <ion-icon name="document-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Description*</ion-label>
                        <quill-view-html
                                class="ion-text-wrap description-container"
                                [content]="descriptionOrPlaceholder"
                                theme="snow"> {{ descriptionOrPlaceholder }}>
                        </quill-view-html>
                    </ion-item>

                    <ion-label *ngFor="let msg of getErrorMessages('description')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item>
                        <ion-label position="stacked">Catégorie*</ion-label>
                        <ion-icon name="pricetags-outline" slot="start"></ion-icon>
                        <ion-select placeholder="Sélectionner une catégorie" required
                                    interface="popover"
                                    name="categoryId" formControlName="categoryId"
                                    class="header-filters">
                            <ion-select-option [value]="category.id"
                                               *ngFor="let category of categories">{{ category.name }}

                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('categoryId')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item>
                        <ion-icon name="calendar-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Date de début*</ion-label>
                        <ion-datetime [displayFormat]="displayFormat"
                                      minuteValues="0,15,30,45"
                                      [yearValues]="yearValues"
                                      [monthNames]="monthShortNames"
                                      [min]="minStartDate()" doneText="Valider" cancelText="Annuler"
                                      name="startDate" formControlName="startDate"
                                      (ngModelChange)="checkEndate($event)"
                        ></ion-datetime>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('startDate')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item>
                        <ion-icon name="calendar-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Date de fin</ion-label>
                        <ion-datetime [displayFormat]="displayFormat"
                                      minuteValues="0,15,30,45"
                                      [yearValues]="yearValues"
                                      [monthNames]="monthShortNames"
                                      [min]="today"
                                      doneText="Valider" cancelText="Annuler"
                                      name="endDate"
                                      formControlName="endDate"
                                      placeholder="Saisir la date de fin"
                                      [pickerOptions]="customPickerOptions"
                        >
                        </ion-datetime>
                    </ion-item>

                    <ion-label *ngFor="let msg of getErrorMessages('endDate')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>
                </div>
                <ion-button (click)="previous()" class="previous" fill="clear">
                    <ion-icon size="large" slot="start" name="arrow-back"></ion-icon>
                </ion-button>
                <ion-button (click)="continue()"
                            class="continue" fill="clear">
                    <ion-icon size="large" slot="end" name="arrow-forward"></ion-icon>
                </ion-button>
            </div>

        </ion-slide>


        <!-- Slide 3 --->
        <ion-slide>
            <edit-deal-header class="edit-deal-header-spaced" title="C’est bientôt fini !"></edit-deal-header>
            <div class="slide">

                <div class="section">

                    <ion-item>
                        <ion-icon name="megaphone-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Type de promotion*</ion-label>
                        <ion-select placeholder="Selectionner le type de promotion*"
                                    name="discountType"
                                    formControlName="discountType"
                                    required interface="popover"
                                    class="header-filters"
                                    (ngModelChange)="updatePromo()"
                        >
                            <ion-select-option [value]="discountType.value"
                                               *ngFor="let discountType of discountTypes">
                                {{ discountType.label }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('discountType')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item *ngIf="isPriceAbsolute">
                        <ion-icon name="card-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Prix à afficher*</ion-label>
                        <ion-row>
                            <ion-col size="8">
                                <ion-input
                                        placeholder="Saisir le prix à afficher ..."
                                        max="9999999999"
                                        inputmode="decimal"
                                        required
                                        name="priceValue" formControlName="priceValue"
                                        [pattern]="decimalRegexPattern"
                                        (ngModelChange)="checkUsualPrice($event)"
                                ></ion-input>
                            </ion-col>
                            <ion-col size="4">
                                <ion-select
                                        (ionChange)="updateUsualPriceUnit($event)"
                                        interface="popover"
                                        name="priceUnit" formControlName="priceUnit" required>
                                    <ion-select-option [value]="item" *ngFor="let item of currencies">
                                        {{ item }}
                                    </ion-select-option>
                                </ion-select>
                            </ion-col>
                        </ion-row>
                    </ion-item>
                    <div *ngIf="isPriceAbsolute">
                        <ion-label *ngFor="let msg of getErrorMessages('priceValue')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                        <ion-label *ngFor="let msg of getErrorMessages('priceUnit')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                    </div>

                    <ion-item *ngIf="isPriceAbsolute">
                        <ion-icon name="card-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Prix habituel</ion-label>
                        <ion-row>
                            <ion-col size="8">
                                <ion-input
                                        placeholder="Saisir le prix habituel..."
                                        max="9999999999"
                                        inputmode="decimal"
                                        name="usualPriceValue" formControlName="usualPriceValue"
                                        [pattern]="decimalRegexPattern"
                                >
                                </ion-input>
                            </ion-col>
                            <ion-col size="4">
                                <ion-select disabled interface="popover"
                                            name="usualPriceUnit" formControlName="usualPriceUnit">
                                    <ion-select-option [value]="item" *ngFor="let item of currencies">
                                        {{ item }}
                                    </ion-select-option>
                                </ion-select>
                            </ion-col>
                        </ion-row>
                    </ion-item>

                    <ng-container *ngIf="isPriceAbsolute">
                        <ion-label *ngFor="let msg of getErrorMessages('usualPriceValue')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                        <ion-label *ngFor="let msg of getErrorMessages('usualPriceUnit')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                    </ng-container>

                    <ion-item *ngIf="isPricePercent">
                        <ion-icon name="card-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Réduction en % *</ion-label>
                        <ion-input
                                placeholder="Saisir la réduction en %"
                                max="100"
                                min="1"
                                inputmode="number"
                                name="discount" formControlName="discount"
                                [pattern]="percentRegexPattern"
                        ></ion-input>
                    </ion-item>
                    <ng-container *ngIf="isPricePercent">
                        <ion-label *ngFor="let msg of getErrorMessages('discount')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                    </ng-container>

                    <ion-item *ngIf="isOffer">
                        <ion-icon name="card-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Nombre de produits à acheter*</ion-label>
                        <ion-input
                                placeholder="Saisir le nombre de produits à acheter"
                                max="100"
                                min="0"
                                inputmode="number"
                                required
                                name="discountBuy" formControlName="discountBuy"
                                [pattern]="discountOfferBuyPattern"
                        ></ion-input>
                    </ion-item>
                    <ng-container *ngIf="isOffer">
                        <ion-label *ngFor="let msg of getErrorMessages('discountBuy')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                    </ng-container>
                    <ion-item *ngIf="isOffer">
                        <ion-icon name="card-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Nombre de produits à offrir*</ion-label>
                        <ion-input
                                placeholder="Saisir le nombre de produits à offrir"
                                max="100"
                                min="0"
                                inputmode="number"
                                required
                                name="discountOffer" formControlName="discountOffer"
                                [pattern]="discountOfferBuyPattern"
                        ></ion-input>
                    </ion-item>
                    <ng-container *ngIf="isOffer">
                        <ion-label *ngFor="let msg of getErrorMessages('discountOffer')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                    </ng-container>

                    <ion-item *ngIf="isPrice">
                        <ion-icon name="card-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Prix de la promotion *</ion-label>
                        <ion-row>
                            <ion-col size="8">
                                <ion-input
                                        placeholder="Saisir le prix à afficher ..."
                                        max="9999999999"
                                        inputmode="decimal"
                                        required
                                        name="priceValue" formControlName="priceValue"
                                        [pattern]="decimalRegexPattern"
                                        (ngModelChange)="checkUsualPrice($event)"
                                ></ion-input>
                            </ion-col>
                            <ion-col size="4">
                                <ion-select
                                        (ionChange)="updateUsualPriceUnit($event)"
                                        interface="popover"
                                        name="priceUnit" formControlName="priceUnit" required>
                                    <ion-select-option [value]="item" *ngFor="let item of currencies">
                                        {{ item }}
                                    </ion-select-option>
                                </ion-select>
                            </ion-col>
                        </ion-row>
                    </ion-item>
                    <div *ngIf="isPrice">
                        <ion-label *ngFor="let msg of getErrorMessages('priceValue')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                        <ion-label *ngFor="let msg of getErrorMessages('priceUnit')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                    </div>

                    <div>
                        <ion-item lines="none">
                            <ion-icon name="gift-outline" slot="start"></ion-icon>
                            <ion-label class="text-bissap" position="stacked">Moyen de retrait</ion-label>
                        </ion-item>
                        <ion-segment>
                            <ion-segment-button (click)="togglePickup()" formControlName="pickup" [class.segment-checked]="isPickup">
                                <img src="/assets/img/picto/shop-picto-2.png">
                                <ion-label>Sur place</ion-label>
                            </ion-segment-button>
                            <ion-segment-button  formControlName="shipping" (click)="toggleShipping()" [class.segment-checked]="isShipping">
                                <img src="/assets/img/picto/drive-illus.png">
                                <ion-label>Livraison</ion-label>
                            </ion-segment-button>
                        </ion-segment>

                        <ion-item *ngIf="createDealGroup.get('shipping')?.value">
                            <ion-label position="stacked">Frais de port*</ion-label>
                            <ion-row>
                                <ion-col size="8">
                                    <ion-input inputmode="decimal"
                                               placeholder="Saisir les frais de port..."
                                               max="9999999999"
                                               name="shippingFeesValue"
                                               formControlName="shippingFeesValue"
                                               [pattern]="decimalRegexPattern"
                                    >
                                    </ion-input>

                                </ion-col>
                                <ion-col size="4">
                                    <ion-select interface="popover"
                                                name="shippingFeesUnit" formControlName="shippingFeesUnit">
                                        <ion-select-option [value]="item" *ngFor="let item of currencies">
                                            {{ item }}
                                        </ion-select-option>
                                    </ion-select>
                                </ion-col>
                            </ion-row>
                        </ion-item>

                        <ion-label *ngFor="let msg of getErrorMessages('shippingFeesValue')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                        <ion-label *ngFor="let msg of getErrorMessages('shippingFeesUnit')" color="warning">
                            <ion-icon color="warning" name="alert-circle"></ion-icon>
                            <span>{{ msg }} </span>
                        </ion-label>
                    </div>
                </div>
                <ion-button (click)="previous()" class="previous" fill="clear">
                    <ion-icon size="large" slot="start" name="arrow-back"></ion-icon>
                </ion-button>
                <ion-button (click)="continue()"
                            class="continue" fill="clear">
                    <ion-icon size="large" slot="end" name="arrow-forward"></ion-icon>
                </ion-button>
            </div>
        </ion-slide>


        <!-- Slide 4 --->
        <ion-slide>
            <edit-deal-header title="Dernière étape !"></edit-deal-header>
            <div class="slide">

                <div class="section">

                    <ion-item *ngIf="isPhysicalStore">
                        <ion-icon name="link-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Lien du deal</ion-label>
                        <ion-input type="url"
                                   name="externalDealLink"
                                   formControlName="externalDealLink"
                                   inputmode="url"
                                   placeholder="Saisir un lien"></ion-input>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('externalDealLink')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>
                    <div class="image-container">
                        <div *ngIf="createDealGroup.get('image').value">
                            <ion-button class="clear-image" color="warning" fill="clear" (click)="clearImage()">
                                <ion-icon name="close-outline"></ion-icon>
                            </ion-button>
                            <div (click)="showPickImagePrompt()">
                                <ion-img [src]="createDealGroup.get('image').value"></ion-img>
                            </div>
                        </div>
                        <div *ngIf="!createDealGroup.get('image').value" (click)="showPickImagePrompt()"
                             class="no-image-container">
                            <div>
                                <div>
                                    <ion-icon name="camera-outline"></ion-icon>
                                </div>
                                <ion-label>Ajouter une image du produit</ion-label>
                            </div>
                        </div>
                    </div>

                    <ion-item>
                        <ion-icon name="link-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Lien de la page Facebook</ion-label>
                        <ion-input type="url"
                                   inputmode="url"
                                   placeholder="Saisir un lien"
                                   name="facebookPage" formControlName="facebookPage"></ion-input>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('facebookPage')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item>
                        <ion-icon name="link-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Lien de la page Instagram</ion-label>
                        <ion-input type="url"
                                   inputmode="url"
                                   placeholder="Saisir un lien"
                                   name="instagramPage" formControlName="instagramPage"></ion-input>
                    </ion-item>
                    <ion-label *ngFor="let msg of getErrorMessages('instagramPage')" color="warning">
                        <ion-icon color="warning" name="alert-circle"></ion-icon>
                        <span>{{ msg }} </span>
                    </ion-label>

                    <ion-item>
                        <ion-icon name="flag-outline" slot="start"></ion-icon>
                        <ion-label position="stacked">Evènement en lien avec le deal</ion-label>
                        <ion-select placeholder="Selectionner un évènement"
                                    name="event"
                                    formControlName="event"
                                    interface="popover"
                                    class="header-filters">
                            <ion-select-option [value]="event"
                                               *ngFor="let event of allEvents">
                                {{ event }}</ion-select-option>
                        </ion-select>
                    </ion-item>
                </div>
                <ion-button (click)="previous()" class="previous" fill="clear">
                    <ion-icon size="large" slot="start" name="arrow-back"></ion-icon>
                </ion-button>

                <ion-button
                        [disabled]="!createDealGroup.valid"
                        (click)="showPreview()"
                        class="continue" fill="clear">
                    <ion-icon size="large" slot="end" name="eye-outline"></ion-icon>
                    Aperçu
                </ion-button>
            </div>
        </ion-slide>


        <!-- Preview --->
        <ion-slide *ngIf="createDealGroup.valid">
            <edit-deal-header title="Aperçu avant soumission !"
                              subtitle="Voici à quoi ressemblera votre deal!"></edit-deal-header>
            <div class="slide">
                <div class="section">
                    <div class="deal-preview">
                        <deal-detail [deal]="buildDeal()"></deal-detail>
                    </div>
                </div>
                <div class="deal-edit-footer">
                    <ion-button (click)="previous()" class="previous" fill="clear">
                        <ion-icon size="large" slot="start" name="arrow-back"></ion-icon>
                    </ion-button>

                    <ion-button
                            [disabled]="loading"
                            (click)="submitForm()"
                            class="continue" fill="clear">
                        <ion-icon size="large" slot="end" name="checkmark-outline"></ion-icon>
                        {{isEdit() ? 'Modifier le deal' : 'Créer le deal'}}
                    </ion-button>
                </div>
            </div>
        </ion-slide>
    </ion-slides>

    <div class=" info-errors" *ngIf="businessMessage">
        <div class="validated-deal-header">
            <ion-img src="/assets/img/picto/deal-illus.png"></ion-img>
        </div>
        <div class="info-message">
            <br>
            <h1>Bravo !</h1>
            <p>Votre deal est en cours de validation,
                <br>
                Merci pour votre soutien !</p>
            <ion-button class="bissap-btn-validate" expand="block" (click)="back()">Découvrir les deals</ion-button>
        </div>
    </div>

</form>

