<div *ngIf="user">
    <form class="form profile-edit" #f="ngForm" [formGroup]="formGroup">
        <ion-list>
            <ion-list-header class="notif-title" *ngIf="!hideHeader">
                {{ title }}
                <ion-button class="dismiss-btn" fill="clear" (click)="dismiss()">
                    <ion-icon name="close-outline"></ion-icon>
                </ion-button>
            </ion-list-header>
            <div>
                <div class="menu-logo">
                    <div>
                        <ion-img ionImgViewer scheme="light" [src]="getProfilePic()" class="img-circle"></ion-img>
                    </div>
                    <div class="mg-top-5">
                        <ion-buttons>
                            <ion-button class="photo-action" size="small" fill="outline" color="danger"
                                        (click)="showPickImagePrompt()">Changer
                            </ion-button>
                            <ion-button class="photo-action" size="small" fill="outline" color="danger"
                                        (click)="deleteImage()">Supprimer
                            </ion-button>
                        </ion-buttons>
                    </div>

                </div>

                <ion-item>
                    <ion-icon class="pseudo-icon" name="person-outline" slot="start"></ion-icon>
                    <ion-label class="pseudo" position="stacked">Pseudo *</ion-label>
                    <ion-input
                            type="text"
                            name="name"
                            formControlName="name" [disabled]="user.usernameUpdateDisabled">

                    </ion-input>
                </ion-item>
                <div class="text-info">
                    <ion-icon color="info" name="information-circle"></ion-icon>
                    <span color="info">Le changement de pseudo est possible une fois tous les 6 mois</span>
                </div>
                <ion-label *ngIf="getErrorMessages('name')" class="error-message">
                    <ion-icon color="danger" name="alert-circle"></ion-icon>
                    <span color="danger">{{ getErrorMessages('name') }} </span>
                </ion-label>
                <ion-item>
                    <ion-icon class="pseudo-icon" name="flag-outline" slot="start"></ion-icon>
                    <ion-label class="pseudo" position="stacked">Pays *</ion-label>
                    <ion-select placeholder="Sélectionner un pays"
                                name="country"
                                formControlName="country"
                                required interface="popover"
                                class="header-filters"
                                (ionChange)="defaultCountryCode($event)">
                        <ion-select-option [value]="country.id"
                                           *ngFor="let country of countries">{{ country.name }}</ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-label *ngIf="getErrorMessages('country')" class="error-message">
                    <ion-icon color="danger" name="alert-circle"></ion-icon>
                    <span color="danger">{{ getErrorMessages('country') }} </span>
                </ion-label>
                <ion-item class="phone-container" lines="none">
                    <ion-icon name="call-outline" class="call-outline" slot="start"></ion-icon>
                    <ion-label position="stacked" class="pseudo phone-label">Numéro de téléphone</ion-label>
                    <ion-intl-tel-input
                            formControlName="phone"
                            [preferredCountries]="preferredCountries"
                            [defaultCountryiso]="selectedCountry"
                            modalTitle="Selection du pays"
                            modalSearchPlaceholder="Rechercher un pays"
                            modalCloseText="Fermer"
                            modalSearchFailText="Aucun pays"
                            [enableAutoCountrySelect]="true"
                    >
                    </ion-intl-tel-input>
                </ion-item>
                <ion-label *ngIf="getErrorMessages('phone')" class="error-message">
                    <ion-icon color="danger" name="alert-circle"></ion-icon>
                    <span color="danger">{{ getErrorMessages('phone') }} </span>
                </ion-label>
                <ion-button
                        [disabled]="!formGroup.valid"
                        expand="block" class="save" (click)="save()">Sauvegarder
                </ion-button>

                <ion-button  *ngIf="user.name && user.phoneNumber" fill="outline" color="danger"
                            expand="block" class="delete" (click)="desactivateAccount()">Désactiver mon profil
                </ion-button>

            </div>
        </ion-list>
    </form>
</div>

