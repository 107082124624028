import {Category, Deal, SearchCriteria, User, UserDeals} from '../models/deal';
import {AuthData, Parameter} from '../models/store';
import {Country} from '../models/country';
import {AlertTrigger, DealNotifSubscription, GlobalAlertTrigger, Notification} from '../models/notification';

export class StoreAuthData {
    static readonly type = '[Auth] Store auth data';
    constructor(public readonly payload: AuthData) {}
}

export class StoreCategories {
    static readonly type = '[Category] Store categories';
    constructor(public readonly payload: Category[]) {}
}
export class StoreDeal {
    static readonly type = '[Deal] Store deal';
    constructor(public readonly payload: Deal) {}
}
export class DeleteDeal {
    static readonly type = '[Deal] Delete deal';
    constructor(public readonly payload: string) {}
}
export class StoreDeals {
    static readonly type = '[Deal] Store deals';
    constructor(public readonly payload: Deal[]) {}
}
export class UpdateDeals {
    static readonly type = '[Deal] Update deals';
    constructor(public readonly payload: Deal[]) {}
}
export class StoreUserDeals {
    static readonly type = '[Deal] Store user deals';
    constructor(public readonly payload: UserDeals) {}
}
export class StoreFavoriteDeals {
    static readonly type = '[Deal] Store favorite deals';
    constructor(public readonly payload: Deal[]) {}
}
export class StoreCountries {
    static readonly type = '[Countries] Store countries';
    constructor(public readonly payload: Country[]) {}
}
export class StoreSelectedCategory {
    static readonly type = '[Category] Store selected category id';
    constructor(public readonly payload: Category) {}
}
export class StoreCurrentDealId {
    static readonly type = '[Deal] Store view deal id';
    constructor(public readonly payload: string) {}
}
export class UpdateSearchText {
    static readonly type = '[Search] update';
    constructor(public readonly payload: string) {}
}
export class StoreNotifications {
    static readonly type = '[Notifs] store';
    constructor(public readonly payload: Notification[]) {}
}
export class StoreAlerts {
    static readonly type = '[Alerts] store';
    constructor(public readonly payload: Notification[]) {}
}

export class StoreNotification {
    static readonly type = '[Notif] store';
    constructor(public readonly payload: Notification) {}
}
export class StoreAlert {
    static readonly type = '[Alert] store';
    constructor(public readonly payload: Notification) {}
}
export class ReadNotification {
    static readonly type = '[Notif] Read';
    constructor(public readonly payload: string) {}
}
export class ReadAlert {
    static readonly type = '[Alert] Read';
    constructor(public readonly payload: string) {}
}
export class StoreGlobalAlertTrigger {
    static readonly type = '[Global Alert Trigger] Store';
    constructor(public readonly payload: GlobalAlertTrigger) {}
}
export class DeleteGlobalAlertTrigger {
    static readonly type = '[Global Alert Trigger] Delete';
    constructor(public readonly payload: string) {}
}
export class StorePushSubscription {
    static readonly type = '[Push] Update push subscription';
    constructor(public readonly payload: string) {}
}

export class ClearSearchHistory {
    static readonly type = '[Search] Clear recent search';
    constructor() {}
}
export class StoreUserProfile {
    static readonly type = '[Profil] update';
    constructor(public readonly payload: User) {}
}
export class StoreParameters {
    static readonly type = '[Params] store';
    constructor(public  readonly payload: Parameter) {}
}


export class StoreSearchCriteria {
    static readonly type = '[Search] update criteria';
    constructor(public readonly payload: SearchCriteria) {}
}
export class StoreAuthTokens {
static readonly type = '[Auth] Store tokens';
    constructor(public readonly payload: {accessToken: string, refreshToken: string}) {}
}
export class StoreMySubscribedDeals {
    static readonly type = '[Notif] Store my subscribed deals';
    constructor(public readonly payload: Deal[]) {}
}
export class StoreCurrentUser {
    static readonly type = '[navigation] Store current user';
    constructor(public readonly payload: User) {}
}
