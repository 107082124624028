import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import {CreatePage} from '../pages/deals/create/create.page';

@Injectable({
  providedIn: 'root'
})
export class HandleUnsavedChangeGuardGuard implements CanDeactivate<CreatePage> {
  canDeactivate(component: CreatePage,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.submitted) {
      return true;
    }
    return component.checkBackBtn();
  }

}
