import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {MomentModule} from 'ngx-moment';
import {SearchComponent} from './search.component';
import {DealsComponentModule} from '../deals/deals.module';
import {SearchFilterComponent} from './search-filter/search-filter.component';
import {DealDetailPageModule} from '../../pages/deals/deal-detail/deal-detail.module';
import {SharedModule} from '../../shared.module';



@NgModule({
    declarations: [SearchComponent, SearchFilterComponent],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        MomentModule,
        DealsComponentModule,
        DealDetailPageModule,
        SharedModule
    ],
    exports: [SearchComponent, SearchFilterComponent],
    entryComponents: [SearchComponent, SearchFilterComponent]
})
export class SearchModule { }
